import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>({
	link: {
        textDecoration: 'none',
        color: theme.palette.primary.main
	},
}));

export default React.forwardRef(({
    to,
    text,
    ...props
}, ref) => {
    const classes = useStyles();
    
    return (
        <Link 
            ref={ref}
            to={to}
            className={classes.link}
            {...props}
        >
            {
                text 
                ?
                text
                : 
                props.children
            }
        </Link>
    );
});