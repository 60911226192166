import {
    LOGIN,
    LOGOUT
} from '../../../consts/user/Login';
import {
    apiAction, 
    thunk
} from '../Helper';
import {
    fetchId
} from './Profile';
import {
    fetchAll as fetchCategory
} from '../category/Fetch';
import {
    fetchAll as fetchProducts
} from '../product/Fetch';
import {
    appendNotification
} from '../Notifications';
import {
    getUserIsFetching
} from '../../reducers';

// const att = async dispatch => {
//     await dispatch(login)
// }

export const login = ({
    user_name,
    password
}) => apiAction(
    {
        url: '/api/token',
        method: 'POST',
        onSuccess: thunk(
            [
                data => ({
                    type: LOGIN,
                    data,
                }),
                fetchId
            ]
        ),
        onFailure: () => appendNotification({
            message: 'Failed to login. Please try again.',
            variant: 'error'
        }),
        data: {
            user_name,
            password,
        },
        label: LOGIN,
        race: getUserIsFetching,
    }
);

export const logout = thunk(
    [
        () => ({
            type: LOGOUT
        }),
        fetchCategory,
        fetchProducts
    ]
);