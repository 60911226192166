import React from 'react';
import {
    connect
} from 'react-redux';
import {
    Switch, 
    Redirect, 
    Route
} from 'react-router-dom';
import PublicLayout from '../components/layout/Public';
import EmptyLayout from '../components/layout/Empty';
import AdminLayout from '../components/layout/Admin';
import AutoScrollToTop from '../utils/index';
import routes from './Data';
import {
    getAuth
} from '../redux/reducers';

const mapStateToProps = state => (
    {
        login: getAuth(state).login ? 1 : 0,
        admin: getAuth(state).admin ? 1 : 0,
    }
);

const WrapperRoute = connect(mapStateToProps)(({
    component: Component,
    authLevel,
    max,
    login,
    admin,
    ...rest
}) => {
    let Layout;//, login = 2; //login is login level of user: NONE, PUBLIC, ADMIN
    /*
        use connect to get login information from store via selector.
        Currently admin login cannot be understood from backend response. 
        So, we deal with public login only (logged in or not)

        if(admin logged in) Layout = AdminLayout, login = ADMIN;
        else if(public login) Layout = PublicLayout, login = PUBLIC;
    */

    if(login && admin) { 
        Layout = AdminLayout; 
        login = 2
    }
    else if(login && !admin) { 
        Layout = PublicLayout; 
        login = 1
    } 
    else{ 
        Layout = EmptyLayout; 
        login = 0
    }

    return (
        <Route 
            {...rest} 
            render = {
                props => (
                    login >= authLevel 
                    && 
                    login <= max
                    ? 
                    <Layout>
                        <Component 
                            {...props} 
                        />
                    </Layout> 
                    :
                    <Redirect 
                        to={{path: "/"}} 
                    />
                )
            } 
        />
    );
});

const Routes = () => (
    <AutoScrollToTop>
        <Switch>
            {
                routes.map(
                    (r, i) => (
                        <WrapperRoute 
                            key={i} 
                            {...r} 
                        />
                    )
                )
            }
            <Route>
                <div 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#dcdcdc'
                    }}
                >
                    404 not found
                </div>
            </Route>
        </Switch>
    </AutoScrollToTop>
);

export default Routes;