import React from 'react';
import withWidth, { 
    isWidthUp 
} from '@material-ui/core/withWidth';
import AppbarForBigger from './public/Bigger';
import AppbarForSmaller from '../menubar/smaller/index';

const Layout = (props) => {
    if (isWidthUp('md', props.width)) {
        return <AppbarForBigger {...props}/>
    }

    return <AppbarForSmaller {...props}/>;
}


export default withWidth()(Layout);
