import _ from 'lodash';
import {
    DELETE,
    READ
} from '../../../consts/promo/Admin';

export default (
    state = {},
    action
) => {
    switch(action.type) {
        case READ:
            return {
                ...state,
                [action.data.id]: action.data
            };
        case DELETE:
            const next = state;
            delete next[Object.keys(action.data).map(l => action.data[l]).join('')];
            return next;
        default:
            return state
    }
};

export const getList = state => Object.keys(state).map(k => state[k]);

export const get = (state, id) => state[id] || {};

export const getByCode = (state, code) => _.find(state, p => p.code === code);