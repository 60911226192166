import {
    CREATE
} from '../../../consts/order/Create';
import {
    FETCH_ALL,
    FETCH_NEW_ORDER,
    FETCH,
    FETCH_DELETED_ORDER
} from '../../../consts/order/Fetch';
import {
    UPDATE
} from '../../../consts/order/Update';
import {
    DELETE
} from '../../../consts/order/Delete';
import {
    FETCH_ORDER_COUNT
} from '../../../consts/order/Fetch';

export default (
    state = [],
    action
) => {
    switch (action.type) {
        case CREATE:
            return [
                ...state, {
                    id: action.data.id,
                    firstName: action.data.firstname,
                    lastName: action.data.lastname,
                    contactNumber: action.data.contactnumber,
                    cartId: action.data.cart_id,
                    address: action.data.address.join(','),
                    customerId: action.data.customer_id,
                    isconfirmed: action.data.is_confirmed,
                }
            ];
        case UPDATE:
            return state.map(i => (i.id === action.data.id) ? { ...i, isconfirmed: true } : i);
        case FETCH:
            return state.map(i => (i.id === action.data.id) ? { ...i, orderNumber: action.data.order_number } : i);
        case FETCH_ALL:
            return {
                ...state,
                orders: action.data.all_order,
            }
        case DELETE:
            return {
                ...state,
                orders: state.orders.filter(
                    ({
                        id
                    }) => action.id !== id
                )
            };
        case FETCH_ORDER_COUNT:
            return {
                ...state,
                count: action.data.count,
            }
        case FETCH_NEW_ORDER:
            return {
                ...state,
                newOrders: action.data.new_order
            }
        case FETCH_DELETED_ORDER:
            return {
                ...state,
                deletes: action.data.dropped_order
            }
        default:
            return state;
    }
};

export const getNewOrders = state => (
    Array.isArray(state.newOrders)
    &&
    state.newOrders
)
    ||
    [];

export const getDeletedOrders = state => (
    Array.isArray(state.deletes)
    &&
    state.deletes
) || [];

export const getNewOrderCount = state => getNewOrders(state).length || 0;

export const getList = state => (
    Array
        .isArray(state.orders)
        ?
        state.orders
        :
        []
);

export const getLastItem = state => state.last();

export const getItem = (state, id) => (
    (
        Array
            .isArray(state.orders)
        &&
        state.orders.find(o => o.id === id)
    )
    ||
    (
        Array
            .isArray(state.newOrders)
        &&
        state.newOrders.find(o => o.id === id)
    )
    ||
    null
);

export const getTotalOrderCount = state => state.count;