import React from 'react';
import {
    connect,
    useSelector
} from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles,
    Grid,
    Paper,
    ButtonGroup
} from '@material-ui/core';
import DeleteDialog from './DeleteConfirmation';
import Logo from '../logo/index';
import {
    Button,
    Typography,
    Divider
} from '../../commonComponents';
import {
    updateQuantity,
    removeFromCart
} from '../../redux/actions/cart';
import {
    process,
    drop
} from '../../redux/actions/order/Update';
import {
    getEmailFromOrderId
} from '../../redux/reducers';
import image from '../../assets/icon1.png';

const styles = theme => (
    {
        root: {
            minHeight: '90vh',
            height: 'auto',
            width: '100%',
            margin: '0 auto',
            backgroundColor: '#dcedc8',
            [theme.breakpoints.down('sm')]: {
                margin: '0 auto'
            },
        },
        button: {
            padding: '0px 20px'
        },
        text: {
            padding: theme.spacing(2),
        },
        divider: {
            width: '90%',
        },
        divider1: {
            width: '40%',
        },
        padding: {
            padding: 5
        },
    }
);

const CartPage = (props) => {
    const {
        classes,
        cart: {
            cartProducts,
            totAmount,
            deliveryCharge,
            discount
        },
        order,
        onClose,
        updateOrder,
        drop,
    } = props;

    const email = useSelector(state => getEmailFromOrderId(state, order.id));

    const [
        open,
        setOpen
    ] = React.useState(-1);

    const handleClickOpen = id => {
        setOpen(id);
    };

    const handleClose = () => {
        setOpen(-1);
    };

    return (
        <Paper
            elevation={1}
            className={classes.root}
        >
            <Logo />
            <Divider
                className={classes.divider1}
            />
            {
                cartProducts.map(
                    (n, index) => (
                        <Grid
                            container
                            title={n.name}
                            key={index}
                        // spacing={2}
                        >
                            <Grid
                                item
                            >
                                <img
                                    className={classes.padding}
                                    alt="complex"
                                    width='85'
                                    height='85'
                                    src={n.image || image}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm
                                container
                                // spacing={5}
                                className={classes.padding}
                            >
                                <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    className={classes.padding}
                                >
                                    <Grid
                                        item
                                        xs
                                    >
                                        <Typography
                                            gutterBottom
                                            variant="subtitle1"
                                            title={n.name}
                                        />
                                        <ButtonGroup
                                            color="primary"
                                        >
                                            <Button
                                                className={classes.button}
                                                buttonText={n.quantity}
                                            />
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    className={classes.padding}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        title={`৳ ${n.totAmount}`}
                                    />
                                </Grid>
                            </Grid>
                            <Divider
                                className={classes.divider}
                            />
                        </Grid>
                    )
                )
            }
            <Typography
                variant="subtitle1"
                align='right'
                className={classes.text}
                title={`Discount: ${discount}`}
            />
            <Typography
                variant="subtitle1"
                align='right'
                className={classes.text}
                title={`Total Cost: ${totAmount + deliveryCharge - discount}`}
            />
            <Button
                onClick={onClose}
                buttonText='Close'
            />
            <Button
                onClick={
                    () => { updateOrder(order, email) }
                }
                buttonText='Processed'
            />

            <Button
                onClick={
                    () => handleClickOpen(1)
                }
                buttonText='delete'
            />
            <DeleteDialog
                open={open !== -1}
                onClose={handleClose}
                id={open}
                order={order}
                drop={drop}
            />
        </Paper>
    );
};

CartPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    onChangeQuantity: updateQuantity,
    removeFromCart: removeFromCart,
    updateOrder: process,
    drop
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(CartPage));