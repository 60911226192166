import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

export default function ChipsArray({
    chipData,
    onDelete,
    sort,
    onSortDelete,
    priceRange,
    onPriceRangeDelete,
    max,
    min
}) {
    const classes = useStyles();
    const r = `৳${priceRange[0]} - ৳${priceRange[1]}`;

    return (
        <div className={classes.root}>
            {chipData.map((data) => (
                <li key={data.id}>
                    <Chip
                        variant='outlined'
                        color='primary'
                        label={data.name}
                        onDelete={() => onDelete(data)}
                        className={classes.chip}
                    />
                </li>
            ))}
            {
                sort
                    ?
                    <li>
                        <Chip
                            variant='outlined'
                            color='primary'
                            label={sort}
                            onDelete={() => onSortDelete(sort)}
                            className={classes.chip}
                        />
                    </li>
                    :
                    ""
            }
            {
                (priceRange[0] - min) !== 0 || (priceRange[1] - max) !== 0
                    ?
                    <li>
                        <Chip
                            variant='outlined'
                            color='primary'
                            label={r}
                            onDelete={() => onPriceRangeDelete()}
                            className={classes.chip}
                        />
                    </li>
                    :
                    ""
            }
        </div>
    );
}
