import {
    combineReducers
} from 'redux';
import {
    LOGOUT
} from '../../consts/user/Login';
// import register from './register';
import user, * as fromUser from './user';
import products, * as fromProducts from './products';
import category, * as fromCategory from './Category';
import cart, * as fromCart from './cart';
import checkout, * as fromCheckout from './Checkout';
import order, * as fromOrder from './order';
import promo, * as fromPromo from './promo';
import staticContent, * as fromStaticContent from './StaticContent';
import notifications, * as fromNotifications from './Notifications';
import inventories, * as fromInventories from './inventory';

const reducers = combineReducers(
    {
        // register,
        user,
        products,
        category,
        cart,
        checkout,
        order,
        promo,
        staticContent,
        notifications,
        inventories
    }
);

export default (
    state,
    action
) => {
    if (action.type === LOGOUT) {
        state = undefined
    }

    return reducers(state, action);
}

export const getUserIsFetching = state => fromUser.getIsFetching(state.user);

//Since only reducer may know the internal structure used to store the data.
// We need a root level selector to navigate between store objects.

//root level selector for login.username
// export const getUsername = state => fromLoginReducer.getUsername(state.login);

//root level selector for login.loggedIn
export const getIsLoggedIn = state => getToken(state) !== '';

export const getProfile = state => fromUser.getProfile(state.user);

export const getUserId = state => fromUser.getUserId(state.user);

export const getUserList = state => fromUser.getUsers(state.user);

export const getUserCount = state => fromUser.getCount(state.user);

export const getEmail = state => fromUser.getEmail(state.user);

export const getEmailById = (state, id) => fromUser.getEmailById(state.user, id);

export const getAuth = state => (
    {
        login: getToken(state) !== '',
        admin: fromUser.getProfile(state.user).isadmin,
    }
);

export const getCategorySubTreeAsArray = (state, id) => fromCategory.getCategorySubTreeAsArray(state.category, id);

export const getCategories = state => fromCategory.getCategories(state.category);

export const getMenu = state => fromCategory.getCategoryTree(state.category);

export const getCategory = (state, id) => fromCategory.getCategory(state.category, id);

export const getInventory = (state, id) => fromInventories.getInventory(state.inventories, id);

//root level selector for productlist.getProducts
export const getProducts = state => fromProducts.getProducts(state.products);
// export const getProducts = (state, scategory) => state.category.category[scategory] ? state.category.category[scategory].map(id => state.products.products.find(product => id === product.id)) : state.products.products;

export const getProduct = (state, id) => fromProducts.getProduct(state.products, id);

export const getProductByCategory = (state, catId) => fromProducts.getProductsByCategory(state.products, catId);

export const getFeaturedProducts = state => fromProducts.getFeaturedProducts(state.products);

export const getSearchedProducts = state => fromProducts.getSearchedProducts(state.products);

export const getProductName = (state, id) => getProduct(state, id).name || getProduct(state, id).banglaName;

export const getProductIsFetching = state => fromProducts.getIsFetching(state);

export const getToken = state => fromUser.getToken(state.user);

export const getIsFetching = state => true; //fromProductList.getIsFetching(state.products) || fromCategory.getIsFetching(state.category);

export const getImages = (state, id) => fromProducts.getImages(state.products, id) || '';

export const getCartIsFetching = (state, id) => fromCart.getIsFetching(state.cart, id);

export const getCart = state => fromCart.getCart(state.cart);

export const getCartProduct = (state, id) => fromCart.getCartProduct(state.cart, id);

export const getDiscountUser = state => {
    const activePromo = getActivePromo(state);

    const cart = getCart(state);

    if (!activePromo) return 0;

    return ((cart.promoCode && cart.totAmount >= activePromo.promo.minPurchase) * Math.min((cart.totAmount * activePromo.promo.discount) / 100.0, activePromo.promo.maxDiscount)) || 0;
}

export const getCartTable = state => {
    const cart = getCart(state);

    return {
        totAmount: cart.totAmount,
        deliveryCharge: cart.deliveryCharge,
        promoCode: cart.promoCode,
        discount: getDiscountUser(state),
        data: cart.cartProducts.map(cp => {
            const product = getCartProduct(state, cp);

            return ({
                ...product,
                name: getProductName(state, product.productId),
                image: getImages(state, product.productId)[0]
            })
        })
    }
};

export const getTotAmountById = (state, id) => fromCart.getTotAmountById(state.cart, id);

// export const getCartTotal = state => fromCart.getTotAmount(state.cart);

export const getCount = state => fromCart.getCount(state.cart);

export const getPictures = state => [];//fromProductList.getPictures(state.products);

export const getShippingAddress = state => fromCheckout.getShippingAddress(state.checkout);

export const getPaymentDetails = state => fromCheckout.getPaymentDetails(state.checkout);

export const getOrder = (state, id) => fromOrder.getItem(state.order, id);

export const getNewOrder = state => fromOrder.getNewOrderCount(state.order);

export const getNewOrders = state => {
    const orders = fromOrder.getNewOrders(state.order);

    return orders.map(o => ({
        ...o,
        totAmount: getTotAmountById(state, o.cart_id)
    }));
};

export const getOrderWithCart = (state, id) => {
    const order = getOrder(state, id);

    if (!order) return {};

    const cart = getOrderCartId(state, order.cart_id);

    return {
        order: order,
        cart: {
            ...cart,
            cartProducts: (cart.cartProducts || []).map(cp => {

                const product = getCartProduct(state, cp);

                if (!product) return null;

                return ({
                    ...product,
                    name: getProductName(state, product.productId) || 'No Product found',
                    image: getImages(state, product.productId)[0]
                });
            }).filter(p => p)
        }
    };
};

export const getInventories = state => {
    const inventories = fromInventories.getInventories(state.inventories);

    return inventories && inventories.map(i => ({
        count: i.count,
        id: i.id,
        product: getProduct(state, i.productID)
    }))
};

export const getTotalOrderCounts = state => fromOrder.getTotalOrderCount(state.order);

export const getTotalProducts = state => fromProducts.getProductCounts(state.products);

export const getOrders = (state, id) => {
    const orders = fromOrder.getList(state.order);

    return orders.map(o => ({
        ...o,
        totAmount: getTotAmountById(state, o.cart_id)
    }));
};

export const getDeletedOrders = (state, id) => {
    const orders = fromOrder.getDeletedOrders(state.order);

    return orders.map(o => ({
        ...o,
        totAmount: getTotAmountById(state, o.cart_id)
    }));
};

export const getLatestOrder = (state, id) => fromOrder.getLastItem(state.order, id);

export const getOrderCartId = (state, id) => fromCart.getCartById(state.cart, id);

export const getProfileById = (state, id) => fromUser.getProfileById(state.user, id);

export const getStaticContent = (state, slug) => fromStaticContent.get(state.staticContent, slug);

export const getUserName = state => fromUser.getUserName(state.user);

export const getNotification = state => fromNotifications.getNotification(state.notifications);

export const getPromoList = state => fromPromo.getList(state.promo);

export const getPromoByID = (state, id) => fromPromo.getPromoByID(state.promo, id);

export const getPromoByCode = (state, code) => fromPromo.getPromoByCode(state.promo, code);

export const getUserPromo = (state, key) => fromPromo.getUserPromo(state.promo, key);

export const getUserPromoList = state => fromPromo.getUserPromoList(state.promo);

export const getActivePromo = state => fromPromo.getActivePromo(state.promo);

export const getEmailFromOrderId = (state, id) => getEmailById(state, getOrderCartId(state, getOrder(state, id).cart_id).userId || '') || 'no email found';