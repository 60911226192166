import {
    READ
} from '../../../../consts/promo/User';
import {
    apiAction,
    thunk
} from '../../Helper';
import {
    readByCode
} from '../admin/Read';
import {
    disableAll, 
    update
} from './Update';

export const readActiveStatus = ({
    userName,
    code
}) => apiAction({
    url: '/api/promoprocess/' + userName + '/' + code,
    onSuccess: data => ({
        type: READ,
        data
    }),
    label: READ
});

export const readAll = userName => apiAction({
    url: '/api/promoprocessall/info/' + userName,
    onSuccess: data => thunk([
        ...(data.user_all_promo || []).map(p => ({
            type: READ,
            data: {
                createdAt: p.created_at,
                id: p.id,
                status: p.status,
                updatedAt: p.updated_at,
                userLimit: p.user_limit,
                userName: p.user_name,
                promo: {
                    code: p.promo.promo_code,
                    discount: p.promo.discount,
                    validity: p.promo.end_date,
                    limit: p.promo.limit,
                    maxDiscount: p.promo.max_discount,
                    minPurchase: p.promo.min_purchase,
                    name: p.promo.name
                }
            }
        })),
        data => {
            const disable = disableAll(userName).payload;

            const active = (data.user_all_promo || []).find(({status}) => status)
            
            return apiAction({
                ...disable,
                onSuccess: thunk([
                    disable.onSuccess,
                    () => {
                        if(
                            !active
                            ||
                            new Date(active.promo.end_date) < Date.now()
                            ||
                            active.user_limit < 1
                        ) return {
                            type: '....'
                        };
            
                        return update({
                            createdAt: active.created_at,
                            id: active.id,
                            status: active.status,
                            updatedAt: active.updated_at,
                            userLimit: active.user_limit,
                            userName: active.user_name,
                            promo: {
                                code: active.promo.promo_code,
                                discount: active.promo.discount,
                                validity: active.promo.end_date,
                                limit: active.promo.limit,
                                maxDiscount: active.promo.max_discount,
                                minPurchase: active.promo.min_purchase,
                                name: active.promo.name
                            }
                        });
                    }
                ])
            })
        },
        data => thunk((data.user_all_promo || []).map(
            promo => {
                const read = readByCode(promo.promo.promo_code).payload;

                return apiAction({
                    ...read,
                    onSuccess: thunk([
                        read.onSuccess,
                        data => update({
                            createdAt: promo.created_at,
                            id: promo.id,
                            status: promo.status,
                            updatedAt: promo.updated_at,
                            userLimit: promo.user_limit,
                            userName: promo.user_name,
                            promo: {
                                code: data.promo_code,
                                discount: data.discount,
                                validity: data.end_date,
                                limit: data.limit,
                                maxDiscount: data.max_discount,
                                minPurchase: data.min_purchase,
                                name: data.name
                            }
                        })
                    ])
                });
            }
        ))()
    ])(data),
    label: READ
});