import {
    API
} from '../../consts/Api';

export const apiAction = ({
    url = "",
    method = "GET",
    data = {},
    onSuccess = null,
    onFailure = null,
    label = "",
    headersOverride = null,
    race = null
}) => ({
    type: API,
    payload: {
        url,
        method,
        data,
        onSuccess,
        onFailure,
        label,
        headersOverride,
        race
    }
});

export const thunk = actions => data => (dispatch, getState) => {
    if(Array.isArray(actions)) {
        actions.forEach(async action => {
            if(typeof action === 'function') {
                await dispatch(action(data, getState));
            }
            else {
                await dispatch(action);
            }
        });

        return;
    }

    if(typeof actions === 'object' && actions.hasOwnProperty('type')) {
        return dispatch(actions);
    }

    if(typeof actions === 'function' && actions(data, getState).hasOwnProperty('type')) {
        return dispatch(actions(data, getState));
    }
};