import React, { 
	useState 
} from 'react';
import { 
	connect 
} from 'react-redux';
import classNames from 'classnames';
import { 
	withStyles,
	CssBaseline,
	Container,
	Grid,
} from '@material-ui/core';
import DateofBirth from './DateOfBirth';
import RadioButton from './RadioButton';
import { 
	registerData,
	defaultRegisterState
} from './Data';
import {
	Typography,
	Textfield,
	Link,
	Button
} from '../../commonComponents';
import Logo from '../logo/index';
import { 
	register 
} from '../../redux/actions/user/Register';

const styles = theme => ({
	root: {
		margin: '0 auto',
		width: '100%',
		height: 'auto',
		minHeight: '80vh',
	},
	margin: {
		margin: theme.spacing(1),
	},
	textField: {
		width: '100%',
		margin: '0 auto',
	},
	'@global': {
		body: {
		  	backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
        marginTop: 110,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			marginTop: 60,
		},
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	item: {
		width: '40%',
		margin: '0 auto',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	}
});

const Register = ({
	classes,
	register,
	...props
}) => {
	const [
		state, 
		setState
	] = useState(defaultRegisterState);

	const validate = (key, value) => {
		if(props.patterns[key] === undefined) return true;
		return !props.patterns[key].test(value);
	};

	const onChange = key => event => {
		let value = null;
		// if event.target.value is present then event.target.value otherwise we are assuming
		//it is password visibility toggle action, thus the 2nd part.
		if(key === "dateofbirth"){
			value = event;
		}
		else{
			value = event.target.value;
		}
			
		let valid = typeof value === 'string' ? value.length < 201 : true;

		valid = valid && validate(key, value);

		if(key === "password2") valid = ( value === state.password1);

		setState({
			...state,
			[key]: value,
            valid: {
				...state.valid,
				password2: ( state.password1 === state.password2),
				[key]: valid,
            }
		});
	};

	const submit = () => {
		if(state.password1 !== state.password2){
			alert('passwords doesn\'t match');
			return;
		}

		let s='';

		if(!state.dateofbirth) {
			s+='Invalid age.';
		}

		if(!state.gender) {
			s+=(s === '' ? '' : ' ') + 'Gender Must be selected.';
		}

		if(s) {
			alert(s);
			return;
		}
		
		register({
			firstName: state.firstName, 
			lastName: state.lastName,
			userName: state.userName,
			gender: state.gender,
			dateofbirth: state.dateofbirth,
			address: state.address, 
			phone: state.phone, 
			email: state.email,
			password: state.password1
		});
	};

	return (
		<Container 
			component="main" 
			maxWidth="lg"
		>
			<CssBaseline />
			<div 
				className={classes.paper}
			>
				<Logo />
				<Typography 
					variant='h6' 
					title='Sign Up'
				/>
				<form 
					className={classes.form}
				>
					<Grid
						container
						direction='column'
						justify='flex-start'
						alignItems='center'
						spacing={2}
						className={classes.root}
					>
						{
							registerData.map(
								(i, index) => (
									<Grid 
										item
										key={index}
										xs={12}
										className={classes.item}
									>
										<Textfield
											label={i.label}
											type={
												i.keyval === 'password1' || i.keyval === 'password2'
												?
												'password'
												:
												'text'
											}
											// id={keyval}
											className={
												classNames(
													classes.margin, 
													classes.textField
												)
											}
											error={!state.valid[i.keyval]}
											required={state.required[i.keyval]}
											helperText={
												state.valid[i.keyval]
												? 
												"" 
												: 
												i.helperText
											}
											value={state[i.keyval]}
											onChange={onChange(i.keyval)}
										/>
									</Grid>
								)
							)
						}
						<Grid 
							item
							xs={12} 
							className={classes.item} 
						>					
							<DateofBirth 
								value={state.dateofbirth} 
								onChange={
									onChange('dateofbirth')
								}
							/>
						</Grid>
						<Grid 
							item
							xs={12} 
							className={classes.item} 
						>					
							<RadioButton 
								value={state.gender} 
								onChange={
									onChange('gender')
								}
							/>
						</Grid>
						<Grid
							item 
							xs={12}
							className={classes.item}
							title="Make sure you fill all the fields before sign up"
						>
							<Button
								variant="contained"
								color="primary"
								onClick={submit}
								className={
									classNames(
										classes.margin, 
										classes.textField
									)
								}
								buttonText='Sign Up'
							/>
						</Grid>
					</Grid>	
					<Link 
						to="/login"
					>
						<Typography
							gutterBottom
							align='center'
							title='Already have an account? Sign in'
						/>
					</Link>
				</form>
			</div>	
		</Container>	
	);
};

const mapStateToProps = state => ({
	patterns: {
		firstName: /\.\.|--|''|[^ A-Za-z.'-]/,
		lastName: /\.\.|--|''|[^ A-Za-z.'-]/,
		phone: /[^\d]/,
		password1: /^.{0,7}$/,
	},
});

const RegisterContainer = connect(mapStateToProps, {register})(withStyles(styles)(Register));

export default RegisterContainer;
