import {
    LOGIN
} from '../../../consts/user/Login';
import {
    API_START,
    API_END
} from '../../../consts/Api';

export default (
    state ={
        token: '',
        isFetching: false,
        error: null,
        user_name: '',
    }, 
    action
) => {
    switch(action.type) {
        case LOGIN:
            return {
                ...state,
                token: action.data.token,
                user_name: action.data.user_name
            };
        case API_START:
            if(action.payload === LOGIN) return {
                ...state,
                isFetching: true,
                error: null,
            }
            return state;
        case API_END:
            if(action.payload === LOGIN) return {
                ...state,
                isFetching: false,
            }
            return state;
        default:
            return state;
    }
};

export const getIsFetching = state => state.isFetching || false;

export const getToken = state => state.token || '';