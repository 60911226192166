export const promo_properties = [
    {
        label: 'Code',
        keyval: 'code',
        type: 'text'
    },
    {
        label: 'Name',
        keyval: 'name',
        type: 'text'
    },
    {
        label: 'Discount',
        keyval: 'discount',
        type: 'number'
	},
	{
        label: 'Maximum amount discount',
        keyval: 'maxDiscount',
        type: 'number'
    },
    {
        label: 'Minimum purchase required',
        keyval: 'minPurchase',
        type: 'number'
    },
    {
        label: 'Validity',
        keyval: 'validity',
    },
    {
        label: 'Usage limit',
        keyval: 'usageLimit',
        type: 'number'
    }
];

export const emptyState = {
    code: '',
    name: '',
    discount: 0,
    maxDiscount: 0,
    minPurchase: 0,
    validity: new Date(),
    usageLimit: 0
};