import React, { 
    useState 
} from 'react';
import { 
    connect 
} from 'react-redux';
import { 
    makeStyles,
    FormControlLabel,
    Checkbox,
    Paper,
    Grid
} from '@material-ui/core';
import { 
    loginData 
} from './Data';
import {
    Typography,
    Textfield,
    Button,
    Link
} from '../../commonComponents';
import Logo from '../logo/index';
import { 
    login 
} from '../../redux/actions/user/Login';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                height: 'auto',
                minHeight: '80vh',
                padding: theme.spacing(4),
                width: '100%',
                margin: '0 auto'
            },
            paper: {
                margin: '0 auto',
                display: 'flex',
                marginTop: 70,
                [theme.breakpoints.down('sm')]: {
                    marginTop: 30,
                },
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            },
            form: {
                width: '100%', // Fix IE 11 issue.
                marginTop: theme.spacing(1),
            },
            submit: {
                margin: theme.spacing(3, 0, 2),
            },
            link1: {
                textAlign: 'left'
            },
            link2: {
                textAlign: 'right',
            }
        }
    )
);

const mapDispatchToProps = {
    login
};

export default connect(
    null, 
    mapDispatchToProps
)(
    ({
        login,
    }) => {
        const classes = useStyles();

        const [
            user_name, 
            setUser_name
        ] = useState('');
        const [
            password, 
            setPassword
        ] = useState('');

        const onEnter = e => {
            if((e.key === 'Enter' || e.key === 'enter') && !e.shiftKey) {
                if(user_name !== '' && password !== '') {
                    login({
                        user_name,
                        password
                    });
                }
            }
        };

        return (
            <Grid 
                container 
                justify='center' 
                spacing={2} 
                component="main" 
                className={classes.root}
            >
                <Grid 
                    item 
                    xs={12} 
                    sm={12} 
                    md={5} 
                    component={Paper} 
                    elevation={0} 
                    square
                >
                    <div className={classes.paper}>
                        <Logo />
                        <Typography 
                            title='Sign In' 
                            variant='h6' 
                        />
                        <form 
                            className={classes.form}
                        >
                            {
                                loginData.map(
                                    (i, index) => (
                                        <Textfield
                                            key={index}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            keyval={i.keyval}
                                            label={i.label}
                                            name={i.keyval}
                                            type={
                                                i.keyval === 'password'
                                                ?
                                                'password'
                                                :
                                                'text'
                                            }
                                            // autoComplete="user_name"
                                            InputProps={{
                                                onChange: (
                                                    {
                                                        target: {value}
                                                    }
                                                ) => {
                                                    i.keyval === 'user_name'
                                                    ?
                                                    setUser_name(value)
                                                    :
                                                    setPassword(value)
                                                },
                                                onKeyUp: onEnter,
                                            }}
                                        />
                                    )
                                )
                            }
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        value="remember" 
                                        color="primary" 
                                    />
                                }
                                label="Remember me"
                            />
                            <Button
                                // type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                title="Click here to log in"
                                onClick={
                                    () => login({
                                            user_name, 
                                            password
                                        })
                                    }
                                className={classes.submit}
                                buttonText='Sign In'
                            />
                            <Grid 
                                container
                            >
                                <Grid 
                                    item 
                                    xs
                                    className={classes.link1}
                                >
                                    <Link 
                                        to="/forget-password" 
                                        variant="body2"
                                        text='Forgot password?'
                                    />
                                </Grid>
                                <Grid 
                                    item
                                    className={classes.link2}
                                >
                                    <Link 
                                        to="/register" 
                                        variant="body2"
                                        text='New Customer? Sign Up'
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Grid>
            </Grid>
        );
    }
)
