import ProductList from 'components/productList';
import ProductDetails from 'components/productDetails';
import HomePage from 'components/homePage';
import Checkout from 'components/checkout';
import FAQ from '../components/footerPages/FAQ';
import TermsAndConditions from '../components/footerPages/TermsAndConditions';
import CartPage from '../components/cart/CartPage';
import ContactUs from '../components/contactUs/index';
import AboutUs from '../components/footerPages/AboutUs';
import PrivacyPolicy from '../components/footerPages/PrivacyPolicy';
import RefundPolicy from '../components/footerPages/RefundPolicy';
import Review from '../components/review/index';
import Login from 'components/loginRegister/Login';
import Register from 'components/loginRegister/Register';
import AdminCategoryEntry from 'components/adminCategoryEntry';
import AdminProductEntry from 'components/adminProductEntry';
import AdminPromoEntry from 'components/adminPromoEntry';
import UserList from 'components/userList/index.jsx';
import UserProfileForAdmin from 'components/userList/UserProfileForAdmin.jsx';
import CategoryList from 'components/adminCategoryList/index.jsx';
import AdminProductList from 'components/adminProductList/index.jsx';
import AdminPromoList from 'components/adminPromoList/index.jsx';
import Profile from 'components/user/Profile';
import ForgetPassword from 'components/forgetPassword';
import ResetPassword from 'components/resetPassword';
import AdminUserPurchaseHistory from 'components/adminUserPurchaseHistory/index.jsx';
import DeletedOrders from 'components/adminDeletedOrders';
import NewOrders from '../components/newOrders';
import StaticContentEditor from '../components/staticContentEditor';
import SliderLinks from '../components/homeLinks';
import LinkToStaticPage from '../components/footer/LinkToStaticPages';
import BannerEdit from '../components/bannerEdit';
import SliderImageEdit from '../components/sliderImages';
import AccountVerify from '../components/accountVerify';
import Inventory from '../components/inventory';
import {
    NONE,
    ADMIN,
    PUBLIC
} from '../consts/RouteAuth';

export default [
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/',
        exact: true,
        component: HomePage
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/:category/productlist',
        component: ProductList
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/productsearch',
        component: ProductList
    },
    {
        authLevel: PUBLIC,
        max: PUBLIC,
        path: '/checkout',
        component: Checkout
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/forget-password',
        component: ForgetPassword
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/productdetails/:productId',
        component: ProductDetails
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/inventory',
        component: Inventory
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/FAQ',
        component: FAQ
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/terms-and-conditions',
        component: TermsAndConditions
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/reset-password',
        component: ResetPassword
    },
    {
        authLevel: PUBLIC,
        max: PUBLIC,
        path: '/cart',
        component: CartPage
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/contactus',
        component: ContactUs
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/about-us',
        component: AboutUs
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/review',
        component: Review
    },
    {
        authLevel: NONE,
        max: NONE,
        path: '/login',
        component: Login
    },
    {
        authLevel: NONE,
        max: NONE,
        path: '/register',
        component: Register
    },
    {
        authLevel: PUBLIC,
        max: PUBLIC,
        path: '/userprofile',
        component: Profile
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/adminprofile',
        component: Profile
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/edit-slider-images',
        component: SliderImageEdit
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/edit-slider-links',
        component: SliderLinks
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/admincategoryentry',
        component: AdminCategoryEntry
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/adminproductentry',
        component: AdminProductEntry
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/adminpromoentry',
        component: AdminPromoEntry
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        exact: true,
        path: '/adminuserlist',
        component: UserList
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/admincategorylist',
        component: CategoryList
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/adminproductlist',
        component: AdminProductList
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/adminpromolist',
        component: AdminPromoList
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/adminuserlist/user/:id',
        component: UserProfileForAdmin
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/adminuserpurchasehistory',
        component: AdminUserPurchaseHistory
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/new-orders',
        component: NewOrders
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/deleted-orders',
        component: DeletedOrders
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/static-content-edit',
        component: StaticContentEditor
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/static-contents',
        component: LinkToStaticPage
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/privacy-policy',
        component: PrivacyPolicy
    },
    {
        authLevel: NONE,
        max: ADMIN,
        path: '/refund-policy',
        component: RefundPolicy
    },
    {
        authLevel: ADMIN,
        max: ADMIN,
        path: '/edit-banner',
        component: BannerEdit
    },
    {
        authLevel: NONE,
        max: PUBLIC,
        path: '/accountverify/:key',
        component: AccountVerify
    }
];