import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    Dialog,
    makeStyles,
    DialogActions,
    DialogContent,
    Grid,
    DialogTitle
} from '@material-ui/core';
import {
    data,
    emptyState
} from './Data';
import {
    Button,
    Textfield
} from '../../commonComponents';
import CategoryEntry from '../categoryEntry';
import {
    getProducts
} from '../../redux/reducers';
import {
    create
} from '../../redux/actions/inventory/Create';

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: '0 auto',
        width: '100%',
        color: theme.palette.secondary.dark,
    },
}));

const CreateInventory = ({
    open,
    onClick,
    suggestions,
    create,
    onClose
}) => {
    const classes = useStyles();

    const [
        state,
        setState
    ] = useState({
        id: '',
        productName: '',
        Quantity: '',
    });

    const onChange = key => event => {
        setState({
            ...state,
            [key]: parseInt(event.target.value),
        });
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Create a new inventory</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='stretch'
                        spacing={3}
                    >
                        <CategoryEntry
                            onClick={
                                (_, v) => setState({
                                    ...state,
                                    id: v.id
                                })
                            }
                            value={suggestions.find(
                                ({ id }) => state.id === id
                            )
                                ||
                                null}
                            options={suggestions}
                            label="Select Product for Inventory"
                        // multiple={false}
                        />
                        {
                            data.map(
                                (i, index) => (
                                    <Grid
                                        item
                                        xs
                                        key={index}
                                    >
                                        <Textfield
                                            label={i.label}
                                            // keyval="quantity"
                                            value={state[i.keyval]}
                                            placeholder={i.placeholder}
                                            className={classes.textField}
                                            onChange={onChange(i.keyval)}
                                            type='number'
                                        // state={state}
                                        />
                                    </Grid>
                                )
                            )
                        }

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        onClick(state);
                        setState(emptyState);
                        onClose();
                    }} color="primary" buttonText='Create' />
                    <Button onClick={onClose} color="primary" buttonText='Cancel' />
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
    suggestions: getProducts(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: state => console.log('state', state) || dispatch(
        (create)(state)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateInventory);
