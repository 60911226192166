import React from 'react';
import {
    makeStyles,
    Grid,
    fade,
} from '@material-ui/core';
import {
    FormatListBulleted,
    Sort,
} from '@material-ui/icons';
import SearchDropDown from '../searchField/DropDown';
import PriceSlider from './PriceSlider';
import FilterMenus from './FilterMenus';
import FilterChips from './FilterChips';
import {
    // categoryMenus,
    sortMenus
} from './Data';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: '5px auto',
        // '& .MuiInputBase-root': {
        //     fontSize: '0.8rem'
        // },
        // '& .MuiFormLabel-root': {
        //     fontSize: '0.8rem'
        // },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    grid: {
        margin: '0 auto'
    },
    searchIcon: {
        width: theme.spacing(4.5),
        height: '100%',
        position: 'absolute',
        // pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        zIndex: 2000
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        // height: '55px',
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        width: '100%',
        '&:focus': {
            // backgroundColor: theme.palette.secondary.light
        },
    },
    searchField: {
        position: 'relative',
        display: 'flex',
        height: '35px',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.secondary.main, 0.5),
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.main, 0.7),
        },
        margin: 4,
        width: '100%',
        color: theme.palette.primary.main,
        '& .MuiOutlinedInput-root': {
            padding: 0,
            height: '35px'
        },
        '& .MuiFormLabel-root': {
            transform: 'translate(14px, 12px) scale(1)'
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: fade(theme.palette.secondary.main, 0.5)
        }
    },
    menus: {
        width: '100%'
    },
    priceSlider: {
        margin: theme.spacing(0.8)
    }
}));

// const useQuery = () => new URLSearchParams(useLocation().search);

export default function Filter({
    handlePriceRange,
    priceRange,
    addCategory,
    sortSelect,
    selectedCategory,
    onDelete,
    selectedSort,
    onSortDelete,
    onPriceRangeDelete,
    categoryMenus,
    max,
    min,
    filtersVisible
    // applyFilter,
    // handleApplyFilter
}) {
    const classes = useStyles();

    return (
        <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            // spacing={1}
            className={classes.root}
        >
            <Grid
                item
                sm={12}
                xs={12}
                className={classes.searchField}
            >
                <SearchDropDown
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                />
            </Grid>
            {
                filtersVisible
                    ?
                    <>
                        <Grid
                            item
                            sm={4}
                            xs={12}
                            className={classes.priceSlider}
                        >
                            <PriceSlider
                                handleChange={handlePriceRange}
                                value={priceRange}
                                max={max}
                                min={min}
                            />
                        </Grid>
                        {
                            categoryMenus.length !== 0
                                ?
                                <Grid
                                    item
                                    sm={2}
                                    xs={4}
                                    className={classes.menus}
                                >
                                    <FilterMenus
                                        Icon={FormatListBulleted}
                                        menu='Category'
                                        menus={categoryMenus}
                                        onMenuClick={addCategory}
                                        x={5}
                                    />
                                </Grid>
                                :
                                ""
                        }
                        <Grid
                            item
                            sm={2}
                            xs={4}
                            className={classes.menus}
                        >
                            <FilterMenus
                                Icon={Sort}
                                menu={'Sort\u00a0By'}
                                onMenuClickSort={sortSelect}
                                menus={sortMenus}
                                x={14}
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <FilterChips
                                chipData={selectedCategory}
                                onDelete={onDelete}
                                sort={selectedSort.name}
                                onSortDelete={onSortDelete}
                                priceRange={priceRange}
                                onPriceRangeDelete={onPriceRangeDelete}
                                max={max}
                                min={min}
                            />
                        </Grid>
                    </>
                    :
                    null
            }

            {/* {
                selectedCategory.length !== 0 || priceRange[0] !== 120 || priceRange[1] !== 950 || selectedSort !== ''
                    ?
                    <Grid
                        item
                        sm={12}
                        xs={12}
                    >
                        <div
                            align='center'
                        >
                            <Button
                                color='primary'
                                buttonText='Apply Filters'
                                onClick={() => handleApplyFilter(true)}
                            />
                        </div>

                    </Grid>
                    :
                    ""
            } */}

        </Grid>

    );
}
