import {
    apiAction
} from './Helper';
import {
    appendNotification
} from './Notifications';

export const accountVerify = id => apiAction({
    url: '/api/accountverify/' + id,
    onSuccess: () => appendNotification({
        message: 'Account verification was successful.',
        variant: 'success'
    }),
    onFailure: () => appendNotification({
        message: 'Failed to verify. Please request a new verification link.',
        variant: 'error'
    })
});