import React from 'react';
import {
    useLocation
} from 'react-router-dom';
import CategoryDetails from './CategoryDetails';

const AdminCategoryEntry = () => {
    const {
        state
    } = useLocation();
    
    return (
        <CategoryDetails 
            id={
                state 
                || 
                ''
            }
        />
    );
};

export default AdminCategoryEntry;