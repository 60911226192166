import React, {
	useState
} from 'react';
import {
	connect
} from 'react-redux';
import PropTypes from 'prop-types';
import {
	Grid,
	withStyles,
} from '@material-ui/core';
import {
	data,
	emptyState
} from './Data';
import CategoryEntry from '../categoryEntry';
import {
	Button,
	Textfield
} from '../../commonComponents';
import {
	getCategories,
	getCategory
} from '../../redux/reducers';
import {
	create
} from '../../redux/actions/category/Create';
import {
	update
} from '../../redux/actions/category/Update';

const styles = theme => (
	{
		root: {
			width: '60%',
			margin: '0 auto',
			// marginTop: 40,
			height: '100%',
			padding: theme.spacing(1),
			color: theme.palette.secondary.dark,
			[theme.breakpoints.down('sm')]: {
				width: '95%',
			},
		},
		textField: {
			margin: '0 auto',
			width: '100%',
			color: theme.palette.secondary.dark,
		},
		button: {
			margin: '0 auto',
		}
	}
);

const CategoryDetails = ({
	classes,
	onClick,
	suggestions,
	initialState
}) => {
	const [
		state,
		setState
	] = useState({
		id: initialState.id || '',
		categoryName: initialState.name || '',
		description: initialState.description || '',
		parentCategory: initialState.category_id || '',
	});

	const onChange = key => event => {
		setState({
			...state,
			[key]: event.target.value,
		});
	}

	return (
		<Grid
			container
			direction='column'
			justify='center'
			alignItems='stretch'
			spacing={3}
			className={classes.root}
		>
			{
				data.map(
					(item, index) => (
						<Grid
							item
							key={index}
							xs
						>
							<Textfield
								label={item.label}
								keyval={item.keyval}
								value={state[item.keyval]}
								required={item.required}
								placeholder={item.placeholder}
								className={classes.textField}
								onChange={onChange(item.keyval)}
							// state={state}
							/>
						</Grid>
					)
				)
			}
			<Grid
				item
				xs
			>
				<CategoryEntry
					onClick={
						(_, v) => setState({
							...state,
							parentCategory: v.id
						})
					}
					value={
						suggestions.find(
							({ id }) => state.parentCategory === id
						)
						||
						null
					}
					options={suggestions}
					label="Select Parent Category"
				// multiple={false}
				/>
			</Grid>
			<Grid
				item
				xs={6}
				className={classes.button}
			>
				<Button
					size="small"
					color="primary"
					onClick={
						() => {
							onClick(state);
							setState(emptyState);
						}
					}
					buttonText="Update"
				/>
			</Grid>
		</Grid>
	);
};

CategoryDetails.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
	suggestions: getCategories(state),
	initialState: getCategory(state, ownProps.id) || {}
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onClick: state => dispatch(
		(
			ownProps.id === ''
				?
				create
				:
				update)(state)
	),
});

const CategoryDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CategoryDetails));

export default CategoryDetailsContainer;