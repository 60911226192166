import React from 'react';
import {
	connect
} from 'react-redux';
import {
	withStyles,
	Grid
} from '@material-ui/core';
import UserPromoEntry from './UserPromoEntry';
import UserPromoList from './UserPromoList';
import {
	Typography
} from '../../commonComponents';
import UpdatePassword from './UpdatePassword';
import EditProfileInformation from './EditProfileInformation';
// import OrderHistory from './OrderHistory';
import {
	getProfile,
	getUserIsFetching
} from '../../redux/reducers/index';
import Loading from '../loading';
import User from '../../assets/user.svg';

const styles = theme => ({
	root: {
		width: '100%',
		height: 'auto',
		minHeight: '70vh',
		margin: '100px auto 0 auto',
		color: theme.palette.info.main,
		[theme.breakpoints.down('sm')]: {
			margin: '50px auto 0 auto'
		},
	},
	item: {
		margin: '0 auto',
		padding: 15
	},
	button: {
		margin: theme.spacing(2),
		flexGrow: 0
	},
	edit: {
		display: 'inline-flex',
		margin: '0 auto',
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			padding: 0
		},
	}
});

const Profile = ({
	classes,
	profile,
	isFetching,
}) => {
	if (isFetching) {
		return <Loading />;
	}

	let join = new Date(profile.created_at);

	return (
		<Grid
			container
			direction="column"
			alignItems='flex-start'
			justify='flex-start'
			className={classes.root}
		>
			<Grid
				item
				xs={12}
				md={8}
				container
				direction="column"
				className={classes.item}
			>
				<img
					width='60'
					height='60'
					src={User}
					alt="user"
				/>
				<Typography
					variant='h6'
					title={
						profile.first_name !== ''
							?
							(profile.first_name + ' ' + profile.last_name)
							:
							profile.name
					}
				/>
				<Typography
					title={profile.address[0]}
					color='inherit'
				/>
				<Typography
					title={profile.phone}
					color='inherit'
				/>
				<Typography
					color="textSecondary"
					title={`Joined In ${join.toString().slice(0, 15)}`}
				/>
				<div
					align='center'
					className={classes.edit}
				>
					<UpdatePassword />
					<EditProfileInformation />
				</div>
				{
					profile.isadmin
						?
						""
						:
						<>
							<UserPromoEntry />
							<br />
							<UserPromoList />
						</>
				}


				{/* <OrderHistory /> */}
			</Grid>
			{/* <Grid
				item
				xs
				className={classes.button}
			>
				<UpdatePassword />
			</Grid>
			<Grid
				item
				xs
				className={classes.button}
			>
				
			</Grid>
				
			</Grid> */}
		</Grid>
	);
}

const mapStateToProps = (state) => ({
	profile: getProfile(state),
	isFetching: getUserIsFetching(state),
});

export default connect(mapStateToProps, null)(withStyles(styles)(Profile)); 