export const data = [
    {
        label: 'Category Name',
        keyval: 'categoryName',
        placeholder: "Name of the Category",
        required: true
    },
    {
        label: 'Description',
        keyval: 'description',
        placeholder: "Provide a short description",
        required: true
    },
];

export const emptyState = {
    id: '',
    categoryName: '',
    description: '',
    parentCategory: '',
};