import {
    PLANT,
    PLANTER,
    SOIL_MIXING
} from '../../consts/product/Types';

export const data = [
    {
        label: 'Product Name',
        keyval: 'name',
        required: true,
    },
    {
        label: 'Bangla Name',
        keyval: 'banglaName',
        required: false,
    },
    {
        label: 'Scientific Name',
        keyval: 'scientificName',
        required: false,
    },
    {
        label: 'Planter Name',
        keyval: 'planterName',
        required: false,
    },
    {
        label: 'Planter Size',
        keyval: 'planterSize',
        required: false,
    },
    {
        label: 'Planter Price',
        keyval: 'planterPrice',
        required: false,
    },
    {
        label: 'Planter Material',
        keyval: 'planterMaterial',
        required: false,
    },
    {
        label: 'Measurement Unit',
        keyval: 'measurementUnit',
        required: false,
    },
    {
        label: 'Buy Price',
        keyval: 'buyPrice',
        required: false,
    },
    {
        label: 'Delivery Charge',
        keyval: 'deliveryCharge',
        required: false,
    },
    {
        label: 'Sell Price',
        keyval: 'sellPrice',
        required: true,
    },
    {
        label: 'Description',
        keyval: 'description',
        required: true,
    },
    {
        label: 'Benefits & Uses',
        keyval: 'BenefitsAndUses',
        required: false,
    },
    {
        label: 'Images',
        keyval: 'image',
        required: true,
    }
];

export const care = [
    {
        label: 'Light Requirement',
        keyval: 'lightRequirement',
    },
    {
        label: 'Water Requirement',
        keyval: 'waterRequirement',
    }
];

export const typeOptions = [
    PLANT, PLANTER, SOIL_MIXING
];

export const emptyState = {
    name: '',
    scientificName: '',
    BenefitsAndUses: '',
    planterName: '',
    planterSize: '',
    planterPrice: 0,
    measurementUnit: '',
    buyPrice: 0,
    deliveryCharge: 0,
    sellPrice: 0,
    description: '',
    lightRequirement: '',
    waterRequirement: '',
    isTub: false,
    image: '',
    productType: typeOptions[0],
    categories: [],
    open: false,
    anchorEl: null,
    isStockOut: false,
    checkFeature: false,
}