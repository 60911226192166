import {
    FETCH_CART, 
    FETCH_PRODUCT
} from '../../../consts/cart/Fetch';
import {
    apiAction, 
    thunk
} from '../Helper';
import {
    getCartIsFetching
} from '../../reducers';

export const fetchCart = id => apiAction(
    {
        url: '/api/cart/' + id,
        data: {
            id
        },
        onSuccess: thunk(
            [
                data => (
                    {
                        type: FETCH_CART,
                        data
                    }
                ),
                data => dispatch => (
                    (data
                    .cart_products || [])
                    .forEach(async cp => dispatch(fetchProduct(cp)))
                )
            ]
        ),
        label: FETCH_CART,
        race: getCartIsFetching
    }
);

export const fetchProduct = id => apiAction(
    {
        url: '/api/cartproduct/' + id,
        onSuccess: data => ({
            type: FETCH_PRODUCT,
            data: {
                ...data,
                id
            }
        }),
        label: FETCH_PRODUCT,
        race: getCartIsFetching
    }
)