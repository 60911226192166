import {
    FETCH,
    FETCH_ALL,
    FETCH_PRODUCT_COUNT
} from '../../../consts/product/Fetch';
import {
    apiAction,
    thunk
} from '../Helper';

export const fetch = id => apiAction(
    {
        url: '/api/product/' + id,
        label: FETCH,
        onSuccess: data => ({
            type: FETCH,
            data,
        })
    }
);

export const update = products => dispatch => (products.all_product || []).map(p => dispatch(
    {
        type: FETCH,
        data: {
            id: p.id,
            name: p.name,
            scientificName: p.scientific_name,
            banglaName: p.bangla_name,
            BenefitsAndUses: p.benefits,
            planterName: p.planter_name,
            planterSize: p.planter_size,
            planterPrice: p.planter_price,
            planterMaterial: p.planter_material,
            measurementUnit: p.measurement_unit,
            productType: p.product_type,
            buyPrice: p.buy_price,
            sellPrice: p.sell_price,
            deliveryCharge: p.delivery_charge,
            description: p.description,
            lightRequirement: p.light_requirement,
            waterRequirement: p.water_requirement,
            image: p.image_url,
            categories: p.category_id,
            checkFeature: p.featured_products,
            curation: p.curation,
            isTub: p.is_tub,
            isStockOut: p.product_stock,
            relatedProducts: []
        },
        id: p.id
    }
));

export const fetchAll = () => apiAction(
    {
        url: '/api/product/info/all',
        label: FETCH_ALL,
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH_ALL,
                    data: data.all_product
                }),
                update
            ]
        )
    }
);

export const fetchProductCount = () => apiAction(
    {
        url: '/api/product/info/count',
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH_PRODUCT_COUNT,
                    data
                }),
            ]
        ),
        label: FETCH_PRODUCT_COUNT
    }
);