import {
    CREATE
} from '../../../consts/order/Create';
import {
    apiAction,
    thunk
} from '../Helper';
import {
    getUserId,
    getCart
} from '../../reducers';
import {
    fetchOrderByID
} from './Fetch';

export const create = order => (
    dispatch,
    getState
) => dispatch(apiAction(
    {
        url: '/api/order',
        method: 'POST',
        data: {
            firstname: order.firstName,
            lastname: order.lastName,
            contactnumber: order.contactNumber,
            cart_id: getCart(getState()).id,
            address: [order.address],
            customer_id: getUserId(getState()),
            is_confirmed: false,
            is_processed: false,
            is_dropped: false
        },
        onSuccess: thunk(
            [
                data => ({
                    type: CREATE,
                    data
                }),
                fetchOrderByID,
            ]
        ),
        label: CREATE
    }
));