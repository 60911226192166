import React from 'react';
import {
    makeStyles,
    Divider,
    ListItemText,
    ListItemAvatar
} from '@material-ui/core';
import {
    Link
} from '../../commonComponents';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: '100%',
            maxWidth: '36ch',
            backgroundColor: theme.palette.background.paper,
        },
        inline: {
            display: 'inline',
        },
        link: {
            verticalAlign: 'middle',
            width: '100%',
            color: theme.palette.secondary.dark,
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'stretch'
        },
        img: {
            objectFit: 'cover'
        }
    }));

export default function AlignItemsList({
    option
}) {
    const classes = useStyles();

    return (
        // <List className={classes.root}>
        <>
            <Link
                to={`/productdetails/${option.id}`}
                className={classes.link}
                title="Click to see this product's detail"
            >
                {/* <ListItem
                className={classes.link}
                // component={Link} 
                // to={`/productdetails/${option.id}`} 
                alignItems="flex-start"> */}
                <ListItemAvatar>
                    <img
                        alt={option.name}
                        src={option.image}
                        height='52px'
                        width='52px'
                        className={classes.img}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={option.name}
                    secondary={option.isStockOut === true ? 'Out of Stock' : `৳ ${option.sellPrice}`}
                />
                {/* </ListItem> */}
            </Link>
            <Divider variant="inset" />
        </>
        // </List>
    );
}
