import React from 'react';
import { 
    withStyles, 
    makeStyles 
} from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import compose from 'recompose/compose';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles({
    rating1: {
        minWidth: 200,
        display: 'flex',
        alignItems: 'center',
    },
    textarea: {
        width: '100%'
    },
    dialog: {
        overflowX: 'hidden'
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { 
        children, 
        classes, 
        onClose 
    } = props;

    return (
        <MuiDialogTitle 
            disableTypography 
            className={classes.root}
        >
            <Typography variant="h6">
                {children}
            </Typography>
            {onClose ? (
                <IconButton 
                    aria-label="close" 
                    className={classes.closeButton} 
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };

const GiveReview = ({
    open, 
    setOpen, 
    width
}) => {
    const [
        hover, 
        setHover
    ] = React.useState(-1);
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog 
                fullScreen={
                    (width === 'xs' || width === 'sm') 
                    ? 
                    true 
                    : 
                    false
                } 
                onClose={handleClose} 
                aria-labelledby="customized-dialog-title" 
                open={open}
                className={classes.dialog}
            >
                <DialogTitle 
                    id="customized-dialog-title" 
                    onClose={handleClose}
                >
                    Gaachwala Review
                </DialogTitle>
                <DialogContent dividers>
                    {/* <Box 
                        width='100%' 
                        component="fieldset" 
                        borderColor="transparent"
                    > */}
                        <Typography variant="Subtitle1">
                            Rating
                        </Typography>
                        <div className={classes.rating1}>
                            <Rating
                                name="hover-side"
                                value='0'
                                precision={0.5}
                                onChangeActive={(event, newHover) => {
                                setHover(newHover);
                                }}
                            />
                            <br />
                            <Box>
                                {labels[hover !== -1 ? hover : '0']}
                            </Box>
                        </div>
                        <TextareaAutosize 
                            aria-label="minimum height" 
                            rows={width === 'xs' ? 7 : 5} 
                            placeholder="Write Your Experience" 
                            className={classes.textarea}
                        />
                    {/* </Box> */}
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleClose} 
                        color="primary"
                    >
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default compose(
	withWidth())(GiveReview);