import uuidv4 from 'uuid/v4';
import {
    APPEND_NOTIFICATION,
    UPDATE_NOTIFICATION
} from '../../consts/Notifications';

export const appendNotification = data => (
    {
        type: APPEND_NOTIFICATION,
        ...data,
        id: uuidv4()
    }
);

export const updateNotification = id => (
    {
        type: UPDATE_NOTIFICATION,
        id
    }
);