import { 
	API_START, 
	API_END, 
	ACCESS_DENIED, 
	API_ERROR 
} from "../../consts/Api";

export const apiStart = (label, data) => ({
	type: API_START,
	payload: label,
	data,
});

export const apiEnd = (label, data) => ({
	type: API_END,
	payload: label,
	data
});

export const accessDenied = url => ({
	type: ACCESS_DENIED,
	payload: {
		url
	}
});

export const apiError = error => ({
	type: API_ERROR,
	error
});