import React, {
    useEffect
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import clsx from 'clsx';
import { 
    makeStyles
} from '@material-ui/core';
import {
    Typography,
    RichTextEditor,
    Link,
    Divider,
    Button
} from '../../commonComponents';
import Logo from '../logo/index';
import {
    readBySlug
} from '../../redux/actions/staticContent/Read';
import {
    getAuth,
    getStaticContent
} from '../../redux/reducers';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '80%',
                minHeight: '80vh',
                margin: '100px auto',
                [theme.breakpoints.down('sm')]: {
                    margin: '50px auto',
                },
            },
            typography: {
                whiteSpace: 'pre-line'
            },
            divider: {
                width: '50%',
            },
            hide: {
                display: 'none'
            }
        }
    )
);

const SLUG = 'about-us';

export default () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const admin = Boolean(useSelector(getAuth).admin);
    const data = JSON.parse(useSelector(state => getStaticContent(state, SLUG)).content[0]);

    useEffect(
        () => {
            dispatch(readBySlug(SLUG));
        },
        [
            dispatch
        ]
    );

    return (
        <div 
            className={classes.root} 
            align='center'
        >
            <Logo />
            <Typography 
                variant='h6' 
                title='About Gaachwala' 
                color='primary' 
            />
            <Divider 
                className={classes.divider}
            />
            <RichTextEditor 
                value={data}
                readOnly
            />
            <Link
                to={{
                    pathname: admin ? '/static-content-edit' : '/' + SLUG,
                    state: {
                        slug: SLUG
                    }
                }}
            >
                <Button 
                    className={
                        clsx({
                            [classes.hide]: !admin
                        })
                    }
                >
                    Edit
                </Button>
            </Link>
        </div>
    );
}