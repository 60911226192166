import {
	CREATE
} from '../../../consts/product/Create';
import {
	apiAction
} from '../Helper';

export const create = product => apiAction(
	{
		url: '/api/admin/product',
		method: 'POST',
		data: {
			name: product.name,
			bangla_name: product.banglaName,
			scientific_name: product.scientificName,
			benefits: product.BenefitsAndUses,
			planter_name: product.planterName,
			planter_size: product.planterSize,
			product_type: product.productType,
			planter_price: product.planterPrice,
			planter_material: product.planterMaterial,
			measurement_unit: product.measurementUnit,
			buy_price: parseFloat(product.buyPrice),
			sell_price: parseFloat(product.sellPrice),
			delivery_charge: parseFloat(product.deliveryCharge),
			description: product.description,
			light_requirement: product.lightRequirement,
			water_requirement: product.waterRequirement,
			image_url: product.image.split(','),
			category_id: product.categories,
			featured_products: product.checkFeature,
			is_tub: product.isTub,
			product_stock: product.isStockOut,
			curation: product.curation,
			related_products: []
		},
		label: CREATE
	}
);