import React, {
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useHistory
} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    CircularProgress,
} from '@material-ui/core';
import {
    Search
} from '@material-ui/icons';
import List from './List';
import { search } from '../../redux/actions/product/Search';
import { getProducts, getProductIsFetching } from '../../redux/reducers';

export default ({
    onClose = () => { },
    ...props
}) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [searchValue, setSearchValue] = useState('');

    const clickSearch = value => {
        if (value !== '') {
            onClose();
            setOpen(false);
            dispatch(search(value, history));
        }
    };

    const onChange = (_, value, reason) => {
        if (reason === 'select-option') setSearchValue(value.name);
        else setSearchValue(value);
    }

    const onKeyUp = e => {
        if ((e.key === 'Enter' || e.key === 'enter') && !e.shiftKey) {
            clickSearch(searchValue);
        }
    };

    const [open, setOpen] = useState(false);
    const options = useSelector(getProducts);
    const isFetching = useSelector(getProductIsFetching);
    const loading = open && isFetching;

    const onInputChange = (_, value) => setOpen(value !== '');

    console.log("search value ", searchValue)

    return (
        <Autocomplete
            {...props}
            id="product-search"
            onClose={() => setOpen(false)}
            value={searchValue}
            onChange={onChange}
            onInputChange={onInputChange}
            onKeyUp={onKeyUp}
            style={{
                width: '100%',
            }}
            open={open}
            freeSolo
            disableClearable
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name || option}
            options={options}
            loading={loading}
            noOptionsText="No products found."
            clearOnBlur
            clearOnEscape
            renderOption={
                option => <List option={option} />
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search Product"
                    variant="outlined"
                    placeholder="Search for products e.g. Adenium"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {
                                    loading
                                        ?
                                        <CircularProgress color="inherit" size={20} />
                                        :
                                        <Search color='primary' onClick={() => clickSearch(params.inputProps.value)} style={{ cursor: 'pointer', paddingRight: 4 }} />
                                }
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}