import {
    UPDATE
} from '../../../consts/category/Update';
import {
    apiAction,
    thunk
} from '../Helper';
import {
    fetchAll
} from './Fetch';

export const update = category => apiAction(
    {
        url: '/api/categoryupdate/' + category.id,
        method: 'POST',
        data: ({
            name: category.categoryName,
            description: category.description,
            category_id: category.parentCategory,
            id: category.id
        }),
        onSuccess: thunk(
            [
                data => ({
                    type: UPDATE,
                    data
                }),
                fetchAll
            ]
        ),
        label: UPDATE,
    }
);