import {
    SEARCH
} from '../../../consts/product/Search';
import { apiAction, thunk } from '../Helper';
import { update } from './Fetch';

export const search = (query, history) => apiAction({
    url: `/api/product/search/${query}`,
    onSuccess: thunk([
        data => ({
            type: SEARCH,
            data: data.all_product || []
        }),
        update,
        () => {
            if (history && history.push) history.push('/productsearch?q=' + query);

            return {
                type: '....'
            };
        }
    ]),
    label: SEARCH
});