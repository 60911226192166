import * as React from 'react';
import {
    Menu,
    makeStyles,
    Button,
    withWidth
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {
    usePopupState,
    bindTrigger,
    bindMenu,
} from 'material-ui-popup-state/hooks';

const useStyles = makeStyles((
    theme
) => (
        {
            div: {
                padding: 5
            },
            menu: {
                opacity: 0.8,
                '& .MuiMenu-paper': {
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.primary.main,
                },
                '& .MuiList-padding': {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
            icons: {
                margin: '0px 3px'
            },
            link: {
                textDecoration: 'none',
                color: theme.palette.primary.dark,
            },
            button: {
                textTransform: 'capitalize'
            }
        }
    )
);

const FilterMenus = ({
    Icon,
    menu,
    menus,
    x,
    width,
    onMenuClick,
    onMenuClickSort
}) => {
    const classes = useStyles();
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'demoMenu',
    });

    return (
        <div
            align='center'
            className={classes.div}
        >
            <Button
                variant='contained'
                size={
                    width === 'sm' || width === 'xs'
                        ?
                        'small'
                        :
                        'medium'
                }
                startIcon={
                    <Icon
                        fontSize={
                            width === 'sm' || width === 'xs'
                                ?
                                'small'
                                :
                                'default'
                        }
                    />
                }
                color="primary"
                {...bindTrigger(popupState)}
                className={classes.button}
            >
                {menu}
            </Button>
            <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transitionDuration={700}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                className={classes.menu}
                PaperProps={{
                    style: {
                        left: '50%',
                        transform: `translateX(${x}%) translateY(1%)`,
                    }
                }}
            >
                {
                    menus.map(
                        (
                            menu,
                            index
                        ) => (
                                <MenuItem
                                    key={index}
                                    dense
                                    onClick={
                                        onMenuClick
                                            ?
                                            () => onMenuClick(menu)
                                            :
                                            () => {
                                                onMenuClickSort(menu);
                                                popupState.close();
                                            }

                                    }
                                >
                                    {menu.name}
                                </MenuItem>
                            )
                    )
                }
            </Menu>
        </div>
    )
};

export default withWidth()(FilterMenus);