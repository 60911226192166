export default [
    {
        keyval: "firstName",
        name: "firstName",
        label: "First Name",
    },
    {
        keyval: "lastName",
        name: "lastName",
        label: "Last Name"
    },
    {
        keyval: "address",
        name: "Address",
        label: "Address line "
    },
    {
        keyval: "contactNumber",
        name: "contactNumber",
        label: "Contact Number"
    }
];

export const steps = [
	'Delivery Address', 
	'Review Your Order'
];