import React from 'react';
import { 
    useLocation 
} from 'react-router-dom';
import ProductDetailsContainer from './ProductDetails';

const AdminProductEntry = () => {
    const {
        state
    } = useLocation();

    return (
        <ProductDetailsContainer 
            id={
                state 
                || 
                ''
            }
        />
    )
}

export default AdminProductEntry;
