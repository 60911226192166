import {
    UPDATE_CART,
    UPDATE_PRODUCT
} from '../../../consts/cart/Update';
import {
    apiAction,
    thunk
} from '../Helper';
import {
    appendNotification
} from '../Notifications';
import {
    getCartIsFetching
} from '../../reducers';

export const updateCart = cart => apiAction(
    {
        url: '/api/cartupdate/' + cart.id,
        method: 'POST',
        data: {
            id: cart.id,
            discount: cart.discount,
            user_id: cart.userId,
            promo_code: cart.promoCode,
            status: cart.status,
            cart_products: cart.cartProducts,
            tot_amount: cart.totAmount,
            delivery_charge: cart.deliveryCharge
        },
        onSuccess: thunk([
            data => ({
                type: UPDATE_CART,
                data: {
                    ...data,
                    TDC: cart.TDC
                }
            }),
            () => appendNotification({
                message: 'Successfully updated cart.',
                variant: 'success'
            })
        ]),
        onFailure: () => appendNotification({
            message: 'Failed to update the cart, please try again.',
            variant: 'error'
        }),
        label: UPDATE_CART,
        race: getCartIsFetching
    }
);

export const updateProduct = (
    product,
    cart
) => apiAction(
    {
        url: '/api/cartproductupdate/' + product.id,
        method: 'POST',
        data: {
            id: product.id,
            product_id: product.productId,
            // promo: product.promo,
            price: product.price,
            quantity: product.quantity,
            tot_amount: product.totAmount,
            delivery_charge: product.deliveryCharge
        },
        onSuccess: thunk(
            [
                data => ({
                    type: UPDATE_PRODUCT,
                    data
                }),
                () => updateCart(cart)
            ]
        ),
        onFailure: () => appendNotification({
            message: 'Failed to update the cart, please try again.',
            variant: 'error'
        }),
        label: UPDATE_PRODUCT,
        race: getCartIsFetching
    }
)