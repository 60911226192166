import React from 'react';
import { 
    makeStyles,
    Radio,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    FormControl
} from '@material-ui/core';

const useStyles = makeStyles(
    theme => (
        {
            formControl: {
                flexDirection: 'row'
            },
        }
    )
);

export default ({
    onChange,
    value
}) => {
    const classes = useStyles();

    return (
        <FormControl 
            component="fieldset"   
        >
            <FormLabel 
                component="legend"
            >
                Gender
            </FormLabel>
            <RadioGroup 
                aria-label="gender" 
                name="gender1" 
                className={classes.formControl}
                value={value} 
                onChange={onChange}
            >
                <FormControlLabel 
                    value="female" 
                    control={
                        <Radio />
                    } 
                    label="Female" 
                />
                <FormControlLabel 
                    value="male" 
                    control={
                        <Radio />
                    } 
                    label="Male" 
                />
                <FormControlLabel 
                    value="other" 
                    control={
                        <Radio />
                    } 
                    label="Other" 
                />
            </RadioGroup>
        </FormControl>    
    );
}
