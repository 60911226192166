import facebook from '../../assets/fb2.png';
import twitter from '../../assets/tw2.png';
import instagram from '../../assets/ig2.png';
import youtube from '../../assets/youtube.png';

const logos = [
    {
        href: 'https://www.facebook.com/gaachwala/',
        name: 'facebook',
        logo: facebook,
    },
    {
        href: '',
        name: 'twitter',
        logo: twitter,
    },
    {
        href: 'https://instagram.com/gaachwalaltd',
        name: 'instagram',
        logo: instagram,
    },
    {
        href: '',
        name: 'youtube',
        logo: youtube,   
    },
]

export default logos;
