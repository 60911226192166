import React from 'react';
import Button from '@material-ui/core/Button';

export default ({
    variant,
    color,
    buttonText,
    Icon,
    RightIcon,
    ...props
}) => (
    <Button 
        variant={
            variant 
            ? 
            variant 
            : 
            "outlined"
        } 
        color={color}
        {...props}
    >
        {
            Icon
            ?
            <Icon />
            :
            ""
        } 
        {
            buttonText
            ?
            buttonText
            :
            ""
        }
        {
            RightIcon
            ?
            <RightIcon />
            :
            ""
        } 
        {
            props.children
        }
    </Button>
);