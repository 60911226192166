import React from 'react';
import { connect } from 'react-redux';
import Carousel from '@brainhubeu/react-carousel';
import {
    makeStyles,
    withWidth,
} from '@material-ui/core';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@material-ui/icons';
import {
    Typography,
    IconButton,
    Link
} from '../../commonComponents';
import {
    getFeaturedProducts
} from '../../redux/reducers';
import '@brainhubeu/react-carousel/lib/style.css';

const useStyles = makeStyles(
    theme => (
        {
            gridList: {
                height: 'auto',
                width: '100%',
                margin: '0 auto',
                //outline: 'solid',
            },
            container: {
                width: '100%',
                height: 'auto',
                margin: '0 auto',
                padding: theme.spacing(2),
                textDecoration: 'none',
                color: theme.palette.secondary.dark,
                [theme.breakpoints.down('xs')]: {
                    padding: 0
                },
            },
            grid3: {
                display: 'block',
                objectFit: 'cover',
                padding: 0,
                filter: 'brightness(100%)',
                '&:hover': {
                    filter: 'contrast(120%) ',
                    transform: 'scale(1.1)'
                },
                transition: 'transform 1s ease-in-out',
                marginBottom: 8,
            },
            carousel: {
                margin: '8px 8px 60px 8px',
                padding: '8px 8px 8px 8px',
                [theme.breakpoints.down('sm')]: {
                    padding: '2px 2px 2px 2px'
                },
            },
        }
    )
);

const col = {
    'xs': 1,
    'sm': 2,
    'md': 3,
    'lg': 4,
    'xl': 6,
};

const FeaturedProduct = ({
    featured,
    ...props
}) => {
    const classes = useStyles();
    const colN = col[props.width];

    return (
        <div className={classes.gridList}>
            <Typography
                title='You May Also Like'
                variant='h6'
                align='center'
                color='primary'
            />
            <br />
            <Carousel
                slidesPerPage={colN}
                arrowLeft={
                    <IconButton
                        Icon={KeyboardArrowLeft}
                        className={classes.carousel}
                        style={{
                            fontSize: '2em'
                        }}
                    />
                }
                arrowRight={
                    <IconButton
                        Icon={KeyboardArrowRight}
                        className={classes.carousel}
                        style={{
                            fontSize: '2em'
                        }}
                    />
                }
                infinite
                addArrowClickHandler
            >
                {
                    featured.map(
                        (tile, index) => (
                            <Link
                                to={`/productdetails/${tile.id}`}
                                key={index}
                                title={tile.name}
                                className={classes.container}
                            >
                                <div align='center'>
                                    <img
                                        src={tile.image}
                                        className={classes.grid3}
                                        alt="featured"
                                        width='100%'
                                        height='250'
                                    />
                                </div>
                                <Typography
                                    gutterBottom
                                    align='center'
                                    title={tile.name}
                                />
                                <Typography
                                    gutterBottom
                                    align='center'
                                    title={`৳ ${tile.sellPrice}`}
                                />
                            </Link>
                        )
                    )
                }
            </Carousel>
        </div>
    );
}

const mapStateToProps = state => ({
    featured: getFeaturedProducts(state) || {},
});

export default withWidth()(connect(mapStateToProps, null)(FeaturedProduct)); 