import {
    FETCH_ALL,
    FETCH_DELETED_ORDER,
    FETCH_ORDER_COUNT,
    FETCH_NEW_ORDER,
    FETCH
} from '../../../consts/order/Fetch';
import {
    apiAction,
    thunk
} from '../Helper';
import {
    fetchCart
} from '../cart/Fetch';
import {
    checkoutCart
} from '../cart';

export const fetchAll = () => apiAction(
    {
        url: '/api/order/info/all',
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH_ALL,
                    data
                }),
                () => checkoutCart(true),
                data => dispatch => Array
                    .isArray(data.all_order)
                    &&
                    data.all_order
                        .forEach(async o => await dispatch(
                            fetchCart(o.cart_id)
                        ))
            ]
        ),
        label: FETCH_ALL
    }
);

export const fetchDeletedOrder = () => apiAction(
    {
        url: '/api/order/info/dropped',
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH_DELETED_ORDER,
                    data
                }),
                () => checkoutCart(true),
                data => dispatch => Array
                    .isArray(data.DroppedOrder)
                    &&
                    data.DroppedOrder
                        .forEach(async o => await dispatch(
                            fetchCart(o.cart_id)
                        ))
            ]
        ),
        label: FETCH_ALL
    }
);

export const fetchOrderByID = ({ id }) => apiAction(
    {
        url: '/api/order/' + id,
        onSuccess: data => ({
            type: FETCH,
            data
        }),
        label: FETCH
    }
)

export const fetchOrderCount = () => apiAction(
    {
        url: '/api/order/info/count',
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH_ORDER_COUNT,
                    data
                }),
            ]
        ),
        label: FETCH_ORDER_COUNT
    }
);

export const fetchNewOrder = () => apiAction(
    {
        url: '/api/order/info/new',
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH_NEW_ORDER,
                    data
                }),
                data => thunk(
                    (data.new_order || []).map(u =>
                        fetchCart(u.cart_id)
                    ))()
            ]
        ),
        label: FETCH_NEW_ORDER
    }
);