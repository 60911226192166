import React, {
    Fragment,
    useState
} from 'react';
import {
    Paper,
    Popper,
    ClickAwayListener,
    MenuList,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    withStyles,
    Fade
} from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import {
    Link
} from '../../../commonComponents';

const styles = theme => (
    {
        popper: {
            zIndex: 1230,
            color: theme.palette.secondary.dark
        },
        menulist: {
            padding: 0,
        },
        listitem: {
            padding: 10,
        },
        arrow: {
            color: theme.palette.secondary.dark
        },
        list: {
            transition: 'all .6s ease-out',
            '&:hover': {
                transform: 'translate(8px, 0px)',
            }
        },
        paper: {
            backgroundColor: theme.palette.secondary.main,
            minWidth: 200
        }
    }
);

let menuItemRefs = {};

const Menu = ({
    anchorEl,
    open,
    data,
    placement,
    classes,
    ...props
}) => {
    const [
        state, 
        setState
    ] = useState({
        open: false,
        selected: null,
    });
    
    if(!data) {
        return '';
    }
    
    const handleChange = event => {
        if(!event.persist) {
            setState({
                open: false,
                selected: null,
            });
            return;
        }
        
        event.persist();
        
        if(!data || !Array.isArray(data)) {
            setState({
                open: false,
                selected: null,
            });
            return;
        }
        
        const selected = data.filter(item => menuItemRefs[item.id].contains(event.target));
        if(selected) {
            setState({
                open: true,
                selected: selected[0],
            });
            return;
        }

        setState({
            open: false,
            selected: null,
        });
    };

    const handleClose = () => {
        if(props.handleClose) props.handleClose();
        setState(() => ({
            open: false,
            selected: null,
        }));
    };

    const updateRef = (node, item) => menuItemRefs = {
        ...menuItemRefs,
        [item]: node,
    };

    return (
        <Fragment>
            <Popper 
                open={open} 
                anchorEl={anchorEl} 
                transition 
                placement={placement}
                className={classes.popper}
            >
                {
                    ({ 
                        TransitionProps, 
                        placement 
                    }) => (
                        <Fade
                            {...TransitionProps}
                            timeout={700}
                            id="menu-list-grow"
                            style={{
                                transformOrigin: placement === 'bottom'
                                ? 
                                'center top' 
                                : 
                                'center bottom',
                            }}
                        >
                            <Paper
                                className={classes.paper}
                            >
                                <ClickAwayListener 
                                    mouseEvent="onClick" 
                                    onClickAway={handleClose}
                                >
                                    <MenuList
                                        className={classes.menulist}
                                        dense
                                    >
                                        {
                                            data.map(
                                                (item, index) => (
                                                    <Link 
                                                        key={index} 
                                                        to={{
                                                            pathname: `/${item.name}/productlist`,
                                                            state: item.id
                                                        }}
                                                    >    
                                                        <ListItem 
                                                            button
                                                            onClick={handleClose} 
                                                            key={item.id}
                                                            id={item.id}
                                                            color="inherit"
                                                            onMouseEnter={handleChange}
                                                            buttonRef={
                                                                node => updateRef(node, item.id)
                                                            }
                                                            disableRipple
                                                            className={classes.listitem}
                                                        >
                                                            <ListItemText 
                                                                className={classes.list}
                                                            >
                                                                {item.name}
                                                            </ListItemText>
                                                            {
                                                                Array.isArray(item.children) 
                                                                && 
                                                                item.children.length > 0
                                                                ? 
                                                                <ListItemSecondaryAction>
                                                                    <ArrowRight 
                                                                        className={classes.arrow}
                                                                    />
                                                                </ListItemSecondaryAction>
                                                                : 
                                                                null
                                                            }
                                                        </ListItem>
                                                    </Link>   
                                                )
                                            )
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Fade>
                    )
                }
            </Popper>
            {
                state.selected 
                && 
                menuItemRefs[state.selected.id] 
                && 
                state.selected.id 
                && 
                Array.isArray(state.selected.children) 
                && 
                state.selected.children.length > 0
                ? 
                <MenuWrapped
                    key={state.selected.name}
                    anchorEl={menuItemRefs[state.selected.id]}
                    open={state.open}
                    handleClose={handleClose}
                    data={state.selected.children}
                    placement="right-start"
                />
                : 
                null
            }
        </Fragment>
    );
};

const MenuWrapped = withStyles(styles)(Menu);

export default MenuWrapped;