import {
    ACTIVATE
} from '../../../../consts/promo/User';
import {
    apiAction,
    thunk
} from '../../Helper';
import {
    appendNotification
} from '../../Notifications';

export const activate = ({
    userName,
    status = true,
    userLimit,
    promo
}) => apiAction({
    url: '/api/promoprocess',
    method: 'POST',
    data: {
        user_name: userName,
        status,
        user_limit: userLimit,
        promo
    },
    onSuccess: thunk([
        data => ({
            type: ACTIVATE,
            data: {
                id: data.id,
                status: data.status,
                userLimit: data.user_limit,
                userName: data.user_name,
                promo: {
                    createdAt: data.promo.created_at,
                    discount: data.promo.discount,
                    validity: data.promo.end_date,
                    id: data.promo.id,
                    limit: data.promo.limit,
                    maxDiscount: data.promo.max_discount,
                    minPurchase: data.promo.min_purchase,
                    name: data.promo.name,
                    code: data.promo.promo_code,
                }
            }
        }),
        () => appendNotification({
            message: 'Promo activated.',
            variant: 'success'
        })
    ]),
    label: ACTIVATE
});