import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import Icon from '../../assets/icon1.png';
import RecommendPaper from './RecommendPaper';
import Reviews from './Reviews';
import GiveReview from './GiveReview';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        margin: '120px auto 0 auto',
        width: 'auto',
        minHeight: '90vh',
        [theme.breakpoints.down('sm')]: {
            margin: '60px auto 0 auto'
        },
    },
    image: {
        width: 128,
        height: 128,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: 80,
            height: 80,
        },
    },
    divider: {
        width: '70%',
        margin: '8px auto'
    },
    item1: {
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
            textAlign: 'center'
        },
    }
}));

export default function ComplexGrid() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <Paper className={classes.root}>
            <Grid 
                container 
                spacing={2}
            >
                <Grid 
                    item 
                    xs={12} 
                    sm={3}
                    className={classes.item}
                >
                    <div align='center'>
                        <img 
                            className={classes.image} 
                            alt="complex" 
                            src={Icon} 
                        />
                    </div>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sm={5} 
                    className={classes.item1}
                >
                    <Typography 
                        gutterBottom 
                        variant="h6"
                        align='inherit'
                    >
                        Gaachwala Reviews
                    </Typography>
                    <Rating 
                        value='4.5' 
                        precision={0.5}
                        readOnly 
                    />
                    <Typography
                        gutterBottom
                        align='inherit'
                    >
                        4.5 Rating 27 Reviews
                    </Typography>
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        onClick={handleClickOpen}
                    >
                        Give A Review
                    </Button>
                    <GiveReview open={open} setOpen={setOpen}/>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sm={4}
                >
                    <RecommendPaper/>
                </Grid>
                <Divider className={classes.divider} />
                <Grid 
                    item
                    xs={12}
                    sm={12}
                >
                    <Reviews />
                </Grid>
            </Grid>
        </Paper>
    );
}