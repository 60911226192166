import {
    PROFILE, 
    ID
} from '../../../consts/user/Profile';
import {
    apiAction, 
    thunk
} from '../Helper';
import { 
    createCart 
} from '../cart/Create';
import {
    readAll
} from '../promo/user/Read';
import {
    fetchAllUser
} from './Fetch';

export const fetchProfile = ({
    user_id
}) => apiAction(
    {
        url: '/api/user/' + user_id,
        label: PROFILE,
        onSuccess: thunk(
            [
                data => ({
                    type: PROFILE,
                    data,
                }),
                () => createCart(user_id),
                data => readAll(data.user_name),
                data => data.isadmin ? fetchAllUser() : {type: '....'}
            ]
        ),
    }
);

export const fetchId = () => apiAction(
    {
        url: '/api/user/info/getid',
        label: ID,
        onSuccess: thunk(
            [
                data => ({
                    type: ID,
                    data,
                }),
                fetchProfile
            ]
        ),
    }
);
