import React, { 
    useState 
} from 'react';
import { 
    connect 
} from 'react-redux';
import { 
    withStyles 
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Menu from './Menu';
import {
    Button,
    Link
} from '../../../commonComponents';
import {
    useWindowScroll
} from '../../../hooks';
import { 
    getMenu 
} from '../../../redux/reducers';
// import menus from '../smaller/menus';

const styles = theme => (
    {
        button: {
            color: theme.palette.primary.main,
            transition: 'all .6s ease-out',
            '&:hover': {
                transform: 'translate(0, -5px)',
            }
        },
        div: {
            display: 'inline', 
            verticalAlign: 'middle',
        },
        root: {
            width: '100%'
        }
    }
);

let buttonRefs = {};

const mapStateToProps = state => ({
    data: getMenu(state)
});

export default connect(mapStateToProps)(
    withStyles(styles)(
        ({
            data: {
                data
            },
            classes,
            ...props
        }) => {

            const [
                state, 
                setState
            ] = useState({
                anchorEl: null,
                open: false,
                selected: null
            });

            const updateRef = (node, item) => buttonRefs = {
                ...buttonRefs,
                [item]: node,
            };

            const handleChange = event => {
                if(!event.persist) {
                    setState({
                        ...state,
                        open: false,
                        selected: null,
                    });
                    return;
                }

                event.persist();

                if(!Array.isArray(data)) {
                    setState({
                        ...state,
                        open: false,
                        selected: null,
                    });
                    return;
                }
                
                const selected = data.filter(
                    item => buttonRefs[item.id].contains(event.target)
                );
                if(selected) {
                    setState({
                        ...state,
                        open: true,
                        selected: selected[0],
                    });
                    return;
                }

                setState({
                    ...state,
                    open: false,
                    selected: null,
                });
            };

            const handleClose = () => setState(() => ({
                ...state,
                open: false,
                selected: null,
            }));

            useWindowScroll(handleClose);

            return (
                <div 
                    // {...props} 
                    align='right' 
                    className={classes.root}
                >
                    {
                        data.map(
                            (item, index) => (
                                <div 
                                    key={index} 
                                    className={classes.div}
                                >
                                    <Link  
                                        to={{
                                            pathname: `/${item.name}/productlist`,
                                            state: item.id
                                        }} 
                                    >
                                        <Button 
                                            size='small' 
                                            color="inherit" 
                                            key={item.id} 
                                            variant='text'
                                            id={item.id}
                                            onClick={handleClose}
                                            onMouseEnter={handleChange}
                                            buttonRef={
                                                node => updateRef(node, item.id)
                                            }
                                            //disableRipple
                                            className={classes.button}
                                            buttonText={item.name}
                                            RightIcon={ 
                                                Array.isArray(item.children) 
                                                && 
                                                item.children.length > 0 
                                                ? 
                                                ArrowDropDown 
                                                : 
                                                null
                                            }
                                        />      
                                    </Link>
                                </div>
                            )
                        )
                    }

                    {
                        state.selected 
                        && 
                        buttonRefs[state.selected.id] 
                        && 
                        Array.isArray(state.selected.children) 
                        && state.selected.children.length > 0
                        ? 
                        <Menu 
                            key={state.selected.id}
                            anchorEl={buttonRefs[state.selected.id]}
                            open={state.open}
                            handleClose={handleClose}
                            data={state.selected.children}
                            placement="bottom-start"
                        />
                        : 
                        null
                    }
                </div>
            );
        }
    )
);