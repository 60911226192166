import {
    PROFILE, 
    ID,
} from '../../../consts/user/Profile';
import {
    UPDATE_PROFILE
} from '../../../consts/user/Update';

export default (
    state = {
        profile: {},
        isFetching: false,
        id: '',
        error: null,
    }, 
    action
) => {
    switch(action.type) {
        case PROFILE:
            return {
                ...state,
                profile: action.data
            };
        case ID:
            return {
                ...state,
                id: action.data.user_id
            };
        case UPDATE_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    first_name: action.data.first_name,
                    last_name: action.data.last_name,
                    phone: action.data.phone,
                    address: action.data.address,
                }
            }
        default:
            return state;
    }
};

export const getProfile = state => (state.profile && {
    ...state.profile,
    address: Array.isArray(state.profile.address) ? state.profile.address.join(', ') : ''
}) || {};

export const getUserId = state => state.id || null;

export const getUserName = state => (state.profile && state.profile.user_name) || null;

export const getEmail = state => (state.profile && state.profile.email) || '';