export const entryList = [
    {
        text: 'Category Entry',
        href: '/admincategoryentry',
    },
    {
        text: 'Product Entry',
        href: '/adminproductentry',
    },
    {
        text: 'Promo Entry',
        href: '/adminpromoentry',
    },
    {
        text: 'Edit Banner',
        href: '/edit-banner'
    },
    {
        text: 'Edit Slider',
        href: '/edit-slider-images'
    },
    {
        text: 'Edit Slider Links',
        href: '/edit-slider-links'
    },
    {
        text: 'Static Pages',
        href: '/static-contents'
    }
];

export const lists = [
    {
        text: 'Profile',
        href: '/adminprofile',
    },
    {
        text: 'Category List',
        href: '/admincategorylist',
    },
    {
        text: 'Product List',
        href: '/adminproductlist',
    },
    {
        text: 'Promo List',
        href: '/adminpromolist',
    },
    {
        text: 'User List',
        href: '/adminuserlist',
    },
    {
        text: 'Inventory',
        href: '/inventory'
    },
    {
        text: 'Deleted Orders',
        href: '/deleted-orders'
    },
    {
        text: 'Purchase History',
        href: '/adminuserpurchasehistory',
    },
];

export const counts = [
    {
        label: 'Products'
    },
    {
        label: 'Orders'
    },
    {
        label: 'Users'
    }
]