import React from 'react';
import {
	connect
} from 'react-redux';
import PropTypes from 'prop-types';
import {
	pulse
} from 'react-animations';
import styled, {
	keyframes
} from 'styled-components';
import clsx from 'clsx';
import {
	withStyles,
	Grid,
	Paper,
} from '@material-ui/core';
import EmptyCartModal from './EmptyCartModal';
import {
	Link,
	Button,
	Typography,
	Divider
} from '../../commonComponents';
import * as rootReducer from '../../redux/reducers';
import {
	padLeft
} from '../../utils/strings';
import image from '../../assets/icon1.png';

const Pulse = styled.div`animation: 2s ${keyframes`${pulse}`} 1`;

const styles = theme => (
	{
		root: {
			//width: '450px',
			// paddingTop: theme.spacing(9),
			margin: '0 auto',
			height: 'auto',
			backgroundColor: theme.palette.secondary.light,
			color: theme.palette.info.main,
			width: '100%',
			//zIndex: '2000'
		},
		button: {
			margin: '0 auto',
		},
		text: {
			paddingRight: theme.spacing(2),
			whiteSpace: 'pre'
			// paddingLeft: theme.spacing(5),
		},
		divider: {
			width: '80%',
			margin: '10px auto'
			//marignBottom: 15
		},
		padding: {
			padding: 5,
			[theme.breakpoints.down('sm')]: {
				padding: '5px 2px'
			},
		},
		pulse: {
			padding: 10
		},
		link: {
			padding: 10
		},
		hide: {
			display: 'none'
		}
	}
);

const EnhancedTable = ({
	classes,
	cart: {
		data,
		discount = 0,
		totAmount,
		deliveryCharge
	},
	...props
}) => {
	if (totAmount === 0) return <EmptyCartModal />
	else return (
		<Paper
			elevation={1}
			className={classes.root}
		>
			{
				data.map(
					(n, index) => (
						<Pulse
							key={index}
							className={classes.pulse}
						>
							<Grid
								title={n.name}
								container
							>
								<Grid
									item
								>
									<img
										className={classes.img}
										alt="complex"
										width='50'
										height='50'
										src={
											n.image
											||
											image
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm
									container
								>
									<Grid
										item
										xs
										container
										direction="column"
									// spacing={1}
									>
										<Grid
											item
											xs
											className={classes.padding}
										>
											<Typography
												variant="body1"
												color='primary'
												title={n.name}
											/>
											{/* <Typography
												variant="body2"
												color='inherit'
												title={`Delivery Charge `}
											/> */}
											{/* <Typography 
												variant="body2" 
											>
												if any pot has to show that will be here
											</Typography>
											<Typography 
												variant="body2" 
												color="textSecondary"
											>
												size will go here(if any)
											</Typography> */}
										</Grid>
									</Grid>
									<Grid
										item
										className={classes.padding}
									>
										<Typography
											variant="body1"
											align='right'
											color='primary'
											title={`৳ ${n.price * n.quantity}`}
										/>
										{/* <Typography
											variant="body2"
											align='right'
											color='inherit'
											title={`৳ ${n.deliveryCharge * n.quantity}`}
										/> */}
									</Grid>
								</Grid>
								<Divider className={classes.divider} />

							</Grid>
						</Pulse>
					)
				)
			}
			<Typography
				variant="subtitle1"
				align='right'
				color='primary'
				className={classes.text}
				title={`Subtotal (+): ${padLeft('৳ ' + totAmount)}`}
			/>
			<Typography
				variant="subtitle1"
				align='right'
				color='primary'
				className={
					clsx(
						classes.text,
						{
							[classes.hide]: discount === 0
						}
					)
				}
				title={`Discount (-): ${padLeft('৳ ' + discount)}`}
			/>
			<Typography
				variant="subtitle1"
				align='right'
				color='primary'
				className={classes.text}
				title={`Delivery Fee (+): ${padLeft('৳ ' + deliveryCharge)}`}
			/>
			<Typography
				variant="subtitle1"
				align='right'
				color='primary'
				className={classes.text}
				title={`Total: ${padLeft('৳ ' + (totAmount + deliveryCharge - discount))}`}
			/>
			<div
				align='center'
				className={classes.link}
			>
				<Link
					to='/cart'
					title="Click to See The Cart"
				>
					<Button
						color="primary"
						size="medium"
						// fullWidth
						className={classes.button}
						buttonText='Go To Cart'
					/>
				</Link>
			</div>
		</Paper>
	);
};

EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	cart: rootReducer.getCartTable(state),
});

export default connect(mapStateToProps)(withStyles(styles)(EnhancedTable));