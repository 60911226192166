import React from 'react';
import {
    Grid,
    withStyles
} from '@material-ui/core';
import { 
    useTheme 
} from '@material-ui/styles';
import {
    InsertEmoticon,
    // CheckBox, //this two will need later
    // LocationOn
 } from '@material-ui/icons';
 import TextFields from './Textfields';
 import { 
    contact 
} from '../footer/Data';
import Logo from '../logo/index';
import {
    Typography,
    Divider,
} from '../../commonComponents';

const styles = theme => (
    {
        container: {
            margin: '80px auto 0 auto',
            padding: 0,
            //minHeight: '30vh',
            height: 'auto',
            width: '100%',
            color: theme.palette.secondary.dark,
            [theme.breakpoints.down('sm')]: {
                margin: '50px auto',
            },
        },
        item: {
            margin: '0 auto'
        }
    }
);

const ContactUs = ({
    classes,
}) => {
    const theme = useTheme();

    return (
        <Grid
            container
            direction="row"
            alignItems='center'
            justify='center'
            spacing={3}
            className={classes.container}
        >
            <Grid
                item
                xs={12}
            >
                <Logo />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
            >
                <Grid
                    container
                    wrap='nowrap'
                    spacing={1}
                >
                    <Grid
                        item
                        xs
                    >
                        <Typography
                            title='Contact Us'
                            align='center'
                            variant='h5'
                        />
                        <Typography
                            color='inherit'
                            gutterBottom
                            align='center'
                            title='You Can Reach Us Whenever You Need Us'
                            Icon={InsertEmoticon}
                            //counld not pass style as classname, that's why passed this way
                            iconStyle={{
                                verticalAlign: 'middle',
                                height: '20px',
                                paddingBottom: 8,
                            }}
                        />
                        {/* <InsertEmoticon className={classes.insertemoticon} /> */}
                    </Grid>
                </Grid>
                <Divider />
            </Grid>
            <Grid
                item
                sm={12}
                md={6}
            >
                {
                    contact.slice(1, 3).map(
                        (i, index) => (
                            <Grid
                                key={index}
                                container
                                wrap="nowrap"
                                // spacing={2}
                                title={i.title}
                                //style={{paddingTop: 20}}
                            >
                                <Grid
                                    item
                                    xs
                                >
                                    <Typography
                                        boldText={i.slogan}
                                        title={i.text}
                                        IconLeft={i.Icon}
                                        iconStyle={{
                                            verticalAlign: 'middle',
                                            margin: theme.spacing(2),
                                            // marginTop: 0
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )
                    )
                }
                {/* <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                >
                    <Grid item>
                        <LocationOn className={classes.locationon}/>
                    </Grid>
                    <Grid
                        item
                        xs
                    >
                        <Typography
                            title="Feel Free To Visit Us"
                            gutterBottom
                        >
                            <b>
                                We Are At
                            </b> 274, Bara Moghbazar, Dhaka-1217
                        </Typography>
                    </Grid>
                </Grid> */}
                {/* <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                >
                    <Grid item>
                        <CheckBox className={classes.locationon}/>
                    </Grid>
                    <Grid
                        item
                        xs
                    >
                        <Typography
                            title="See You Soon!"
                            gutterBottom
                        >
                            <b>
                                We Are Available On
                            </b> Sundays to Thursdays from 9.00am to 6.00pm Except Public Holidays
                        </Typography>
                    </Grid>
                </Grid> */}

            </Grid>
            <Grid
                item
                sm={12}
                md={6}
                className={classes.item}
            >
                <TextFields />
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(ContactUs);