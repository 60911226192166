import React, {
    useEffect,
    useState
} from 'react';
import {
    Link
} from 'react-router-dom';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useDebounce
} from 'use-debounce';
import {
    Carousel
} from 'react-responsive-carousel';
import {
    withStyles
} from '@material-ui/core';
import {
    Typography
} from '../../commonComponents';
import {
    readBySlug
} from '../../redux/actions/staticContent/Read';
import {
    getStaticContent
} from '../../redux/reducers';
import Icon from '../../assets/icon1.png';
import "./Style.css";

const styles = theme => (
    {
        root: {
            display: 'flex',
            marginTop: 80,
            position: 'relative',
            width: '100%',
            height: 'auto',
            minHeight: '50vh',
            overflowX: 'hidden',
            backgroundColor: '#c8e6c9',
            [theme.breakpoints.down('md')]: {
                marginTop: 50
            },
        },
        slide: {
            position: 'relative',
            width: '100% !important', // Overrides inline-style
            height: '100vh',
            '&:hover': {
                zIndex: 1,
                '& $imageTitle': {
                    border: '4px solid currentColor',
                },
            },
            [theme.breakpoints.down('sm')]: {
                height: '65vh'
            },
        },
        imageButton: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            width: 'auto',
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.common.white,
            opacity: 0.4
        },
        imageSrc: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            filter: 'contrast(1.08)',
            // transition: 'filter 1s ease-in-out',
        },
        imageBackdrop: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            // backgroundColor: theme.palette.secondary.main,
        },
        imageTitle: {
            position: 'relative',
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.dark,
            width: 'auto',
            opacity: 0.4,
        },
        autoswipe: {
            width: '100%',
            '.carousel .control-dots .dot': {
                backgroundColor: 'blue',
                boxShadow: 'none'
            },
            '& .control-arrow': {
                position: 'absolute',
                zIndex: 100
            }
        },
        links: {
            textDecoration: 'none'
        }
    }
);

const SLUG = 'banner';
const SLIDERSLUG = 'slider-images';
const SLIDERLINKS = 'slider-links';

const PictureSlideShow = ({
    classes,
}) => {
    const dispatch = useDispatch();

    const [count, setCount] = useState(0);

    const [dcount] = useDebounce(count, 200);

    useEffect(
        () => {
            dispatch(readBySlug(SLUG));
            dispatch(readBySlug(SLIDERSLUG));
            dispatch(readBySlug(SLIDERLINKS));
        },
        [
            dispatch
        ]
    );

    const bannerTexts = useSelector(state => getStaticContent(state, SLUG)).content;
    const sliderImages = useSelector(state => getStaticContent(state, SLIDERSLUG)).content;
    const sliderLinks = useSelector(state => getStaticContent(state, SLIDERLINKS)).content;

    const getBanner = () => bannerTexts.length === 0 ? 'Loading ...' : bannerTexts[dcount % bannerTexts.length];
    const getSliders = () => sliderImages.length === 0 ? Icon : sliderImages[dcount % sliderImages.length];
    const getLinks = () => sliderLinks.length === 0 ? 'Loading ...' : sliderLinks[dcount % sliderLinks.length];

    return (
        <div
            className={classes.root}
        >
            <Carousel
                // index={index}
                infiniteLoop
                autoPlay
                swipeable={true}
                showThumbs={false}
                showStatus={false}
                stopOnHover={false}
                onChange={() => setCount(count + 1)}
                className={classes.autoswipe}
            >
                {
                    sliderImages.map(
                        (g, index) => (
                            <div
                                key={index}
                                className={classes.slide}
                            >
                                <img
                                    src={getSliders()}
                                    alt={'slider ' + index}
                                    className={classes.imageSrc}
                                />
                            </div>
                        )
                    )
                }
            </Carousel>
            {
                bannerTexts.map(
                    (i, index) => (
                        <Link
                            to={getLinks()}
                            key={index}
                            className={classes.links}
                        >
                            <span
                                className={classes.imageBackdrop}
                            />
                            <span
                                className={classes.imageButton}
                            >
                                <Typography
                                    //component="span"
                                    variant="h6"
                                    color="inherit"
                                    align='center'
                                    className={classes.imageTitle}
                                    title={getBanner()}
                                />
                            </span>
                        </Link>
                    )
                )
            }
            {/* <Pagination
                dots={Object.keys(banner).length}
                index={index}
                onChangeIndex={this.handleChangeIndex}
            /> */}
        </div>
    );
}

export default withStyles(styles)(PictureSlideShow);
