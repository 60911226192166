import React from 'react';
import PropTypes from 'prop-types';
import { 
    withStyles 
} from '@material-ui/core';
import Icon from '../../assets/icon1.png';

const styles = () => (
    {
        image: {
            margin: '10px auto'
        },
    }
);

const Logo = ({
    classes,
}) => (
    <div
        align='center'
    >
        <img 
            alt="gaachwala logo" 
            src={Icon} 
            height="70"
            width="60"
            className={classes.image}
        />
    </div>
);

Logo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Logo);