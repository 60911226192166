export const passwordFields = [
    {
        label: 'Current Password',
        placeholder: 'Current Password',
        key: 'currentPassword'
    },
    {
        label: 'New Password',
        placeholder: 'New Password',
        key: 'newPassword'
    },
    {
        label: 'Confirm New Password',
        placeholder: 'Confirm New Password',
        key: 'confirmNewPassword'
    }
];

export const updatePaswordEmptyState = {
    currentPassword: {
        value: '',
        valid: true
    },
    newPassword: {
        value: '',
        valid: true
    },
    confirmNewPassword: {
        value: '',
        valid: true
    }
};

export const profileInformationFields = [
    {
        key: 'first_name',
        label: 'First Name',
        helperText: 'Name should only contain letters, spaces, period, hyphen, or apostrophe'
    },
    {
        key: 'last_name',
        label: 'Last Name',
        helperText: 'Name should only contain letters, spaces, period, hyphen, or apostrophe'
    },
    {
        key: 'phone',
        label: 'Phone Number',
        helperText: 'Phone number should only contain digits'
    },
    {
        key: 'address',
        label: 'Address',
        helperText: ''
    }
];