import React from 'react';
import Typography from '@material-ui/core/Typography';

export default ({
	classes,
	variant, 
	title,
	boldText,
	IconLeft,
	Icon,
	iconStyle,
	color,
	...props
}) => (
	<Typography
		variant={variant}
		color={color || "primary"}
		gutterBottom 
		{...props}
	>
		{
			IconLeft
			?
			<IconLeft style={iconStyle} />
			:
			""
		}
		{
			boldText
			?
			<b>{boldText}</b>
			:
			""
		}
		{
			title
			?
			title
			:
			""	
		}
		{
			Icon
			?
			<Icon style={iconStyle} />
			:
			""
		}
		{
			props.children
		}
	</Typography>    
);