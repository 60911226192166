import _ from 'lodash';
import {
    ACTIVATE,
    READ,
    UPDATE,
    DISABLE
} from '../../../consts/promo/User';

export default (state = {}, action) => {
    switch(action.type) {
        case READ:
            return {
                ...state,
                [action.data.id]: action.data
            };
        case DISABLE:
            return _.reduce(state, (prev, cur, key) => ({
                ...prev,
                [key]: {
                    ...cur,
                    status: false
                }
            }), {});
        case UPDATE:
            return {
                ...state,
                [action.data.id]: {
                    ...state[action.data.id],
                    status: action.data.status,
                    userLimit: action.data.userLimit
                }
            };
        case ACTIVATE:
            return {
                ...state,
                [action.data.id]: {
                    ...action.data
                }
            };
        default:
            return state;
    }
};

export const get = (state, key) => state[key] || Object.keys(state).find(k => state[k].promo.code === key) || {};

export const getList = state => Object.keys(state).map(k => state[k]);

export const getActivePromo = state => getList(state).find(({status}) => status);