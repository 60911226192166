import {
    combineReducers
} from 'redux';
import list, * as fromList from './List';
import user, * as fromUser from './User';

export default combineReducers({
    list,
    user
});

export const getList = state => fromList.getList(state.list);

export const getPromoByID = (state, id) => fromList.get(state.list, id);

export const getPromoByCode = (state, code) => fromList.getByCode(state.list, code);

export const getUserPromo = (state, key) => fromUser.get(state.user, key);

export const getUserPromoList = state => fromUser.getList(state.user);

export const getActivePromo = state => fromUser.getActivePromo(state.user);