import {
    CREATE
} from '../../../consts/inventory/Create';
import {
    apiAction
} from '../Helper';
import { fetchAll } from './Fetch';

export const create = ({
    id,
    Quantity
}) => apiAction(
    {
        url: '/api/admin/inventory',
        method: 'POST',
        data: {
            productID: id,
            count: Quantity,
        },
        onSuccess: fetchAll,
        label: CREATE
    }
);