import React from 'react';
import {
	Box,
	withStyles
} from '@material-ui/core';
import {
	Typography
} from '../../commonComponents';

const styles = theme => (
	{
		root: {
			padding: 8 * 3, 
			color: theme.palette.primary.dark,
			minHeight: '150px',
		}
	}
);

const TabContainer = ({
	children,
	value,
	w,
    l,
    classes,
}) => (
	<Typography 
		component="div" 
		className={classes.root}
	>
		{
			value === 1 
			&& 
			<Box>
				<b>
					{`Light Requirement: ` }
				</b>
				{l} 
				<br /> 
				<b>
					{`Water Requirement: `}
				</b>
				{w}
			</Box>
		}
		{
			(value === 0 || value === 2) 
			&& 
			<Box>
				{children}
			</Box>
		}
	</Typography>
);

export default withStyles(styles)(TabContainer);