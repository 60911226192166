import React from 'react';
import {
    withStyles,
    GridListTile,
    GridListTileBar,
} from '@material-ui/core';
import {
    Typography,
    Link
} from '../../commonComponents';
import AddToCartButton from '../addToCartButton';

const styles = theme => (
    {
        root: {
            display: 'flex',
            //flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            //alignContents: 'flex-start',
            //padding: 5,
            height: '100%',
            width: '100%',
            margin: '0 auto',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
            [theme.breakpoints.down('sm')]: {
                padding: 5,
            },
        },
        icon: {
            color: theme.palette.primary.main,
        },
        titleBar: {
            background: 'transparent',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 50,
            },
        },
        img: {
            display: 'block',
            objectFit: 'cover',
            //opacity: 0.4,
            filter: 'brightness(100%)',
            verticalAlign: 'middle',
            '&:hover': {
                filter: 'contrast(120%) ',
                transform: 'scale(1.1)'
            },
            transition: 'transform .5s ease-in-out',
            width: '100%',
            margin: '0 auto 8px auto',
            [theme.breakpoints.down('sm')]: {
                width: '80%',
            },
        },
        link: {
            verticalAlign: 'middle',
        }
    }
);

const Product = ({
    id,
    image,
    name,
    banglaName,
    price,
    type,
    deliveryCharge,
    addToCart,
    classes,
    isFetching,
    stockOut,
    ...props
}) => (
        <GridListTile
            {...props}
            key={id}
            className={classes.root}
        >
            <Link
                to={`/productdetails/${id}`}
                className={classes.link}
                title="Click to see this product's detail"
            >
                <img
                    src={image}
                    alt={name}
                    height='80%'
                    //width='100%'
                    className={classes.img}
                />
            </Link>
            <GridListTileBar
                titlePosition="top"
                actionIcon={
                    <AddToCartButton
                        variant="text"
                        title="Add to Cart"
                        className={classes.icon}
                        id={id}
                        price={price}
                        productType={type}
                        deliveryCharge={deliveryCharge}
                        quantity={1}
                        text=""
                        stockOut={stockOut}
                    />
                }
                actionPosition="left"
                className={classes.titleBar}
            />
            <Typography
                gutterBottom
                align='center'
                color='primary'
                variant='subtitle1'
                title={name || banglaName}
            />
            <Typography
                align='center'
                color='primary'
                gutterBottom
            >
                {
                    stockOut
                        ?
                        <b style={{ color: 'red' }}>Out of Stock</b>
                        :
                        <i>{price} Tk</i>
                }
            </Typography>
        </GridListTile>
    );

export default withStyles(styles)(Product);