import React from 'react';
import { 
	makeStyles,
	Modal
} from '@material-ui/core';
import {
	Button
} from '.';

function rand() {
  	return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
	const top = 50 + rand();
	//   const left = 50 + rand();
	return {
		top: `${top}%`,
	};
}

const useStyles = makeStyles(
	theme => (
		{
			paper: {
				position: 'absolute',
				width: 400,
				zIndex: 1000,
				margin: '0 auto',
				backgroundColor: theme.palette.background.paper,
				border: '2px solid #000',
				boxShadow: theme.shadows[5],
				padding: theme.spacing(2, 4, 3),
				[theme.breakpoints.down('md')]: {
					width: '100%'
				},
			},
		}
	)
);

export default ({
    onClose,
    remove,
	open,
	name
}) => {
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);

	return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={open}
			onClose={onClose}
		>
			<div 
				style={modalStyle} 
				className={classes.paper}
			>
				<h2 
					id="simple-modal-title"
				>
					Delete Confirmation
				</h2>
				<p 
					id="simple-modal-description"
				>
					Are You Sure You Want To Delete this {name}?
				</p>
				<Button 
					onClick={
						() => {
							remove();
							onClose();
						}
					}
					buttonText='delete'
				/>	
				<Button
					onClick={onClose}
					buttonText='cancel'
				/>
			</div>
		</Modal>
	);
}