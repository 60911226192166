import React from 'react';
import {
    connect
} from 'react-redux';
import {
    makeStyles,
    Grid,
    Paper,
    ButtonBase
} from '@material-ui/core';
import {
    Typography
} from '../../commonComponents';
import {
    getProfileById
} from '../../redux/reducers';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                flexGrow: 1,
            },
            paper: {
                padding: theme.spacing(2),
                margin: 'auto',
                maxWidth: 500,
            },
            image: {
                width: 128,
                height: 128,
            },
            img: {
                margin: 'auto',
                display: 'block',
                maxWidth: '100%',
                maxHeight: '100%',
            },
        }
    )
);

const mapStateToProps = (state, ownProps) => ({
    profile: getProfileById(state, ownProps.match.params.id)
});

export default connect(mapStateToProps)(
    props => {
        const classes = useStyles();

        let join = new Date(props.profile.created_at);

        return (
            <Paper
                className={classes.paper}
            >
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                    >
                        <ButtonBase
                            className={classes.image}
                        >
                            <img
                                className={classes.img}
                                alt="userpicture"
                                src="/static/images/grid/complex.jpg"
                            />
                        </ButtonBase>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm
                        container
                    >
                        <Grid
                            item
                            xs
                            container
                            direction="column"
                            spacing={2}
                        >
                            <Grid
                                item
                                xs
                            >
                                <Typography
                                    gutterBottom
                                    variant="subtitle1"
                                    title={props.profile.name}
                                />
                                <Typography
                                    variant="body2"
                                    title={props.profile.phone}
                                />
                                <Typography
                                    variant="body2"
                                    title={props.profile.email}
                                />
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {
                                        (
                                            Array.isArray(props.profile.address)
                                            &&
                                            props.profile.address.join(', ')
                                        )
                                        ||
                                        ''
                                    }
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    title={`Joined In ${join.toString().slice(0, 15)}`}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item>
                        <Typography variant="subtitle1">$19.00</Typography>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Paper>
        );
    }
);