import React from 'react';
import {
    connect
} from 'react-redux';
import Layout from './CommonLayout';
import {
    logout
} from '../../redux/actions/user/Login';

const mapDispatchToProps = {
    logout
}

export default connect(
    null, 
    mapDispatchToProps
)(
    ({
        logout,
        ...props
    }) => (
        <Layout
            buttonText="logout"
            buttonAction={logout}
            buttonHref="/"
            {...props}
        />
    )
);