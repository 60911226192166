import React from 'react';
import {
    makeStyles
} from '@material-ui/core';
import {
    Divider
} from '../../commonComponents';

const useStyles = makeStyles((
    theme
) => ({
    div: {
        width: '100%',
        margin: '15px auto',
        height: '1px',
        [theme.breakpoints.down('xs')]: {
            margin: '35px auto 10px auto',
        },
    }
}
    ));

export default () => {
    const classes = useStyles();

    return (
        <Divider
            className={classes.div}
        />
    );
}