import {
    UPDATE
} from '../../../consts/StaticContent';
import {
    apiAction
} from '../Helper';

export const update = ({
    id,
    content,
    slug
}) => apiAction({
    url: '/api/staticcontent/update/' + id,
    method: 'POST',
    data: {
        content_slug: slug,
        static_content: content
    },
    onSuccess: data => ({
        type: UPDATE,
        data: {
            ...data,
            id
        }
    }),
    label: UPDATE
});