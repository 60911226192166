import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv';
import App from './App';
import UpdateChecker from './swupdateWrapper';
import store from './redux/Store';

dotenv.config();

ReactDOM.render(
    <UpdateChecker>
        <App store={store} />
    </UpdateChecker>,
    document.getElementById('root')
);