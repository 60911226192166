import React, {
    useState
} from 'react';
import {
    useSelector
} from 'react-redux';
import PopupState, {
    bindToggle,
    bindPopover,
    bindHover
} from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import {
    withStyles,
    AppBar,
    Grid,
    Toolbar,
    ButtonBase,
    fade,
    Popover,
    Badge,
} from '@material-ui/core';
import {
    ShoppingCart,
    // Search
} from '@material-ui/icons';
import {
    Typography,
    Divider,
    Button,
    Link
} from '../../../commonComponents';
import Cart from '../../cart';
import MenuBar from '../../menubar/bigger';
import FeaturedProduct from '../../homePage/FeaturedProduct';
import ProfileAction from '../../menubar/ProfileAction';
import HideOnScroll from '../../../utils/HideOnScroll';
// import ReviewSegment from '../../reviewsegment/index';
import Loading from '../../loading';
import Footer from '../../footer';
import SearchDropdown from '../../searchField/DropDown';
import Logos from '../../logos/index';
import {
    getUserIsFetching,
    getCount,
    getProfile
} from '../../../redux/reducers';
import Icon from '../../../assets/icon.png';
import { useWindowScroll } from '../../../hooks';

const styles = theme => (
    {
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'static',
            display: 'flex',
            height: 'auto',
            minHeight: '100vh',
            width: '100%',
            margin: '0 auto',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.secondary.main, 0.5),
            '&:hover': {
                backgroundColor: fade(theme.palette.secondary.main, 0.7),
            },
            marginLeft: 0,
            width: 'auto',
            [theme.breakpoints.down('md')]: {
                marginLeft: theme.spacing(1),
                width: '30%',
            },
            color: theme.palette.primary.main,
            '& .MuiOutlinedInput-root': {
                padding: 0,
                height: '38px'
            },
            '& .MuiFormLabel-root': {
                transform: 'translate(14px, 12px) scale(1)'
            },
            '& .MuiInputLabel-shrink': {
                transform: 'translate(14px, -6px) scale(0.75)'
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: fade(theme.palette.secondary.main, 0.7)
            }
        },
        searchIcon: {
            width: theme.spacing(4.5),
            height: '100%',
            position: 'absolute',
            // pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main,
            zIndex: 2000
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
            padding: 0,
            [theme.breakpoints.up('lg')]: {
                minWidth: 320
            }
        },
        inputInput: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(5),
            transition: theme.transitions.create('width'),
            width: 296,
            [theme.breakpoints.down('sm')]: {
                width: 240
            },
            [theme.breakpoints.down('lg')]: {
                // marginLeft: theme.spacing(1),
                width: '80%',
                // transition: 'none',
            },
            '&:focus': {
                [theme.breakpoints.up('lg')]: {
                    width: 700
                }
            },
        },
        cart: {
            margin: '0 0 0 5px',
            fontSize: 20
        },
        appBar: {
            zIndex: 100,
            backgroundColor: theme.palette.secondary.main,
        },
        link: {
            display: 'flex',
            wrap: 'nowrap',
            justify: 'center',
            alignItems: 'center',
            color: theme.palette.primary.main,
            textDecoration: 'none'
        },
        grow: {
            flexGrow: 1,
        },
        toolbar1: {
            minHeight: '40px',
            width: '100%',
            // maxHeight: '6px',
            margin: 0.5,
            padding: 0,
            color: theme.palette.primary.main,
            backgroundColor: '#8bc34a',
        },
        toolbar2: {
            minHeight: '45px',
            width: '100%',
            padding: 0,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
            margin: '0 auto'
        },
        '@global': {
            'html, body, #root': {
                height: '100%',
                margin: 0,
                width: '100%',
                overflow: 'auto',
            },
        },
        logo: {
            margin: '0 5px',
            verticalAlign: 'middle',
            fontFamily: '"KirtinashaMJ"'
        },
        divider: {
            margin: '35px auto'
        }
    }
);

const Layout = ({
    classes,
    children,
    popupState,
    ...props
}) => {
    useWindowScroll(popupState.close);
    const cartCount = useSelector(getCount), isFetching = useSelector(getUserIsFetching), name = useSelector(getProfile).name;
    const [
        state,
        setState
    ] = useState({
        cart: false,
        anchorEl: null,
        // loginRegister: false,
    });

    const openCart = event => {
        setState({
            ...state,
            cart: !state.cart,
            anchorEl: event.currentTarget
        });
    };

    const closeCart = value => {
        setState({
            ...state,
            cart: !state.cart,
            anchorEl: value,
        });
    };

    if (isFetching) {
        return <Loading />;
    }
    const id = state.cart ? 'simple-popover' : undefined;

    return (
        <Grid
            container
            direction='column'
            className={classes.root}
            justify='flex-end'
            //alignContent='baseline' 
            alignItems='stretch'
        >
            <Grid
                item
                xs
            >
                <HideOnScroll
                    {...props}
                >
                    <AppBar
                        className={classes.appBar}
                        position="fixed"
                    >
                        <Toolbar
                            className={classes.toolbar1}
                            disableGutters
                        >
                            <Logos />
                            <div
                                className={classes.grow}
                            />
                            <div
                                title="Search for category, products etc"
                                className={classes.search}
                            >
                                {/* <IconButton className={classes.searchIcon} onClick={clickSearch}>
                                    <Search />
                                </IconButton> */}
                                <SearchDropdown
                                    classes={{
                                        inputRoot: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                />
                                {/* <InputBase
                                    placeholder="Search for products e.g. Adenium"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    value={searchValue}
                                    onChange={onChangeSearch}
                                    onKeyUp={onEnter}
                                /> */}
                            </div>
                            <Badge
                                badgeContent={cartCount}
                                color="primary"
                            >
                                <ButtonBase
                                    title="Cart"
                                    {...bindToggle(popupState)}
                                    {...bindHover(popupState)}
                                >
                                    <ShoppingCart
                                        className={classes.cart}
                                        onClick={openCart}
                                    />
                                </ButtonBase>
                            </Badge>
                            {
                                props.buttonText === "login"
                                &&
                                <Button
                                    component={Link}
                                    to={props.buttonHref}
                                    color='inherit'
                                    variant='text'
                                    onClick={props.buttonAction}
                                    buttonText={props.buttonText}
                                />
                            }
                            {
                                props.buttonText === "logout"
                                &&
                                <ProfileAction
                                    name={name}
                                    to={props.buttonHref}
                                    button={props.buttonText}
                                    onClick={props.buttonAction}
                                />
                            }
                        </Toolbar>
                        <Toolbar className={classes.toolbar2} >
                            <div className={classes.grow} />
                            <Link
                                to="/"
                                title="Gaachwala Home"
                                className={classes.link}
                            >
                                <img
                                    alt="gaachwala logo"
                                    src={Icon}
                                    height="50"
                                    width="50"
                                    className={classes.logo}
                                />
                                <Typography
                                    variant='h6'
                                    noWrap
                                    color='inherit'
                                    boldText='গাছওয়ালা'
                                />
                            </Link>
                            <MenuBar />
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Popover
                    open={state.cart}
                    id={id}
                    transitionDuration={850}
                    onMouseLeave={popupState.close}
                    onClick={popupState.close}
                    {...bindPopover(popupState)}
                    modifiers={{
                        flip: {
                            enabled: true
                        },
                        preventOverflow: {
                            enabled: false,
                            // boundariesElement: "scrollParent"
                        }
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Cart
                        id={id}
                        open={state.cart}
                        onClose={closeCart}
                        anchorEl={state.anchorEl}
                    />
                </Popover>
                {children}
                <Divider
                    className={classes.divider}
                />
                <FeaturedProduct />
                <Divider
                    className={classes.divider}
                />
                {/* <ReviewSegment />
                <Divider className={classes.divider} /> */}
                <Footer />
            </Grid>
        </Grid>
    );
};

const PopupStateWrapper = props => (
    <PopupState
        variant="popover"
        popupId="demo-popup-popover"
    >
        {
            popupState => (
                <Layout
                    popupState={popupState}
                    {...props}
                />
            )
        }
    </PopupState>
);

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PopupStateWrapper);