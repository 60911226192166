import React, { 
    useEffect 
} from 'react';
import { 
    connect 
} from 'react-redux';
import {
    makeStyles,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
} from '@material-ui/core';
import {
    Link,
    Button,
    Divider
} from '../../commonComponents';
import { 
    getUserList 
} from '../../redux/reducers';
import { 
    fetchAllUser 
} from '../../redux/actions/user/Fetch';
import { 
    deleteUser 
} from '../../redux/actions/user/Update';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
                maxWidth: 360,
                backgroundColor: theme.palette.background.paper,
            },
            inline: {
                display: 'inline',
            },
            list: {
                color: theme.palette.primary.dark,
            }
        }
    )
);

const mapStateToProps = state => ({
	users: getUserList(state),
});

const mapDispatchToProps = {
    deleteUser,
    fetchAllUser
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(
    ({
        fetchAllUser,
        deleteUser,
        users,
    }) => {
        const classes = useStyles();

        useEffect(
            () => {
                fetchAllUser(); 
            }, [
                fetchAllUser
            ]
        );

        if(!users || !Array.isArray(users)) return null;

        return (
            <List 
                className={classes.root}
            >
                {
                    users.map(
                        (user, index) =>
                        {
                            let r = new Date(user.created_at);
                            return (
                                <React.Fragment 
                                    key={index}
                                >
                                    {
                                        index > 0 
                                        ? 
                                        <Divider 
                                            variant="inset" 
                                            component="li" 
                                        /> 
                                        : 
                                        ""
                                    }
                                    <Link 
                                        to={"/adminuserlist/user/" + (user.id || 'dummy')} 
                                    >
                                        <ListItem 
                                            alignItems="flex-start" 
                                            className={classes.list}
                                        >
                                            <ListItemAvatar>
                                                <Avatar 
                                                    alt={user.name} 
                                                    // src={src} 
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={user.name}
                                                secondary={
                                                    <span>
                                                        Joined in {r.toString().slice(0, 15)}
                                                    </span>
                                                }
                                            />
                                        </ListItem>
                                    </Link>
                                    <Button
                                        color='primary'
                                        onClick={
                                            () => deleteUser(user.id)
                                        }
                                        buttonText='delete'
                                    />
                                </React.Fragment>
                            )                         
                        }                                 
                    )
                }
            </List>
        );
    }
)