import {
    useEffect
} from 'react';
import {
    useDebouncedCallback
} from 'use-debounce';

const useWindowScroll = handler => {
    const [debouncedHandler] = useDebouncedCallback(handler, 32);

    useEffect(
        () => {
            window.addEventListener('scroll', debouncedHandler);

            return () => window.removeEventListener('scroll', debouncedHandler);
        }
    );
};

export default useWindowScroll;