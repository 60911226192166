import {
    DELETE
} from '../../../../consts/promo/Admin';
import {
    apiAction
} from '../../Helper';

export const remove = id => apiAction({
    url: '/api/promo/remove/' + id,
    method: 'POST',
    onSuccess: data => ({
        type: DELETE,
        data
    }),
    label: DELETE
});