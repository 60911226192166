import React, {
    useState
} from 'react';
import {
    connect
} from 'react-redux';
import {
    withStyles,
    SwipeableDrawer,
    ListItemSecondaryAction,
    Grow,
    AppBar,
    Toolbar,
    ListItemText,
    ListItem,
    Collapse,
    Paper,
    Grid,
    Badge,
    Popover,
} from '@material-ui/core';
import SearchField from '../../searchField/index';
import {
    bindTrigger,
    bindPopover,
    usePopupState
} from 'material-ui-popup-state/hooks';
import {
    Menu as MenuIcon,
    ExpandLess,
    ExpandMore,
    ShoppingCart,
    Search,
} from '@material-ui/icons';
// import menus from './menus';
import Footer from '../../footer';
// import ReviewSegment from '../../reviewsegment/index';
import FeaturedProduct from '../../homePage/FeaturedProduct';
import ProfileAction from '../../menubar/ProfileAction';
import {
    Button,
    Link,
    Divider
} from '../../../commonComponents';
import Cart from '../../cart';
import HideOnScroll from '../../../utils/HideOnScroll';
import {
    getCount,
    getMenu,
    getProfile
} from 'redux/reducers';
import Icon from '../../../assets/icon.png';

const styles = theme => (
    {
        root: {
            height: 'auto',
            //minHeight: '100vh',
            width: '100%',
            margin: 0,
            overflow: 'hidden',
            '& .MuiListItem-container': {
                listStyle: 'none'
            }
        },
        toolbar: {
            width: '100%',
            maxHeight: '50px',
            minHeight: '50px',
            padding: 0,
            color: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.main,
            margin: '0 auto'
        },
        links: {
            width: '100%',
            color: theme.palette.secondary.dark,
            textDecoration: 'none',
        },
        menu: {
            height: 'auto',
            width: '320px',
            color: theme.palette.primary.main,
            paddingLeft: 5,
        },
        menu1: {
            height: 'auto',
            width: '320px',
            color: theme.palette.primary.main,
            backgoundColor: '#b0bec5',
            paddingLeft: 5,
            listStyle: 'none'
        },
        list: {
            paddingLeft: 10,
            transition: 'all .6s ease-out',
            '&:hover': {
                transform: 'translate(8px, 0px)',
            },
            '& .MuiListItem-container': {
                listStyle: 'none'
            }
        },
        clear: {
            height: '30px',
            width: '100px',
            margin: '0 auto',
            color: theme.palette.primary.main,
        },
        drawer: {
            width: '100%'
        },
        item1: {
            width: '40%',
            color: theme.palette.secondary.dark,
        },
        item2: {
            width: '20%',
            color: theme.palette.secondary.dark,
        },
        '@global': {
            'html, body, #root': {
                height: 'auto',
                margin: 0,
                minHeight: '100vh',
                overflow: 'auto',
            },
            '& .MuiListItem-container': {
                listStyle: 'none'
            }
        },
        divider: {
            margin: '35px auto'
        },
        button: {
            minWidth: 'auto',
            width: 'auto',
            maxWidth: 'auto'
        }
    }
);

const AppbarforSmaller = ({
    menus,
    classes,
    children,
    cartCount,
    ...props
}) => {
    const [
        state,
        setState
    ] = useState({
        cart: false,
        anchorEl: null,
        anchorEl1: null,
        anchorEl2: null,
        search: false
    });

    const [
        menu,
        setMenu
    ] = useState({});

    const searchPopupState = usePopupState({
        variant: 'popover',
        popupId: 'search-popup'
    });

    const cartPopupState = usePopupState({
        variant: 'popover',
        popupId: 'cart-popup'
    });

    const openSearch = event => {
        setState(prevState => ({
            ...prevState,
            search: true,
            anchorEl2: event.currentTarget
        }));
    }

    const closeSearch = event => {
        setState(prevState => ({
            ...prevState,
            search: false,
            anchorEl2: null,
        }));

        searchPopupState.close();
    }

    const openCart = event => {
        setState(prevState => ({
            ...prevState,
            cart: !prevState.cart,
            anchorEl1: event.currentTarget
        }));
    };

    const closeCart = event => {
        setState(prevState => ({
            ...prevState,
            cart: !prevState.cart,
            anchorEl1: null,
        }));
    }

    const handleClick = event => {
        setState(prevState => ({ ...prevState, anchorEl: event.currentTarget }));
    };

    const handleClose = event => {
        setState(prevState => ({ ...prevState, anchorEl: null }));
    };

    // closeCart = event => {
    //     this.setState({
    //         cart: false,
    //         anchorEl1: null
    //     });
    // };

    const handleOpen = item => {
        setMenu(prevState => ({
            ...prevState,
            [item]: !prevState[item]
        }));
    }

    const handler = children => {

        return children.map(
            (subOption, index) => {
                if (!subOption.children
                    ||
                    !Array.isArray(subOption.children)
                    ||
                    (Array.isArray(subOption.children)
                        &&
                        subOption.children.length === 0
                    )) {
                    return (
                        <Grow
                            in={
                                subOption.name
                                    ?
                                    true
                                    :
                                    false
                            }
                            key={index}
                            timeout={850}
                        >
                            <Paper
                                key={subOption.name}
                                elevation={0}
                                className={classes.menu}
                            >
                                <Link
                                    to={{
                                        pathname: `/${subOption.name}/productlist`,
                                        state: subOption.id
                                    }}
                                    className={classes.links}
                                >
                                    <ListItem
                                        button
                                        divider
                                        className={classes.list}
                                    >
                                        <ListItemText
                                            inset
                                            className={classes.list}
                                            onClick={handleClose}
                                            primary={subOption.name}
                                        />
                                    </ListItem>
                                </Link>
                            </Paper>
                        </Grow>
                    );
                }
                return (
                    <Paper
                        key={index}
                        className={classes.menu1}
                        elevation={0}
                    >
                        <ListItem
                            button
                            divider
                            alignItems="flex-start"
                            className={classes.list}
                        >
                            <Link
                                to={{
                                    pathname: `/${subOption.name}/productlist`,
                                    state: subOption.id
                                }}
                                className={classes.links}
                            >
                                <ListItemText
                                    inset
                                    primary={subOption.name}
                                    onClick={handleClose}
                                    className={classes.list}
                                />
                            </Link>
                            {
                                menu[subOption.id]
                                    ?
                                    <ListItemSecondaryAction
                                        onClick={
                                            event => {
                                                event.stopPropagation();
                                                handleOpen(subOption.id);
                                            }
                                        }
                                    >
                                        <ExpandLess />
                                    </ListItemSecondaryAction>
                                    :
                                    <ListItemSecondaryAction>
                                        <ExpandMore
                                            onClick={
                                                event => {
                                                    event.stopPropagation();
                                                    handleOpen(subOption.id);
                                                }
                                            }
                                        />
                                    </ListItemSecondaryAction>
                            }
                        </ListItem>
                        <Collapse
                            in={menu[subOption.id]}
                            timeout={850}
                            unmountOnExit
                        >
                            {
                                handler(subOption.children)
                            }
                        </Collapse>
                    </Paper>
                );
            }
        );
    }

    const open1 = Boolean(state.anchorEl);
    const open2 = Boolean(state.cart);
    const open3 = Boolean(state.search);

    return (
        <Grid
            container
            className={classes.root}
            justify='center'
            alignContent='flex-start'
        >
            <Grid
                item
                xs
            >
                <HideOnScroll {...props}>
                    <AppBar
                        position="fixed"
                        className={classes.root}
                    >
                        <Toolbar
                            className={classes.toolbar}
                        >
                            <div
                                align='left'
                                className={classes.item1}
                            >
                                <Button
                                    className={classes.button}
                                    aria-owns={
                                        open1
                                            ?
                                            'fade-menu'
                                            :
                                            null
                                    }
                                    aria-haspopup="true"
                                    variant='text'
                                    color='inherit'
                                    size='small'
                                    onClick={handleClick}
                                    Icon={MenuIcon}
                                    buttonText='Menu'
                                />

                                <Button
                                    onClick={openSearch}
                                    className={classes.button}
                                    color='inherit'
                                    variant='text'
                                    size='small'
                                    {...bindTrigger(searchPopupState)}
                                >
                                    <Search />
                                </Button>
                            </div>
                            <div
                                align='center'
                                className={classes.item2}
                            >
                                <Button
                                    component={Link}
                                    to="/"
                                    variant='text'
                                >
                                    <img
                                        alt="gaachwala logo"
                                        src={Icon}
                                        height="50"
                                        width="50"
                                    />
                                </Button>
                            </div>

                            <div
                                align='right'
                                className={classes.item1}
                            >
                                <Button
                                    onClick={openCart}
                                    color='inherit'
                                    variant='text'
                                    className={classes.button}
                                    {...bindTrigger(cartPopupState)}
                                >
                                    <Badge
                                        badgeContent={cartCount}
                                    >
                                        <ShoppingCart />
                                    </Badge>
                                </Button>
                                {
                                    props.buttonText === "login"
                                    &&
                                    <Button
                                        component={Link}
                                        to={props.buttonHref}
                                        color='inherit'
                                        variant='text'
                                        className={classes.button}
                                        onClick={props.buttonAction}
                                        buttonText={props.buttonText}
                                    />
                                }
                                {
                                    props.buttonText === "logout"
                                    &&
                                    <ProfileAction
                                        name={props.name}
                                        to={props.buttonHref}
                                        button={props.buttonText}
                                        onClick={props.buttonAction}
                                    />
                                }
                            </div>
                            <SwipeableDrawer
                                id="fade-menu"
                                open={open1}
                                onOpen={handleClick}
                                autoFocus
                                anchor='left'
                                onClose={handleClose}
                                transitionDuration={500}
                                className={classes.drawer}
                                width='100%'
                            >
                                {
                                    handler(menus.data)
                                }

                                <br />

                                <Button
                                    variant='text'
                                    className={classes.clear}
                                    onClick={handleClose}
                                    buttonText='Close'
                                />

                            </SwipeableDrawer>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Popover
                    {...bindPopover(cartPopupState)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Cart
                        open={open2}
                        // onClose={this.closeCart} 
                        anchorEl={state.anchorEl1}
                        onClose={closeCart}
                        id="cart"
                    />
                </Popover>
                <Popover
                    {...bindPopover(searchPopupState)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transitionDuration={850}
                >
                    <SearchField
                        open={open3}
                        // onClose={this.closeCart} 
                        anchorEl={state.anchorEl2}
                        onClose={closeSearch}
                        id="search"
                    />
                </Popover>
                {children}
                <Divider
                    className={classes.divider}
                />
                <FeaturedProduct />
                {/* <Divider className={classes.divider} />
                <ReviewSegment /> */}
                <Divider
                    className={classes.divider}
                />
                <Footer />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => ({
    cartCount: getCount(state),
    menus: getMenu(state),
    name: getProfile(state).name,
})

export default connect(mapStateToProps)(withStyles(styles)(AppbarforSmaller));
