import React, {
	useEffect
} from 'react';
import {
	connect
} from 'react-redux';
import compose from 'recompose/compose';
import {
	makeStyles,
	withStyles,
	Dialog,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Grid,
	withWidth,
	Slide
} from '@material-ui/core';
import {
	Typography,
	Link,
	Button
} from '../../commonComponents';
import Logo from '../logo/index';
import {
	getCartTable,
	getLatestOrder
} from '../../redux/reducers';
import {
	update
} from '../../redux/actions/order/Update';

const CustomTableCell = withStyles(
	theme => (
		{
			head: {
				paddingRight: 4,
				paddingLeft: 5,
				borderBottom: 'none'
			},
			body: {
				paddingRight: 4,
				paddingLeft: 5,
				borderBottom: 'none'
			},
		}
	)
)(TableCell);

const useStyles = makeStyles(
	theme => (
		{
			info: {
				padding: theme.spacing(0, 1),
			},
			divider: {
				width: '70%',
				margin: '0 auto',
				backgroundColor: theme.palette.secondary.dark,
				//height: '2px'
			},
			button: {
				textTransform: 'capitalize',
				backgroundColor: theme.palette.secondary.main,
				width: '40%',
				[theme.breakpoints.down('sm')]: {
					width: '70%',
				},
			},
			buttonitem: {
				width: '100%',
				margin: '10px auto',
			}
		}
	)
);

function ccyFormat(num) {
	return `${parseFloat(num).toFixed(2)}`;
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide
		direction="up"
		ref={ref}
		{...props}
		timeout={800}
	/>;
});

const Invoice = ({
	order,
	cart,
	width,
	setData,
	onClose,
	open,
	...props
}) => {
	const classes = useStyles();

	useEffect(
		() => {
			if (order && cart) {
				setData({
					order: order,
					cart: cart
				});
			}
		}, [
		order,
		cart,
		setData
	]
	);

	if (!order) {
		return (
			<p>
				Invoice generating
			</p>
		);
	}

	let date = new Date().toLocaleString();

	return (
		<Dialog
			fullScreen={
				(
					width === 'xs'
					||
					width === 'sm'
				)
					?
					true
					:
					false
			}
			open={open}
			TransitionComponent={Transition}
			onClose={onClose}
		>
			<Logo />
			<div
				align='center'
			>
				<Button
					color='primary'
					className={classes.button}
					onClick={
						() => {
							props.update(order)
							props.onClick();
						}
					}
					buttonText='click here to confirm your order'
				/>
			</div>

			<Typography
				variant='subtitle1'
				color='inherit'
				className={classes.info}
				boldText='Name: '
				title={order.firstName + ' ' + order.lastName}
			/>
			<Typography
				variant='subtitle1'
				color='inherit'
				className={classes.info}
				boldText='Address: '
				title={order.address}
			/>
			<Typography
				variant='subtitle1'
				color='inherit'
				className={classes.info}
				boldText='Contact No. '
				title={order.contactNumber}
			/>
			<Typography
				variant='subtitle1'
				color='inherit'
				className={classes.info}
				boldText='Order Number: '
				title={order.orderNumber}
			/>
			<Typography
				variant='subtitle1'
				color='inherit'
				className={classes.info}
				boldText='Date: '
				title={date}
			/>

			<Table>
				<TableHead>
					<TableRow>
						<CustomTableCell>
							<b>
								Item
							</b>
						</CustomTableCell>
						<CustomTableCell
						>
							<b>
								Price
							</b>
						</CustomTableCell>
						<CustomTableCell
						>
							<b>
								Delivery Charge
							</b>
						</CustomTableCell>
						<CustomTableCell
							align="right"
						>
							<b>
								Total
							</b>
						</CustomTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						cart.data.map(
							(row, index) => (
								<TableRow
									key={index}
								>
									<CustomTableCell>
										{index + 1}. {row.name}
									</CustomTableCell>
									<CustomTableCell
									>
										{`${row.quantity} X ${row.price} = ${row.price * row.quantity}`}
									</CustomTableCell>
									<CustomTableCell
									>
										{`${row.quantity} X ${row.deliveryCharge} = ${row.deliveryCharge * row.quantity}`}
									</CustomTableCell>
									<CustomTableCell
										align="right"
									>
										{row.quantity * (row.price + row.deliveryCharge)}
									</CustomTableCell>
								</TableRow>
							)
						)
					}
					<TableRow>
						<CustomTableCell
							colSpan={3}
						>
							<b>
								Sub Total in Taka (+)
							</b>
						</CustomTableCell>
						<CustomTableCell
							align="right"
						>
							<b>
								{
									ccyFormat(cart.totAmount)
								}
							</b>
						</CustomTableCell>
					</TableRow>
					<TableRow>
						<CustomTableCell
							colSpan={3}
						>
							<b>
								Discount in Taka (-)
							</b>
						</CustomTableCell>
						<CustomTableCell
							align="right"
						>
							<b>
								{
									ccyFormat(cart.discount)
								}
							</b>
						</CustomTableCell>
					</TableRow>
					<TableRow>
						<CustomTableCell
							colSpan={3}
						>
							<b>
								Delivery charge in Taka (+)
							</b>
						</CustomTableCell>
						<CustomTableCell
							align="right"
						>
							<b>
								{
									ccyFormat(cart.deliveryCharge)
								}
							</b>
						</CustomTableCell>
					</TableRow>
					<TableRow>
						<CustomTableCell
							colSpan={3}
						>
							<b>
								Total in Taka
							</b>
						</CustomTableCell>
						<CustomTableCell
							align="right"
						>
							<b>
								{
									ccyFormat(cart.totAmount + cart.deliveryCharge - cart.discount)
								}
							</b>
						</CustomTableCell>
					</TableRow>
				</TableBody>
			</Table>
			<br />
			<Divider className={classes.divider} />
			<br />
			<br />
			<Grid
				container
				// spacing={1}
				justify='center'
				alignItems='center'
			>
				<Grid
					item
					xs={5}
				>
					<Typography
						align='left'
						title={`Cashier's Signature`}
					/>
				</Grid>
				<Grid
					item
					xs={5}
				>
					<Typography
						align='right'
						title={`Customer's Signature`}
					/>
				</Grid>
				<div
					align='center'
					className={classes.buttonitem}
				>
					<Button
						color='primary'
						className={classes.button}
						onClick={
							() => {
								props.update(order)
								props.onClick();
							}
						}
						buttonText='click here to confirm your order'
					/>
				</div>
				<div
					align='center'
					className={classes.buttonitem}
				>
					<Button
						component={Link}
						onClick={onClose}
						buttonText='Close'
						color='primary'
						to="/"
					/>
				</div>
			</Grid>
		</Dialog>
	);
};

const mapStateToProps = state => ({
	cart: getCartTable(state),
	order: getLatestOrder(state)
});

const mapDispatchToProps = {
	update
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withWidth())(Invoice);
