import React from 'react';
import Grow from '@material-ui/core/Grow';
import Table from './Table';

export default () => (
	<Grow 
		timeout={850}
	>
		<Table/>
	</Grow>   
);