import React, {
    useRef,
    useState,
    useEffect
} from 'react';
import clsx from 'clsx';
import {
    Editor, 
    EditorState, 
    RichUtils, 
    getDefaultKeyBinding, 
    convertFromRaw,
    convertToRaw
} from 'draft-js';
import './RichEditor.css';

const RichEditorExample = props => {
    const [editorState, setEditorState] = useState(
        props.value && Object.keys(props.value).length
        ?
        EditorState.createWithContent(
            convertFromRaw(props.value)
        ) 
        :
        EditorState.createEmpty());

    useEffect(
        () => {
            if(props.readOnly && props.value && Object.keys(props.value).length) {
                setEditorState(EditorState.createWithContent(
                    convertFromRaw(props.value)
                ));
            }
        },
        [
            props.readOnly,
            props.value
        ]
    )

    const editor = useRef(null);

    const focus = () => editor.current && editor.current.focus();
    const onChange = editorState => {
        if(props.onChange) props.onChange(convertToRaw(editorState.getCurrentContent()));
        setEditorState(editorState);
    };

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
            return true;
        }
        return false;
    };

    const mapKeyToEditorCommand = e => {
        if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
            e,
            editorState,
            4, /* maxDepth */
        );
            if (newEditorState !== editorState) {
                onChange(newEditorState);
            }
            
            return;
        }
        
        return getDefaultKeyBinding(e);
    };

    const toggleBlockType = blockType => {
        onChange(
            RichUtils.toggleBlockType(
                editorState,
                blockType
            )
        );
    };

    const toggleInlineStyle = inlineStyle => {
        onChange(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        );
    };
    
    const readOnly = Boolean(props.readOnly);
        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    if(readOnly) className += ' RichEditor-no-border';

    // console.log(convertToRaw(contentState));


    return (
        <div className={
            clsx(
                "RichEditor-root",
                {
                    "RichEditor-no-border": readOnly
                }
            )
        }>
            <BlockStyleControls
                editorState={editorState}
                onToggle={toggleBlockType}
                hide={readOnly}
            />
            <InlineStyleControls
                editorState={editorState}
                onToggle={toggleInlineStyle}
                hide={readOnly}
            />
            <div className={className} onClick={focus}>
                <Editor
                    blockStyleFn={getBlockStyle}
                    customStyleMap={styleMap}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={mapKeyToEditorCommand}
                    onChange={onChange}
                    placeholder=""
                    ref={editor}
                    spellCheck={true}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
};

    // Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    BOLD: {
        color: '#1b5e20',
        fontWeight: 'bold'
    }
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': 
            return 'RichEditor-blockquote';
        default: 
            return null;
    }
};

const StyleButton = props => {
    const onToggle = e => {
        e.preventDefault();
        props.onToggle(props.style);
    };

    let className = 'RichEditor-styleButton';
    if (props.active) {
        className += ' RichEditor-activeButton';
    }

    return (
        <span className={className} onMouseDown={onToggle}>
            {props.label}
        </span>
    );
}

const BLOCK_TYPES = [
    {
        label: 'H1', 
        style: 'header-one'
    },
    {
        label: 'H2', 
        style: 'header-two'
    },
    {
        label: 'H3', 
        style: 'header-three'
    },
    {
        label: 'H4', 
        style: 'header-four'
    },
    {
        label: 'H5', 
        style: 'header-five'
    },
    {
        label: 'H6', 
        style: 'header-six'
    },
    {
        label: 'Blockquote', 
        style: 'blockquote'
    },
    {
        label: 'UL', 
        style: 'unordered-list-item'
    },
    {
        label: 'OL', 
        style: 'ordered-list-item'
    },
    {
        label: 'Code Block', 
        style: 'code-block'
    },
    {
        label: 'Divider',
        style: 'divider'
    }
];

const BlockStyleControls = props => {
    const {editorState, hide} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div 
            className={
                clsx(
                    "RichEditor-controls", 
                    {
                        "RichEditor-hide": hide
                    }
                )
            }
        >
            {
                BLOCK_TYPES.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={type.style === blockType}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )
            }
        </div>
    );
};

const INLINE_STYLES = [
    {
        label: 'Bold', 
        style: 'BOLD'
    },
    {
        label: 'Italic', 
        style: 'ITALIC'
    },
    {
        label: 'Underline', 
        style: 'UNDERLINE'
    },
    {
        label: 'Monospace', 
        style: 'CODE'
    },
];

const InlineStyleControls = props => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    const {hide} = props;

    return (
        <div 
            className={
                clsx(
                    "RichEditor-controls", 
                    {
                        "RichEditor-hide": hide
                    }
                )
            }
        >
            {
                INLINE_STYLES.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={currentStyle.has(type.style)}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )
            }
        </div>
    );
};

export default RichEditorExample;