import {
    combineReducers
} from 'redux';
import cart, * as fromCart from './Cart';
import product, * as fromProduct from './Product';
import { CREATE_CART } from '../../../consts/cart/Create';

export const selectedCart = (
    state = "", 
    action
) => {
    switch(action.type){
        case CREATE_CART: 
            return action.data.id;
        default:
            return state;
    }
}

export default combineReducers({
    selectedCart,
    cart,
    product,
});

export const getCart = state => getCartById(state, state.selectedCart);

export const getCartProduct = (state, id) => fromProduct.getCartProduct(state.product, id);

export const getIsFetching = (state, id) => fromCart.getIsFetching(state.cart, id);

export const getCount = state => {
    const l = getCart(state);

    if(!l) return 0;
    else {
        return (
            l.cartProducts 
                ?
                (
                    Array.isArray(l.cartProducts)
                    ?
                    l.cartProducts.map(cp => getCartProduct(state, cp).quantity).reduce(
                        (prev, cur) => prev + cur, 0
                    )
                    :
                    0
                )
                :
                0
        ) || 0
    }      
}

export const getOrderByCartId = (state, id) => (
    fromCart
    .getCart(state.cart)
    .find(c => c.id === id)
);

export const getCartById = (state, id) => fromCart.getCartById(state.cart, id);

export const getTotAmountById = (state, id) => fromCart.getTotalAmountByID(state.cart, id);