import {
    UPDATE
} from '../../../../consts/promo/Admin';
import {
    apiAction
} from '../../Helper';

export const update = ({
    id,
    code,
    name,
    discount,
    maxDiscount,
    minPurchase,
    validity,
    usageLimit
}) => apiAction({
    url: '/api/promoupdate/' + id,
    method: 'POST',
    data: {
        promo_code: code,
        name,
        discount: Number(discount),
        max_discount: Number(maxDiscount),
        min_purchase: Number(minPurchase),
        end_date: validity,
        limit: Number(usageLimit)
    },
    onSuccess: data => ({
        type: UPDATE,
        data
    }),
    label: UPDATE
});