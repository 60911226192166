import React from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import PropTypes from 'prop-types';
import {
    isEmpty
} from 'lodash';
import clsx from 'clsx';
import {
    withStyles,
    Grid,
    Paper,
    ButtonGroup
} from '@material-ui/core';
import {
    Add,
    Remove
} from '@material-ui/icons';
import EmptyCart from './EmptyCart';
import Logo from '../logo/index';
import {
    Link,
    Button,
    Typography,
    Divider
} from '../../commonComponents';
import {
    getCartTable,
    getCartIsFetching,
    getActivePromo
} from '../../redux/reducers';
import {
    applyPromoToCart,
    updateQuantity,
    removeFromCart
} from '../../redux/actions/cart';
import {
    padLeft
} from '../../utils/strings';
import image from '../../assets/icon1.png';

const styles = theme => (
    {
        root: {
            minHeight: '90vh',
            height: 'auto',
            width: '100%',
            margin: '90px auto 0px auto',
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.info.main,
            [theme.breakpoints.down('sm')]: {
                margin: '50px auto 0px auto'
            },
        },
        button: {
            margin: '0 auto',
            width: '40%',
            [theme.breakpoints.down('sm')]: {
                width: '80%'
            },
        },
        text: {
            paddingRight: theme.spacing(2),
            whiteSpace: 'pre'
        },
        divider: {
            width: '90%',
        },
        divider1: {
            width: '40%',
            [theme.breakpoints.down('sm')]: {
                width: '90%'
            },
        },
        padding: {
            padding: 5,
            [theme.breakpoints.down('sm')]: {
                padding: '5px 2px'
            },
        },
        img: {
            objectFit: 'cover',
            padding: 5,
            [theme.breakpoints.down('sm')]: {
                padding: '5px 2px'
            },
        },
        quantityButton: {
            padding: '0px 20px'
        },
        promo: {
            marginBottom: theme.spacing(1)
        },
        hide: {
            display: 'none'
        }
    }
);

const CartPage = ({
    classes
}) => {
    const {
        data,
        discount = 0,
        totAmount,
        deliveryCharge,
        promoCode
    } = useSelector(getCartTable);
    const isFetching = useSelector(getCartIsFetching);
    const activePromo = useSelector(getActivePromo) || '';
    const dispatch = useDispatch();

    if (totAmount === 0) return <EmptyCart />;

    const Promo = (activePromo.id && promoCode) ? '*Discount will not be applicable on delivery charge' : '*Apply promo to avail discount on your purchase';
    const del = '**Delivery charge for plant and soil mixing is 120/-. Delivery charge of all planters is 100/-. The maximum charge for any delivery will be 240/-.'
    return (
        <Paper
            elevation={1}
            className={classes.root}
        >
            <Logo />
            <Typography
                title='Gaachwala Shopping Cart'
                variant='h6'
                color='primary'
                align='center'
            />
            <Divider
                className={classes.divider1}
            />
            {
                data.map(
                    (n, index) => (
                        <Grid
                            container
                            title={n.name}
                            key={index}
                        // spacing={2}
                        >
                            <Grid
                                item
                            >
                                <img
                                    className={classes.img}
                                    alt="complex"
                                    width='85'
                                    height='85'
                                    src={n.image || image}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm
                                container
                                // spacing={5}
                                className={classes.padding}
                            >
                                <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    className={classes.padding}
                                >
                                    <Grid
                                        item
                                        xs
                                        className={classes.padding}
                                    >
                                        <Typography
                                            gutterBottom
                                            color='primary'
                                            variant="body1"
                                            title={n.name}
                                        />

                                        {/* <Typography 
                                            variant="body2" 
                                            gutterBottom
                                        >
                                            if any pot has to show that will be here
                                        </Typography>

                                        <Typography 
                                            variant="body2" 
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            size will go here(if any)
                                        </Typography> */}

                                        <ButtonGroup
                                            color="primary"
                                            size="small"
                                        >
                                            <Button
                                                onClick={
                                                    () => dispatch(updateQuantity({
                                                        ...n,
                                                        quantity: n.quantity + 1
                                                    }))
                                                }
                                                disabled={
                                                    n.quantity === 10
                                                    ||
                                                    isFetching
                                                }
                                                Icon={Add}
                                            />
                                            <Button
                                                className={classes.quantityButton}
                                                buttonText={n.quantity}
                                            />
                                            <Button
                                                onClick={
                                                    () => dispatch(updateQuantity({
                                                        ...n,
                                                        quantity: n.quantity - 1
                                                    }))
                                                }
                                                disabled={
                                                    n.quantity === 1
                                                    ||
                                                    isFetching
                                                }
                                                Icon={Remove}
                                            />
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        className={classes.padding}
                                    >
                                        <Button
                                            size="small"
                                            color="primary"
                                            onClick={
                                                () => dispatch(removeFromCart(n))
                                            }
                                            buttonText="Remove from cart"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item className={classes.padding} >
                                    <Typography
                                        variant="subtitle1"
                                        align='right'
                                        title={`৳ ${n.price * n.quantity}`}
                                    />
                                    {/* <Typography
                                        variant="subtitle1"
                                        align='right'
                                        color='inherit'
                                        title={`Delivery Charge: ৳ ${n.deliveryCharge * n.quantity}`}
                                    /> */}

                                </Grid>
                            </Grid>
                            <Divider
                                className={classes.divider}
                            />
                        </Grid>
                    )
                )
            }
            <Typography
                variant="caption"
                color='inherit'
                title={Promo}
            />
            <br />
            <Typography
                variant="caption"
                color='inherit'
                title={del}
            />
            <Typography
                variant="subtitle1"
                align='right'
                className={classes.text}
                title={`Subtotal (+): ${padLeft('৳ ' + totAmount)}`}
            />
            <Typography
                variant="subtitle1"
                align='right'
                className={
                    clsx(
                        classes.text,
                        {
                            [classes.hide]: discount === 0
                        }
                    )
                }
                title={`Discount (-): ${padLeft('৳ ' + discount)}`}
            />
            <Typography
                variant="subtitle1"
                align='right'
                className={classes.text}
                title={`Delivery Fee (+): ${padLeft('৳ ' + deliveryCharge)}`}
            />
            <Typography
                variant="subtitle1"
                align='right'
                className={classes.text}
                title={`Total: ${padLeft('৳ ' + (totAmount + deliveryCharge - discount))}`}
            />
            <div
                title="Apply promo"
                align="center"
            >
                <Button
                    size="small"
                    color="primary"
                    className={
                        clsx(
                            classes.promo,
                            {
                                [classes.hide]: isEmpty(activePromo) || promoCode
                            }
                        )
                    }
                    onClick={
                        () => dispatch(applyPromoToCart(activePromo.promo.code))
                    }
                >
                    Apply promo
                </Button>
                <Button
                    component={Link}
                    to={{
                        pathname: "/userprofile",
                        state: true
                    }}
                    className={
                        clsx({
                            [classes.hide]: !isEmpty(activePromo) || promoCode
                        })
                    }
                >
                    Add promo
                </Button>
            </div>
            <div
                title="Click to Checkout"
                align='center'
            >
                <Link
                    to='/checkout'
                >
                    <Button
                        color="primary"
                        size="medium"
                        className={classes.button}
                        buttonText="Proceed to checkout"
                    />
                </Link>
            </div>
        </Paper>
    );
};

CartPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CartPage);