import {
    REGISTER,
} from '../../../consts/user/Register';
import {
    apiAction, 
    thunk
} from '../Helper';
import {
    login
} from './Login';
import {
    appendNotification
} from '../Notifications';

export const register = ({
    firstName,
    lastName,
    password,
    userName,
    phone,
    address,
    dateofbirth,
    gender,
    email,
}) => apiAction(
    {
        url: '/api/user',
        method: 'POST',
        onSuccess: thunk(
            [
                login,
                data => ({
                    type: REGISTER,
                    data
                }),
                () => appendNotification({
                    message: 'Registered successfully.',
                    variant: 'success',
                    autoHideDuration: 10000
                })
            ]
        ),
        onFailure: () => appendNotification({
            message: 'Failed to register. Please try again.',
            variant: 'error'
        }),
        data: {
            first_name: firstName,
            user_name: userName,
            name: firstName + " " + lastName,
            last_name: lastName,
            password,
            email,
            phone,
            address: [address],
            date_of_birth: dateofbirth,
            gender
        }
    }
);