import {
    FORGET_PASSWORD,
    RESET_PASSWORD
} from '../../consts/ResetPassword';
import {
    apiAction, thunk
} from './Helper';
import {
    appendNotification
} from './Notifications';

export const forgetPassword = email => apiAction({
    url: '/api/user/resetemail',
    method: 'POST',
    data: {
        email
    },
    onSuccess: () => appendNotification({
        message: 'Check your mails for password reset link.',
        variant: 'success'
    }),
    onFailure: () => appendNotification({
        message: 'Failed to submit request. Please try again.',
        variant: 'error'
    }),
    label: FORGET_PASSWORD
});

export const resetPassword = ({
    id,
    password,
    history
}) => apiAction({
    url: '/api/user/resetpassword/' + id,
    method: 'POST',
    data: {
        password
    },
    onSuccess: thunk([
        () => appendNotification({
            message: 'Password reset was successful.',
            variant: 'success'
        }),
        () => {
            history.push('/login');

            console.log(JSON.stringify(window.location));

            return {
                type: '....'
            };
        }
    ]),
    onFailure: () => appendNotification({
        message: 'Failed to reset password. Please try again.',
        variant: 'error'
    }),
    label: RESET_PASSWORD
});