import React from 'react';
import {
    makeStyles,
    Slider,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        color: theme.palette.primary.dark,
        '& .MuiAccordionDetails-root': {
            flexDirection: 'column',
            padding: 0
        },
        '& .MuiAccordionSummary-root': {
            minHeight: '38px',
            maxHeight: '38px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        '& .MuiIconButton-root': {
            color: theme.palette.common.white
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        },
    },
    textfield: {
        width: '40%',
        margin: '10px 10px'
    },
    slider: {
        width: '80%',
        margin: '40px auto 20px auto',
        // '& .MuiSlider-thumb': {
        //     backgroundColor: theme.palette.secondary.light
        // }
    },
    typo: {
        fontSize: '0.875rem',
        fontWeight: 500
    }
}));

function valuetext(value) {
    return `${value}`;
};

export default function SimpleAccordion({
    value,
    handleChange,
    max, min
}) {
    const classes = useStyles();
    const marks = [
        {
            value: min,
            label: `৳ ${min}`,
        },
        {
            value: max,
            label: `৳ ${max}`,
        }
    ];

    return (
        <Accordion
            className={classes.root}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography
                    id="range-slider"
                    color='inherit'
                    gutterBottom
                    className={classes.typo}
                >
                    Price Range
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Slider
                    max={max}
                    min={min}
                    value={value}
                    onChange={handleChange}
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    marks={marks}
                    valueLabelDisplay="on"
                    className={classes.slider}
                />
                <div
                    width='100%'
                    align='center'
                >
                    <TextField
                        className={classes.textfield}
                        id="input-with-icon-textfield"
                        label="From"
                        type="number"
                        value={value[0]}
                        // onChange={handleChange(0)}
                        InputProps={{
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    ৳
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className={classes.textfield}
                        id="input-with-icon-textfield"
                        label="To"
                        type="number"
                        value={value[1]}
                        // onChange={handleChange(1)}
                        InputProps={{
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    ৳
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                {/* <div
                    align='center'
                    width='100%'
                >
                    <Button>
                        Set Price Range
                    </Button>
                </div> */}
            </AccordionDetails>
        </Accordion>

    );
}
