export const data = [
    {
        label: 'Quantity',
        keyval: 'Quantity',
        placeholder: "Quantity"
    }
];

export const emptyState = {
    id: '',
    productName: '',
    Quantity: '',
};