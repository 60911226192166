import React, {
    useState,
    Fragment
} from 'react';
import {
    connect
} from 'react-redux';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import LoginRegisterDialog from '../loginRegisterDialog';
import {
    Button
} from '../../commonComponents';
import * as rootReducer from '../../redux/reducers';
import {
    addToCart
} from '../../redux/actions/cart';

const AddToCartButton = ({
    classes,
    variant,
    loggedIn,
    quantity,
    text,
    id,
    price,
    productType,
    deliveryCharge,
    isFetching,
    addToCart,
    stockOut,
    ...props
}) => {
    const [
        open,
        setOpen
    ] = useState(false);

    const handleClickDetail = (
        quantity,
        loggedIn
    ) => {
        if (loggedIn) return;
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    };

    const handleClickOpen = loggedIn => {
        setOpen(loggedIn);
    };

    const handleClose = value => {
        setOpen(value);
    };

    return (
        <Fragment>
            <Button
                variant={variant}
                {...props}
                disabled={isFetching || stockOut}
                title="Add to cart"
                onClick={
                    event => {
                        event.preventDefault();
                        if (!loggedIn) {
                            addToCart({
                                id,
                                price,
                                deliveryCharge,
                                quantity,
                                productType
                            });
                        }
                        handleClickDetail(quantity, loggedIn);
                        handleClickOpen(loggedIn);
                    }
                }
                Icon={AddShoppingCart}
                buttonText={text}
            />
            {/* <AddShoppingCart />
                {text ? text : ""}
            </Button> */}
            <LoginRegisterDialog
                op={open}
                onClick={handleClose}
            />
        </Fragment>
    );
};

const mapStateToProps = state => ({
    isFetching: rootReducer.getCartIsFetching(state),
    loggedIn: rootReducer.getAuth(state).login ? 0 : 1
});

const mapDispatchToProps = {
    addToCart: addToCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartButton);