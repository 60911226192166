import React, {
    useState
} from 'react';
import {
    useHistory,
    useLocation
} from 'react-router-dom';
import {
    useDispatch
} from 'react-redux';
import {
    makeStyles,
    Collapse,
    Grid
} from '@material-ui/core';
import {
    Alert
} from '@material-ui/lab';
import {
    Typography,
    Button,
    Textfield
} from '../../commonComponents';
import {
    resetPassword
} from '../../redux/actions/ResetPassword';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '90vh',
        margin: '105px auto 0px auto',
    },
    inner: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.dark
    },
    input: {
        width: '70%',
        margin: theme.spacing(1),
    },
    alert: {
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.primary.dark}`,
        margin: theme.spacing(1),
        width: '90%'
    }
}));

const useQuery = () => new URLSearchParams(useLocation().search);

export default ({
    ...props
}) => {
    const classes = useStyles();

    const query = useQuery();
    let history = useHistory();

    const [
        passwords,
        setPasswords
    ] = useState(['', '']);

    const onChange = key => ({ target: { value } }) => {
        setPasswords(passwords.reduce((prev, cur, i) => i === key ? [...prev, value] : [...prev, cur], []));
    }

    const passwordMatch = passwords[0] === passwords[1];
    const lengthCheck = passwords[0].length > 7 && passwords[1].length > 7;
    const disabled = !passwordMatch || !lengthCheck;

    const alertData = [
        {
            in: !lengthCheck,
            severity: 'warning',
            message: 'Password length should be at least 8.'
        },
        {
            in: !passwordMatch,
            severity: 'warning',
            message: 'Passwords should match.'
        },
        {
            in: !disabled,
            severity: 'success',
            message: 'All set. Good to go.'
        }
    ];

    const dispatch = useDispatch();

    const submit = () => {
        dispatch(resetPassword({
            id: query.get('key'),
            password: passwords[0],
            history
        }));
        setPasswords(['', '']);
    };

    return (
        <Grid
            container
            justify="center"
            alignItems="flex-start"
            className={classes.root}
        >
            <Grid
                item
                sm={6}
                md={6}
                xs={12}
                container
                direction="column"
                justify="space-around"
                alignItems="center"
                className={classes.inner}
            >
                <Typography
                    variant="h6"
                    align="center"
                    title='Reset Your Password'
                />
                {
                    alertData.map(
                        ({
                            in: In,
                            severity,
                            message
                        }, index) => (
                                <Collapse
                                    key={index}
                                    in={In}
                                >
                                    <Alert
                                        severity={severity}
                                        className={classes.alert}
                                    >
                                        {message}
                                    </Alert>
                                </Collapse>
                            )
                    )
                }
                <Textfield
                    value={passwords[0]}
                    onChange={
                        onChange(0)
                    }
                    variant="outlined"
                    keyval="NewPassword"
                    label="New Password"
                    type="password"
                    placeholder="New Password"
                    className={classes.input}
                />

                <Textfield
                    value={passwords[1]}
                    onChange={
                        onChange(1)
                    }
                    variant="outlined"
                    type="password"
                    keyval='ConfirmPassword'
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    className={classes.input}
                />
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <Button
                        size="small"
                        disabled={disabled}
                        buttonText='submit'
                        onClick={submit}
                    />
                    <Button
                        size="small"
                        buttonText='cancel'
                        onClick={() => setPasswords(['', ''])}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};