import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        //maxWidth: 500,
        minHeight: '100px',
        backgroundColor: '#f1f8e9'
    },
    divider: {
        width: '70%',
        margin: '8px auto'
    }
}));

export default function ComplexGrid() {
    const classes = useStyles();

    return (
        <Paper 
            elevation={0} 
            className={classes.paper}
        >
            {[0, 1, 2].map(value => (
                <Grid 
                    key={value} 
                    container 
                    alignItems='center' 
                    justify='center' 
                    spacing={2}
                >
                    <Grid 
                        item 
                        xs={12} 
                        sm={6}
                    >
                        <Typography 
                            gutterBottom 
                            variant="subtitle1"
                        >
                            Customer Name
                        </Typography>
                        <Typography 
                            variant="body2" 
                            gutterBottom
                        >
                            Great Service! Great Service! Great Service! Great Service!
                        </Typography>
                    </Grid>
                    <Grid 
                        item 
                        xs={12} 
                        sm={2}
                    >
                        <Typography 
                            variant="subtitle1" 
                        >
                            Posted On 29.6.2019
                        </Typography>
                    </Grid>
                    <Divider className={classes.divider} />
                </Grid>
            ))}
        </Paper>
    );
}