import React from 'react';
import Layout from './CommonLayout';

export default props => (
    <Layout
        buttonText="login"
        buttonAction={
            () => ''
        }
        buttonHref="/login"
        {...props}
    />
);