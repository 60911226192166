import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
    PDFViewer,
    PDFDownloadLink
} from '@react-pdf/renderer';
import Icon from '../../assets/icon1.png';

function ccyFormat(num) {
    return `${parseFloat(num).toFixed(2)}`;
}

const styles = StyleSheet.create(
    {
        image: {
            marginVertical: 15,
            marginHorizontal: 100,
            width: 48,
            height: 'auto',
            alignSelf: 'center'
        },
        cart: {
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            justifyContent: 'space-between',
            marginLeft: '5%',
            marginRight: '5%',
        },
        divider: {
            width: '95%',
            margin: '1% 2.5% 1% 2.5%',
            height: 2,
            backgroundColor: '#000000'
        },
        gap: {
            width: '100%',
            height: 30,
        },
        text: {
            marginHorizontal: 8,
            marginVertical: 4,
            fontSize: 13,
        },
        name: {
            textAlign: 'left',
            width: '30%',
            fontSize: 12,
        },
        qty: {
            fontSize: 10,
            width: '23%',
            textAlign: 'right'
        },
        subhead: {
            fontSize: 10,
            width: '20%',
            textAlign: 'left'
        }
    }
);

export default props => {
    if (!props.order) {
        return (
            <p>
                Invoice generating
            </p>
        );
    }

    return (
        <>
            <PDFViewer
                width="100%"
                height="600px"
            >
                <Document>
                    <Page
                        size="A4"
                    >
                        <View>
                            <Image
                                style={styles.image}
                                src={Icon}
                                allowDangerousPaths
                            />
                            <Text
                                style={styles.text}
                            >
                                Name: {[props.order.firstName, props.order.lastName].join(' ')}
                            </Text>
                            <Text
                                style={styles.text}
                            >
                                Adress: {props.order.address}
                            </Text>
                            <Text
                                style={styles.text}
                            >
                                Contact No.: {props.order.contactNumber}
                            </Text>
                            <Text
                                style={styles.text}
                            >
                                Order Number: {props.order.orderNumber}
                            </Text>
                            <Text
                                style={styles.text}
                            >
                                Date: {props.order.created_at}
                            </Text>
                        </View>
                        <View
                            style={styles.gap}
                        >

                        </View>
                        <View
                            style={styles.cart}
                        >
                            <Text
                                style={styles.name}
                            >
                                Item
                            </Text>
                            <Text
                                style={styles.qty}
                            >
                                Qty
                            </Text>
                            <Text
                                style={styles.qty}
                            >
                                Price
                            </Text>
                            <Text
                                style={styles.qty}
                            >
                                Delivery Charge
                            </Text>
                        </View>
                        {
                            props.cart.data.map(
                                (d, i) => (
                                    <View
                                        style={styles.cart}
                                        key={i}
                                    >
                                        <Text
                                            style={styles.name}
                                        >
                                            {i + 1}. {d.name}
                                        </Text>
                                        <Text
                                            style={styles.qty}
                                        >
                                            {d.quantity}
                                        </Text>
                                        <Text
                                            style={styles.qty}
                                        >
                                            {ccyFormat(d.totAmount - d.quantity * d.deliveryCharge)}
                                        </Text>
                                        <Text
                                            style={styles.qty}
                                        >
                                            {ccyFormat(d.quantity * d.deliveryCharge)}
                                        </Text>
                                    </View>
                                )
                            )
                        }

                        <View
                            style={styles.divider}
                        >
                        </View>
                        <View
                            style={styles.cart}
                        >
                            <Text
                                style={styles.name}
                            >
                            </Text>
                            <Text
                                style={styles.subhead}
                            >
                                Sub Total (+)
                            </Text>
                            <Text
                                style={styles.qty}
                            >
                                {ccyFormat(props.cart.totAmount)}
                            </Text>
                        </View>
                        <View
                            style={styles.cart}
                        >
                            <Text
                                style={styles.name}
                            >
                            </Text>
                            <Text
                                style={styles.subhead}
                            >
                                Discount (-)
                            </Text>
                            <Text
                                style={styles.qty}
                            >
                                {ccyFormat(props.cart.discount)}
                            </Text>
                        </View>
                        <View
                            style={styles.cart}
                        >
                            <Text
                                style={styles.name}
                            >
                            </Text>
                            <Text
                                style={styles.subhead}
                            >
                                Delivery Charge (+)
                            </Text>
                            <Text
                                style={styles.qty}
                            >
                                {ccyFormat(props.cart.deliveryCharge)}
                            </Text>
                        </View>
                        <View
                            style={styles.divider}
                        >
                        </View>
                        <View
                            style={styles.cart}
                        >
                            <Text
                                style={styles.name}
                            >

                            </Text>
                            <Text
                                style={styles.subhead}
                            >
                                Total (in Taka)
                            </Text>
                            <Text
                                style={styles.qty}
                            >
                                {ccyFormat(props.cart.totAmount + props.cart.deliveryCharge - props.cart.discount)}
                            </Text>
                        </View>
                        <View
                            style={styles.divider}
                        >
                        </View>
                        <View
                            style={styles.gap}
                        >

                        </View>
                        <View
                            style={styles.cart}
                        >
                            <Text
                                style={styles.text}
                            >
                                Cashier's Signature
                            </Text>
                            <Text>

                            </Text>
                            <Text
                                style={styles.text}
                            >
                                Customer's Signature
                            </Text>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
            <PDFDownloadLink
                document={
                    <Document>
                        <Page
                            size="A4"
                        >
                            <View>
                                <Image
                                    style={styles.image}
                                    src={Icon}
                                    allowDangerousPaths
                                />
                                <Text
                                    style={styles.text}
                                >
                                    Name: {[props.order.firstName, props.order.lastName].join(' ')}
                                </Text>
                                <Text
                                    style={styles.text}
                                >
                                    Adress: {props.order.address}
                                </Text>
                                <Text
                                    style={styles.text}
                                >
                                    Contact No.: {props.order.contactNumber}
                                </Text>
                                <Text
                                    style={styles.text}
                                >
                                    Order Number: {props.order.orderNumber}
                                </Text>
                                <Text
                                    style={styles.text}
                                >
                                    Date: {props.order.created_at}
                                </Text>
                            </View>
                            <View
                                style={styles.gap}
                            >

                            </View>
                            <View
                                style={styles.cart}
                            >
                                <Text
                                    style={styles.name}
                                >
                                    Item
                                </Text>
                                <Text
                                    style={styles.qty}
                                >
                                    Qty
                                </Text>
                                <Text
                                    style={styles.qty}
                                >
                                    Price
                                </Text>
                                <Text
                                    style={styles.qty}
                                >
                                    Delivery Charge
                                </Text>
                            </View>
                            {
                                props.cart.data.map(
                                    (d, i) => (
                                        <View
                                            style={styles.cart}
                                            key={i}
                                        >
                                            <Text
                                                style={styles.name}
                                            >
                                                {i + 1}. {d.name}
                                            </Text>
                                            <Text
                                                style={styles.qty}
                                            >
                                                {d.quantity}
                                            </Text>
                                            <Text
                                                style={styles.qty}
                                            >
                                                {ccyFormat(d.totAmount - d.quantity * d.deliveryCharge)}
                                            </Text>
                                            <Text
                                                style={styles.qty}
                                            >
                                                {ccyFormat(d.quantity * d.deliveryCharge)}
                                            </Text>
                                        </View>
                                    )
                                )
                            }

                            <View
                                style={styles.divider}
                            >
                            </View>
                            <View
                                style={styles.cart}
                            >
                                <Text
                                    style={styles.name}
                                >
                                </Text>
                                <Text
                                    style={styles.subhead}
                                >
                                    Sub Total (+)
                                </Text>
                                <Text
                                    style={styles.qty}
                                >
                                    {ccyFormat(props.cart.totAmount)}
                                </Text>
                            </View>
                            <View
                                style={styles.cart}
                            >
                                <Text
                                    style={styles.name}
                                >
                                </Text>
                                <Text
                                    style={styles.subhead}
                                >
                                    Discount (-)
                                </Text>
                                <Text
                                    style={styles.qty}
                                >
                                    {ccyFormat(props.cart.discount)}
                                </Text>
                            </View>
                            <View
                                style={styles.cart}
                            >
                                <Text
                                    style={styles.name}
                                >
                                </Text>
                                <Text
                                    style={styles.subhead}
                                >
                                    Delivery Charge (+)
                                </Text>
                                <Text
                                    style={styles.qty}
                                >
                                    {ccyFormat(props.cart.deliveryCharge)}
                                </Text>
                            </View>
                            <View
                                style={styles.divider}
                            >
                            </View>
                            <View
                                style={styles.cart}
                            >
                                <Text
                                    style={styles.name}
                                >

                                </Text>
                                <Text
                                    style={styles.subhead}
                                >
                                    Total (in Taka)
                                </Text>
                                <Text
                                    style={styles.qty}
                                >
                                    {ccyFormat(props.cart.totAmount + props.cart.deliveryCharge - props.cart.discount)}
                                </Text>
                            </View>
                            <View
                                style={styles.divider}
                            >
                            </View>
                            <View
                                style={styles.gap}
                            >

                            </View>
                            <View
                                style={styles.cart}
                            >
                                <Text
                                    style={styles.text}
                                >
                                    Cashier's Signature
                                </Text>
                                <Text>

                                </Text>
                                <Text
                                    style={styles.text}
                                >
                                    Customer's Signature
                                </Text>
                            </View>
                        </Page>
                    </Document>
                }
                fileName="Gaachwala Invoice.pdf"
                style={{
                    textDecoration: "none",
                    padding: "10px",
                    color: "#4a4a4a",
                    backgroundColor: "#f2f2f2",
                    border: "1px solid #4a4a4a"
                }}
            >
                {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download Invoice"
                }
            </PDFDownloadLink>
        </>
    );
};
