import React, {
    Fragment
} from 'react';
import {
    ListItemText
} from '@material-ui/core';
import {
    toDateString
} from '../utils/date';

export default ({
    promo,
    userLimit
}) => {
    return (
        <ListItemText
            primary={promo.code}
            secondaryTypographyProps={{
                component: 'div'
            }}
            secondary={
                <Fragment>
                    <p>
                        Discount: {promo.discount}% with maximum discount of tk {promo.maxDiscount}.
                    </p>
                    <p>
                        This promo can be used at most {userLimit} time{userLimit > 1 ? 's' : ''} by any single user.
                    </p>
                    <p>
                        ** Minimum purchase of tk {promo.minPurchase} is required to avail this promo.
                    </p>
                    <p>
                        Valid till {toDateString(promo.validity)}.
                    </p>
                </Fragment>
            }
        />
    );
};