import React from 'react';
import { 
	connect 
} from 'react-redux';
import { 
	makeStyles,
	List,
	ListItem,
	ListItemText
} from '@material-ui/core';
import {
	Typography
} from '../../commonComponents';
import {
	getCartTable, 
} from 'redux/reducers';

const useStyles = makeStyles(
	theme => (
		{
			listItem: {
				padding: theme.spacing(1, 0),
			},
			total: {
				fontWeight: '700',
			},
			title: {
				marginTop: theme.spacing(2),
			},
		}
	)
);

const Review = ({
    cart: {
		data, 
		totAmount, 
		deliveryCharge,
		discount
	},
}) => {
	const classes = useStyles();

	return (
		<List 
			disablePadding
		>
			{
				data.map(
					product => (
						<ListItem 
							className={classes.listItem} 
							key={product.name}
						>
							<ListItemText 
								primary={product.name} 
								secondary={'quantity: ' + product.quantity} 
							/>
							<Typography 
								variant="body2"
								title={`৳ ${product.totAmount}`}
							/>
						</ListItem>
					)
				)
			}
			<ListItem 
				className={classes.listItem}
			>
				<ListItemText 
					primary="Subtotal (+)" 
				/>
				<Typography 
					variant="subtitle1" 
					className={classes.total}
					title={`৳ ${totAmount}`}
				/>
			</ListItem>
			<ListItem 
				className={classes.listItem}
			>
				<ListItemText 
					primary="Discount (-)" 
				/>
				<Typography 
					variant="subtitle1" 
					className={classes.total}
					title={`৳ ${discount}`}
				/>
			</ListItem>
			<ListItem 
				className={classes.listItem}
			>
				<ListItemText 
					primary="Delivery charge (+)" 
				/>
				<Typography 
					variant="subtitle1" 
					className={classes.total}
					title={`৳ ${deliveryCharge}`}
				/>
			</ListItem>
			<ListItem 
				className={classes.listItem}
			>
				<ListItemText 
					primary="Total" 
				/>
				<Typography 
					variant="subtitle1" 
					className={classes.total}
					title={`৳ ${totAmount + deliveryCharge - discount}`}
				/>
			</ListItem>
		</List>
	);
};

const mapStateToProps = state => ({
    cart: getCartTable(state),
});

export default connect(mapStateToProps)(Review);