import React, { 
	useState 
} from 'react';
import {
	Tabs,
	Tab,
	withStyles
} from '@material-ui/core';
import TabContainer from './TabContainer';

const styles = theme => (
	{
		root: {
			flexGrow: 1,
			//backgroundColor: theme.palette.background.paper,
			width: '100%',
			color: theme.palette.secondary.dark,
			margin: '0 auto',
			'&:focus': {
				opacity: 1,
			},
			// '&$selected': {
			//     color: '#01579b',
			// },
		},
		selected: {
			fontWeight: theme.typography.fontWeightBold,
		}
	}
);

const TabsWrappedLabel = ({
	classes,
	description,
	light,
	water,
	benefits
}) => {

	const [
		value, 
		setValue
	] = useState(0);
		
	return (
		<div 
			className={classes.root}
		>
			<Tabs 
				value={value} 
				onChange={
					(e, v) => {						
						setValue(v);
					}
				}
				centered
				indicatorColor="primary"
				textColor="inherit"
			>
				<Tab 
					value={0} 
					title="Description" 
					label="Description" 
					// classes={classes.selected}
				/>
				{
					(water !== "" || light !== "")
					&&
					<Tab 
						value={1} 
						title="Care" 
						label="Care" 
						// classes={classes.selected}
					/>
				}
				{
					benefits !== ""
					&&
					<Tab 
						value={2} 
						title={`Benefits & Uses`} 
						label={`Benefits & Uses`} 
						// classes={classes.selected}
					/>
				}
			</Tabs>
			{
				value === 0 
				&& 
				<TabContainer 
					value={value} 
					l="" 
					w=""
				>
					{description}
				</TabContainer>
			}
			{
				value === 1 
				&& 
				<TabContainer 
					l={light} 
					w={water} 
					value={value}
				/>
			}
			{
				value === 2 
				&& 
				<TabContainer 
					value={value} 
					l="" 
					w=""
				>
					{benefits}
				</TabContainer>
			}
		</div>
	);
};

export default withStyles(styles)(TabsWrappedLabel);