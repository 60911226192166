import {
    combineReducers
} from 'redux';

const actions = {
    UPDATE_SHIPPING_ADDRESS: 'UPDATE_SHIPPING_ADDRESS',
    UPDATE_PAYMENT_DETAILS: 'UPDATE_PAYMENT_DETAILS',
};

const shippingAddress = (
    state = {}, 
    action
) => {
    switch(action.type) {
        case actions.UPDATE_SHIPPING_ADDRESS:
            return action.payload;
        default:
            return state;
    }
};

const paymentDetails = (
    state = {}, 
    action
) => {
    switch(action.type) {
        case actions.UPDATE_PAYMENT_DETAILS:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    shippingAddress,
    paymentDetails,
});

export const updateShippingAddress = payload => ({
    type: actions.UPDATE_SHIPPING_ADDRESS,
    payload,
});

export const updatePaymentDetails = payload => ({
    type: actions.UPDATE_PAYMENT_DETAILS,
    payload,
});

export const getShippingAddress = state => state.shippingAddress;

export const getPaymentDetails = state => state.paymentDetails;