import {
    FETCH, 
    FETCH_PRODUCT_COUNT
} from '../../../consts/product/Fetch';
import {
    DELETE
} from '../../../consts/product/Delete';

export default (
    state = {}, 
    action
) => {
    switch(action.type) {
        case FETCH:
            return {
                ...state,
                [action.id]: action.data
            };
        case DELETE:
            const nextState = state;
            delete nextState[action.id];
            return nextState;
        case FETCH_PRODUCT_COUNT: 
            return {
                ...state,
                count: action.data.count
            }
        default:
            return state;
    }
};

export const getProduct = (state, id) => (
    state.hasOwnProperty(id) 
    && 
    state[id]
);

export const getProductCount = state => state.count;