import React from 'react';
import { IconButton } from '@material-ui/core';

export default React.forwardRef(({
    Icon,
    style,
    ...props
}, ref) => (
    <IconButton
        color='primary'
        ref={ref}
        {...props}
    >
        <Icon 
            style={style}
        />
    </IconButton> 
));