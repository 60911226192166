import {
    createStore,
    applyMiddleware,
    compose,
} from 'redux';
import {
    createLogger
} from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import api from './middleware/Api';
import reducers from './reducers';
import {
    loadState
} from '../services/LocalStorage';

const initialState = loadState();
const logger = createLogger();

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const middlewares = () => {
    let m = [api, promise, thunk];

    if (process.env.NODE_ENV === 'development') m = [...m, logger];

    return m;
};

export default createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares()))
);