import React, {
    Fragment,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles
} from '@material-ui/core';
import {
    Button,
    Textfield
} from '../../commonComponents';
import {
    profileInformationFields
} from './Data';
import Logo from '../logo/index';
import {
    getProfile
} from '../../redux/reducers';
import {
    updateProfileInformation
} from '../../redux/actions/user/Update';

const useStyles = makeStyles(theme => ({
    inner: {
        // padding: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main
    },
    input: {
        width: '90%',
        margin: theme.spacing(1),
    },
    title: {
        textAlign: 'center',
        color: theme.palette.primary.main
    },
    dialogAction: {
        margin: '0 auto'
    },
    dialogContent: {
        padding: 0
    },
    button: {
        [theme.breakpoints.down('sm')]: {
			textTransform: 'capitalize',
		},
    }
}));

const EditProfileInformation = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [state, setState] = useState(useSelector(getProfile));

    const onChange = key => ({
        target: {
            value
        }
    }) => setState({
        ...state,
        [key]: value
    });

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const onClick = () => {
        dispatch(updateProfileInformation(state));

        handleClose();
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <Logo />
                <DialogTitle
                    className={classes.title}
                >
                    Edit Profile Information
                </DialogTitle>
                <DialogContent>
                    {
                        profileInformationFields.map(
                            (field, index) => (
                                <Textfield 
                                    key={index}
                                    value={state[field.key]}
                                    onChange={onChange(field.key)} 
                                    label={field.label}
                                    placeholder={field.placeholder} 
                                    className={classes.input} 
                                />
                            )
                        )
                    }
                </DialogContent>
                <DialogActions
                    className={classes.dialogAction}
                >
                    <Button
                        onClick={onClick}
                        buttonText='Update'
                        color='primary'
                        className={classes.button}
                    />
                    <Button
                        onClick={handleClose}
                        color='primary'
                        className={classes.button}
                        buttonText='cancel'
                    />
                </DialogActions>
            </Dialog>
            <Button
                onClick={handleOpen}
                color='primary'
                variant='text'
                buttonText='Edit Profile Information'
                className={classes.button}
            /> 
        </Fragment>
    )
};

export default EditProfileInformation;