import React, {
    useRef,
    useState,
    useEffect
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import clsx from 'clsx';
import { 
    Grid,
    makeStyles,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Typography,
    Textfield,
    Divider,
    DeleteConfirmation,
    Button
} from '../../commonComponents';
import {
    readBySlug
} from '../../redux/actions/staticContent/Read';
import {
    update
} from '../../redux/actions/staticContent/Update';
import {
    getAuth,
    getStaticContent
} from '../../redux/reducers';
import Logo from '../logo';
import Icon from '../../assets/icon.png';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '80%',
                minHeight: '80vh',
                height: 'auto',
                margin: '120px auto',
                color: theme.palette.info.main,
                [theme.breakpoints.down('sm')]: {
                    margin: '50px auto',
                    width: '95%'
                },
            },
            heading: {
                fontSize: theme.typography.pxToRem(16),
                fontWeight: theme.typography.fontWeightRegular,
            },
            img: {
                verticalAlign: 'middle',
                paddingRight: '10px'
            },
            expand: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                width: '100%',
                height: 'auto',
                padding: theme.spacing(1),
                paddingLeft: theme.spacing(4),
                margin: '0 auto',
                [theme.breakpoints.down('sm')]: {
                    margin: 0,
                    paddingLeft: theme.spacing(2),
                },
            },
            divider: {
                width: '75%',
                margin: '15px auto'
            },
            box: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                height: 'auto'
            },
            edit: {
                width: '100%',
                margin: theme.spacing(1)
            },
            hide: {
                display: 'none'
            },
            addQA: {
                margin: '0 auto'
            }
        }
    )
);

const SLUG = 'faq';

export default () => {
    const classes = useStyles();
    const confirmDeleteData = useRef({
        name: '',
        remove: () => {}
    });
    const [view, setView] = useState(true);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const admin = Boolean(useSelector(getAuth).admin);
    const data = useSelector(state => getStaticContent(state, SLUG));
    const [faq, setFAQ] = useState(JSON.parse(data.content[0]));

    useEffect(
        () => {
            dispatch(readBySlug(SLUG));
        },
        [
            dispatch
        ]
    );

    useEffect(
        () => {
            setFAQ(JSON.parse(data.content[0]));
        },
        [
            setFAQ,
            data.content
        ]
    )

    const addSection = () => {
        setFAQ({
            ...faq,
            "": [],
            sections: [
                ...faq.sections,
                ''
            ]
        });
    };

    const addQA = key => setFAQ({
        ...faq,
        [key]: [
            ...faq[key],
            {
                Q: '',
                A: ''
            }
        ]
    });

    const onChangeSection = (name, index) => ({target: {value}}) => {
        const next = {
            [value]: faq[name],
            ...faq,
            sections: [
                ...faq.sections.slice(0, index),
                value,
                ...faq.sections.slice(index+1)
            ]
        };

        delete next[name];

        setFAQ(next);
    };



    const onChangeQA = QA => (key, index) => ({target: {value}}) => setFAQ({
        ...faq,
        [key]: [
            ...faq[key].slice(0, index),
            {
                ...faq[key][index],
                [QA]: value
            },
            ...faq[key].slice(index+1)
        ]
    });

    const onChangeQuestion = onChangeQA('Q');
    const onChangeAnswer = onChangeQA('A');

    const removeSection = key => {
        const next = {
            ...faq,
            sections: faq.sections.filter(s => s !== key)
        };

        delete next[key];

        setFAQ(next);
    };

    const removeQA = (key, index) => setFAQ({
        ...faq,
        [key]: [
            ...faq[key].slice(0, index),
            ...faq[key].slice(index+1)
        ]
    });

    const renderQuestion = (q, key, index) => (
        view
        ?
        <Typography
            variant='body2'
            align='left'
            boldText={q}
        />
        :
        <Textfield 
            value={q}
            multiline
            variant="filled"
            label="Question"
            className={classes.edit}
            onChange={onChangeQuestion(key, index)}
        />
    );
            
    const renderAnswer = (a, key, index) => (
        view
        ?
        <Typography
            variant='body2'
            align='left'
            gutterBottom
            title={a}
            color='inherit'
        />
        :
        <Textfield
            variant="filled"
            value={a}
            multiline
            label="Answer"
            className={classes.edit}
            onChange={onChangeAnswer(key, index)}
        />
    );

    const renderSectionName = (name, index) => (
        view
        ?
        <Typography
            className={classes.heading}
            title={name}
        />
        :
        <Textfield
            value={name}
            variant="filled"
            label="Section name"
            className={classes.edit}
            onChange={onChangeSection(name, index)}
        />
    );

    if(!Object.keys(faq).length) {
        return 'loading ...';
    }

    return (
        <div 
            className={classes.root} 
            align='center'
        >
            <DeleteConfirmation 
                open={open}
                onClose={() => setOpen(false)}
                {...confirmDeleteData.current}
            />
            <Logo/>
            <Typography
                gutterBottom
                variant='h6'
                color='primary'
                title='Frequently Asked Questions'
            />
            <Button
                className={
                    clsx(
                        classes.addQA,
                        {
                            [classes.hide]: !admin || view
                        }
                    )
                }
                onClick={addSection}
            >
                Add another Section
            </Button>
            {
                faq.sections.map(
                    (s, index) => (
                        <ExpansionPanel 
                            // defaultExpanded={index === 0 ? true : false}
                            key={index} 
                            className={classes.box}
                        >
                            <ExpansionPanelSummary
                                aria-controls="FAQ"
                                id="FAQ-expansion-summary"
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <img 
                                    src={Icon} 
                                    alt="faq"
                                    width='30' 
                                    className={classes.img}
                                    height='30'
                                />
                                {renderSectionName(s, index)}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails 
                                className={classes.expand}
                            >
                                <Grid 
                                    container 
                                    alignItems="flex-start"
                                    className={classes.expand}
                                >
                                    <Button
                                        className={
                                            clsx(
                                                classes.addQA,
                                                {
                                                    [classes.hide]: !admin || view
                                                }
                                            )
                                        }
                                        onClick={() => {
                                            confirmDeleteData.current = {
                                                name: 'section: ' + s,
                                                remove: () => removeSection(s)
                                            }

                                            setOpen(true);
                                        }}
                                    >
                                        Remove this section
                                    </Button>
                                    <Button
                                        className={
                                            clsx(
                                                classes.addQA,
                                                {
                                                    [classes.hide]: !admin || view
                                                }
                                            )
                                        }
                                        onClick={() => addQA(s)}
                                    >
                                        Add another Q/A
                                    </Button>
                                    { 
                                        faq[s].map(
                                            (i, index) => (
                                                <Grid 
                                                    key={index}
                                                    item 
                                                    xs={12} 
                                                >
                                                    {renderQuestion(i.Q, s, index)}
                                                    {renderAnswer(i.A, s, index)}
                                                    <Button
                                                        className={
                                                            clsx(
                                                                classes.addQA,
                                                                {
                                                                    [classes.hide]: !admin || view
                                                                }
                                                            )
                                                        }
                                                        onClick={
                                                            () => {
                                                                confirmDeleteData.current = {
                                                                    name: 'Q/A',
                                                                    remove: () => removeQA(s, index)
                                                                }
                    
                                                                setOpen(true);
                                                            }
                                                        }
                                                    >
                                                        Remove this Q/A
                                                    </Button>
                                                    <Divider 
                                                        className={classes.divider} 
                                                    />
                                                </Grid>
                                            )
                                        )
                                    }
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                )
            }
            <Button
                className={
                    clsx({
                        [classes.hide]: !admin
                    })
                }
                onClick={
                    () => {
                        if(!view) {
                            dispatch(update({
                                slug: SLUG,
                                content: faq,
                                id: data.id
                            }));
                        }
                        setView(!view);
                    }
                }
            >
                {view ? 'Edit' : 'Save'}
            </Button>
            <Button
                className={
                    clsx({
                        [classes.hide]: !admin || view
                    })
                }
                onClick={
                    () => {
                        setView(true);
                        setFAQ(JSON.parse(data.content[0]));
                    }
                }
            >
                Cancel
            </Button>
        </div>
    );
};