import {
    UPDATE,
    UPDATE_ADMIN
} from '../../../consts/order/Update';
import {
    apiAction,
    thunk
} from '../Helper';
import {
    createCart
} from '../cart/Create';
import {
    checkoutCart
} from '../cart';
import {
    appendNotification
} from '../Notifications';

export const update = order => apiAction(
    {
        url: '/api/orderupdate/' + order.id,
        method: 'POST',
        data: {
            firstname: order.firstName,
            lastname: order.lastName,
            contactnumber: order.contactNumber,
            cart_id: order.cartId,
            address: order.address.split(','),
            customer_id: order.customerId,
            is_confirmed: true,
            is_processed: false,
            is_dropped: false
        },
        onSuccess: thunk(
            [
                data => ({
                    type: UPDATE,
                    data
                }),
                () => appendNotification({
                    message: 'Your order has been received.',
                    variant: 'success'
                }),
                async () => await checkoutCart(),
                data => createCart(data.customer_id)
            ]
        ),
        onFailure: () => appendNotification({
            message: 'Failed to place order, please try again.',
            variant: 'error'
        }),
        label: UPDATE
    }
);

export const process = (order, email) => apiAction({
    url: '/api/orderconfirm/' + order.id,
    method: 'POST',
    data: {
        is_processed: true,
        email
    },
    onSuccess: data => ({
        type: UPDATE_ADMIN,
        data: {
            ...order,
            ...data
        }
    }),
    label: UPDATE_ADMIN
});

// export const process = order => apiAction(
//     {
//         url: '/api/orderupdate/' + order.id,
//         method: 'POST',
//         data: {
//             ...order,
//             is_processed: true
//         },
//         label: UPDATE
//     }
// );

export const drop = order => apiAction(
    {
        url: '/api/orderupdate/' + order.id,
        method: 'POST',
        data: {
            ...order,
            is_dropped: true
        },
        label: UPDATE_ADMIN
    }
);

export const dropUndo = order => apiAction(
    {
        url: '/api/orderupdate/' + order.id,
        method: 'POST',
        data: {
            ...order,
            is_dropped: false
        },
        label: UPDATE_ADMIN
    }
);