import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import CreateInventory from './CreateInventory';
import UpdateInventory from './UpdateInventory';
import {
    Button
} from '../../commonComponents';
import {
    getInventories
} from '../../redux/reducers';
import {
    fetchAll
} from '../../redux/actions/inventory/Fetch';

const columns = [
    { id: 'name', label: 'Name' },
    { id: 'quantity', label: 'Quantity' },
    { id: 'buyPrice', label: 'Buy\u00a0Price' },
    { id: 'sellPrice', label: 'Sell\u00a0Price' },
];

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        '& .MuiTableCell-sizeSmall': {
            padding: '6px 2px 6px 2px'
        }
    },
    table: {
        minWidth: '100%',
        width: '100%',
        maxHeight: '240px'
    },
    style: {
        width: '100%',
        '& .MuiExpansionPanel-root': {
            minWidth: '100%'
        },
    },
    container: {
        maxHeight: 440,
    },
    tableHead: {
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.main
        }
    },
    buttonUpdate: {
        textTransform: 'capitalize'
    }
}));

const Inventory = ({
    data,
    fetchAll
}) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [
        state,
        setState
    ] = React.useState({
        id: '',
        productName: '',
        count: ''
    });

    useEffect(
        () => fetchAll(),
        [
            fetchAll
        ]
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = (id, name, counts) => {
        setState({
            id: id,
            productName: name,
            count: counts
        });
    }

    const handleUpdateClose = () => {
        setState({
            id: '',
            productName: '',
            count: ''
        });
    }

    return (
        <>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>

                    <Table size="small" stickyHeader aria-label="sticky table" className={classes.table}>
                        <TableHead
                            className={classes.tableHead}
                        >
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align='left'
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody
                            className={classes.style}
                        >
                            {
                                Array.isArray(data) ?
                                    data.map((row) => {
                                        return (
                                            <TableRow hover key={row.id}>
                                                <TableCell align='left'>
                                                    {row.product.name}
                                                    <Button
                                                        buttonText="Update"
                                                        onClick={() => { handleUpdate(row.id, row.product.name, row.count); }}
                                                        color='primary'
                                                        variant='text'
                                                        className={classes.buttonUpdate}
                                                    />
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {row.count}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {row.product.buyPrice}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {row.product.sellPrice}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    :
                                    null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <br />
            <div
                align='center'
            >
                <Button
                    variant='text'
                    buttonText="Create New Inventory"
                    onClick={handleClickOpen}
                    color='primary'
                />
            </div>

            <CreateInventory
                open={open}
                onClose={handleClose}
            />
            <UpdateInventory
                open={state.id === '' ? false : true}
                onClose={handleUpdateClose}
                id={state.id}
                name={state.productName}
                c={state.count}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    data: getInventories(state) || null,
});

export default connect(mapStateToProps, { fetchAll })(Inventory);
