import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@material-ui/core';
import {
	Button,
	Link
} from '../../commonComponents';

export default ({
	op,
	onClick,
}) => (
		<Dialog
			open={op}
			onClose={
				() => onClick(false)
			}
			aria-labelledby="login-or-register-dialog"
			aria-describedby="login-or-register-dialog-description"
		>
			<DialogTitle
				id="alert-dialog-title"
			>
				{"Please log in or register for placing your order"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText
					id="alert-dialog-description"
				>
					If you have already registered then please log in to continue your shopping
			</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					component={Link}
					variant='text'
					to="/login"
					onClick={
						() => onClick(false)
					}
					color="primary"
					buttonText='Log In'
				/>
				<Button
					component={Link}
					to="/register"
					variant='text'
					onClick={
						() => onClick(false)
					}
					color="primary"
					buttonText='register'
				// autoFocus
				/>
			</DialogActions>
		</Dialog>
	);