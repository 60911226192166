import {
    PhoneOutlined,
    EmailOutlined
} from '@material-ui/icons';

export const data = [
    {
        link: '/about-us',
        text: 'About Us'
    },
    {
        link: '/terms-and-conditions',
        text: 'Terms and Conditions'
    },
    {
        link: '/privacy-policy',
        text: 'Privacy Policy'
    },
    {
        link: '/refund-policy',
        text: 'Refund Policy'
    },
    {
        link: '/faq',
        text: 'FAQ'
    },
    {
        link: '/contactus',
        text: 'Contact Us'
    }
];

export const contact = [
    {
        text: 'Gaachwala',
        Icon: null,
        variant: 'h6',
    },
    {
        text: '01614885566',
        Icon: PhoneOutlined,
        variant: 'body1',
        slogan: 'Call Us At ',
        title: 'Feel Free To Call Us About Your Query'
    },
    {
        text: 'gaachwala@gmail.com',
        Icon: EmailOutlined,
        variant: 'body1',
        slogan: 'Email Us At ',
        title: 'Feel Free To Email Us About Your Query'
    }
];

export const developer = [
    {
        text: 'Developed By Aubichol Intelligent Technologies',
        Icon: null,
        variant: 'subtitle1',
    },
    {
        text: '01727192452',
        Icon: PhoneOutlined,
        variant: 'body1',
        slogan: 'Call Us At ',
        title: 'Feel Free To Call Us About Your Query'
    },
    {
        text: 'tarifezaz@aubichol.com',
        Icon: EmailOutlined,
        variant: 'body1',
        slogan: 'Email Us At ',
        title: 'Feel Free To Email Us About Your Query'
    }
]