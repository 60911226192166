import React, {
    Fragment,
    useEffect
} from 'react';
import { 
    useDispatch,
    useSelector
} from 'react-redux';
import {
    makeStyles,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
} from '@material-ui/core';
import {
    Link,
    Button,
    Divider,
    PromoListItemText
} from '../../commonComponents';
import { 
    readAll
} from '../../redux/actions/promo/admin/Read';
import { 
    remove
} from '../../redux/actions/promo/admin/Delete';
import {
    getPromoList
} from '../../redux/reducers';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
                maxWidth: 360,
                backgroundColor: theme.palette.background.paper,
            },
            inline: {
                display: 'inline',
            },
            list: {
                color: theme.palette.primary.dark,
            }
        }
    )
);

export default props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(readAll());
        }, [
            dispatch
        ]
    );

    const promos = useSelector(getPromoList);

    return (
        <List 
            className={classes.root}
        >
            {
                promos.map(
                    (promo, index) => (
                        <Fragment 
                            key={index}
                        >
                            {
                                index > 0 
                                ? 
                                <Divider 
                                    variant="inset" 
                                    component="li" 
                                /> 
                                : 
                                ""
                            }
                            <ListItem 
                                alignItems="flex-start" 
                                className={classes.list}
                            >
                                <ListItemAvatar>
                                    <Avatar 
                                        alt={promo.name} 
                                        // src={src} 
                                    />
                                </ListItemAvatar>
                                <PromoListItemText 
                                    promo={promo}
                                    userLimit={promo.userLimit}
                                />
                            </ListItem>
                            <Button
                                color="primary"
                                to={{
                                    pathname: '/adminpromoentry',
                                    state: promo.id
                                }}
                                component={Link}
                            >
                                Edit
                            </Button>
                            <Button
                                color='primary'
                                onClick={
                                    () => dispatch(remove(promo.id))
                                }
                                buttonText='delete'
                            />
                        </Fragment>
                    )
                )
            }
        </List>
    );
};