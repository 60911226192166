import React, {
	useEffect,
	useState,
} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import {
	useLocation,
	useParams
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';
import {
	withStyles,
	withWidth,
	GridList,
} from '@material-ui/core';
import Filter from './Filter';
import Divider from './Divider';
import Product from './Product';
import TitleImage from './TitleImage';
import Loading from '../loading';
import {
	Typography
} from '../../commonComponents';
import * as rootReducer from '../../redux/reducers';
import {
	addToCart
} from '../../redux/actions/cart';

const col = {
	'xs': 1,
	'sm': 2,
	'md': 3,
	'lg': 4,
	'xl': 6,
};

const styles = theme => (
	{
		root: {
			display: 'flex',
			justifyContent: 'center',
			overflow: 'hidden',
			width: '90%',
			height: 'auto',
			// minHeight: '90%',
			margin: '0 auto',
			marginTop: 87,
			backgroundColor: theme.palette.background.paper,
			marginBottom: 100,
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginTop: 50
			},
		},
		gridList: {
			width: '100%',
			height: '100%',
			color: theme.palette.secondary.dark,
		},
		div: {
			position: 'relative',
			width: '100%',
		},
	}
);

const useQuery = () => new URLSearchParams(useLocation().search);

const ProductArray = ({
	classes,
	...props
}) => {
	const dispatch = useDispatch();
	let query = useQuery();
	const s = query.get("q");

	const getFilteredProducts = (products, selectedCategory, selectedSort, priceRange) => {
		let filteredProducts = selectedCategory.length > 0
			?
			products.filter(
				product => product.categories.find(
					cat => selectedCategory.find(
						selected => selected.id === cat
					)
				)
			)
			:
			products;

		filteredProducts = filteredProducts.filter(
			i => (
				i.sellPrice >= priceRange[0]
				&&
				i.sellPrice <= priceRange[1]
			)
		);

		filteredProducts = selectedSort !== ''
			?
			(
				selectedSort.name === 'Price Low to High'
					?
					filteredProducts.sort(
						(a, b) => a.sellPrice < b.sellPrice ? -1 : 1
					)
					:
					filteredProducts.sort(
						(a, b) => a.sellPrice < b.sellPrice ? 1 : -1
					)
			) : filteredProducts;

		return filteredProducts;
	};

	const maxAndMinPrices = products => products.reduce(
		(prev, curr) => [
			Math.min(prev[0], curr.sellPrice),
			Math.max(prev[1], curr.sellPrice)
		],
		[Infinity, -Infinity]
	);

	const location = useLocation();
	const { state: categoryId } = location;
	const { category } = useParams();
	const isSearchPath = location.pathname === '/productsearch';

	const products = useSelector(
		state => (
			categoryId
			&&
			typeof categoryId === 'string'
			&&
			categoryId !== ''
			&&
			rootReducer.getProductByCategory(state, categoryId)
		)
			||
			(
				isSearchPath
				&&
				rootReducer.getSearchedProducts(state)
			)
			||
			rootReducer.getProducts(state)
	);

	let [min, max] = maxAndMinPrices(products);
	const isFetching = useSelector(rootReducer.getProductIsFetching);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [selectedSort, setSelectedSort] = useState('');
	const categoryMenus = useSelector(state => rootReducer.getCategorySubTreeAsArray(state, categoryId));
	const [priceRange, setPriceRange] = useState([min, max]);
	const [debouncedPriceRange] = useDebounce(priceRange, 100);

	useEffect(() => {
		setSelectedCategory([]);
		setSelectedSort('');
		setPriceRange([min, max]);
	}, [
		location,
		min,
		max
	]);

	const handlePriceRange = (_, newRange) => {
		setPriceRange(newRange);
	};

	const removePriceRange = () => setPriceRange(maxAndMinPrices(products));

	const addCategory = category => {
		if (!selectedCategory.find(i => i.id === category.id)) {
			setSelectedCategory([...selectedCategory, category]);
		}
		return;
	};

	const removeCategory = category => setSelectedCategory(
		selectedCategory.filter(i => i.id !== category.id)
	);

	const sortSelect = sort => setSelectedSort(sort);

	const removeSort = () => setSelectedSort('');

	if (isFetching) {
		return <Loading />;
	}
	const colN = col[props.width];
	const fProducts = getFilteredProducts(products, selectedCategory, selectedSort, debouncedPriceRange);
	const searchResult = (isSearchPath && fProducts.length > 0) ? `Found ${fProducts.length} products after searching for "${s}"` : '';

	return (
		<div className={classes.root}>
			<GridList
				cellHeight={
					fProducts.length
						?
						350
						:
						50
				}
				className={classes.root}
				cols={colN}
				spacing={40}
			>
				<TitleImage
					Height={180}
					data={isSearchPath ? "Search Result" : category}
					Image={"https://i.ibb.co/qmbnRtf/IMG-9891.jpg"}
				/>
				<Filter
					handlePriceRange={handlePriceRange}
					priceRange={priceRange}
					addCategory={addCategory}
					sortSelect={sortSelect}
					selectedCategory={selectedCategory}
					onDelete={removeCategory}
					selectedSort={selectedSort}
					onSortDelete={removeSort}
					onPriceRangeDelete={removePriceRange}
					categoryMenus={categoryMenus}
					filtersVisible={products.length > 1}
					max={max}
					min={min}
				// searched={s}
				/>
				{
					searchResult !== ''
						?
						<Typography
							title={searchResult}
							variant='subtitle1'
							align='center'
							style={{
								height: '10px',
								padding: 0,
								width: '100%'
							}}
						/>
						:
						""
				}
				<Divider />
				{
					fProducts.length
						?
						fProducts.map(
							product =>
								<Product
									key={product.id}
									addToCart={d => dispatch(addToCart(d))}
									isFetching={isFetching}
									id={product.id}
									name={product.name}
									banglaName={product.banglaName}
									image={product.image}
									type={product.productType}
									price={product.sellPrice}
									deliveryCharge={product.deliveryCharge}
									stockOut={product.isStockOut}
								/>
						)
						:
						<Typography
							title="Sorry, No Product To Show"
							variant='h6'
							align='center'
						/>
				}
			</GridList>
		</div>
	);
}

ProductArray.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(ProductArray));