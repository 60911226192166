import React, { 
    useEffect 
} from 'react';
import { 
    connect 
} from 'react-redux';
import { 
    makeStyles,
    Grid
} from '@material-ui/core';
import { 
    counts 
} from './Data';
import {
    Typography
} from '../../../commonComponents';
import { 
    getTotalOrderCounts, 
    getTotalProducts, 
    getUserCount
} from '../../../redux/reducers';
import { 
    fetchOrderCount 
} from '../../../redux/actions/order/Fetch';
import { 
    fetchProductCount 
} from '../../../redux/actions/product/Fetch';
import { 
    fetchUserCount 
} from '../../../redux/actions/user/Fetch';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                width: '100%',
            },
            paper: {
                height: 'auto',
                backgroundColor: theme.palette.secondary.light,
                position: 'static',
            },
        }
    )
);

const Tiles = ({
    fetchOrderCount,
    fetchProductCount,
    fetchUserCount,
    countOrders,
    countProducts,
    countUsers
}) => {
    const classes = useStyles();

    useEffect(
        () => {
            fetchOrderCount(); 
            fetchProductCount();
            fetchUserCount();
        }, [
            fetchOrderCount, 
            fetchProductCount, 
            fetchUserCount
        ]
    );

    return (
        <Grid 
            className={classes.root}
            container
            direction="row"
            justify="space-evenly"
            alignItems="flex-start"
        >
            {
                counts.map(
                    (i, index) => (
                        <Grid 
                            key={index}
                            item 
                            xs={3} 
                            className={classes.paper}
                        >
                                <Typography 
                                    variant="h4" 
                                    align="center"
                                    color="primary"
                                    title={
                                        index === 0 
                                        ?
                                        countProducts
                                        :
                                        (
                                            index === 1
                                            ?
                                            countOrders
                                            :
                                            countUsers
                                        )
                                    }
                                />
                                <Typography 
                                    variant="h6" 
                                    align="center" 
                                    gutterBottom
                                    title={i.label}
                                />
                        </Grid>
                    )
                )
            }
        </Grid>
    );
};

const mapStateToProps = state => ({
    countOrders: getTotalOrderCounts(state) || "0",
    countProducts: getTotalProducts(state),
    countUsers: getUserCount(state),
});

const mapDispatchToProps = {
    fetchOrderCount, 
    fetchProductCount, 
    fetchUserCount
}

export default connect(mapStateToProps, mapDispatchToProps)(Tiles);