import React from 'react';
import {
	connect
} from 'react-redux';
import {
	makeStyles,
	Paper,
	Stepper,
	Step,
	StepLabel,
} from '@material-ui/core';
import Invoice from './Invoice';
import AddressForm from './AddressForm';
// import PaymentForm from './PaymentForm';
import Review from './Review';
import {
	steps
} from './Data';
import {
	Typography,
	Button
} from '../../commonComponents';
import Logo from '../logo/index';
import Pdf from '../pdf';
import {
	getProfile
} from '../../redux/reducers';
import {
	create
} from '../../redux/actions/order/Create';

const useStyles = makeStyles(
	theme => (
		{
			appBar: {
				position: 'relative',
			},
			layout: {
				width: 'auto',
				height: 'auto',
				minHeight: '80vh',
				marginTop: 100,
				marginLeft: theme.spacing(2),
				marginRight: theme.spacing(2),
				[theme.breakpoints.down('sm')]: {
					marginTop: 60,
				},
				[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
					width: 600,
					marginLeft: 'auto',
					marginRight: 'auto',
				},
			},
			paper: {
				marginTop: theme.spacing(3),
				marginBottom: theme.spacing(3),
				padding: theme.spacing(2),
				[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
					marginTop: theme.spacing(6),
					marginBottom: theme.spacing(6),
					padding: theme.spacing(3),
				},
				[theme.breakpoints.down('sm')]: {
					padding: theme.spacing(0.5)
				},
			},
			stepper: {
				padding: theme.spacing(1),
				[theme.breakpoints.down('sm')]: {
					padding: 0
				},
			},
			buttons: {
				display: 'flex',
				justifyContent: 'flex-end',
			},
			button: {
				marginTop: theme.spacing(3),
				marginLeft: theme.spacing(1),
			},
		}
	)
);

const getStepContent = (
	step,
	state,
	setState
) => {
	switch (step) {
		case 0:
			return <AddressForm
				state={state}
				setState={setState}
			/>;
		case 1:
			return <Review
				state={state}
			/>;
		default:
			throw new Error('Unknown step');
	}
}

const Checkout = ({
	create,
	profile,
	...props
}) => {
	const classes = useStyles();
	const [
		activeStep,
		setActiveStep
	] = React.useState(0);

	const [state, setState] = React.useState({
		firstName: profile.first_name,
		lastName: profile.last_name,
		address: profile.address,
		contactNumber: profile.phone
	});

	const disabled = [
		state.firstName,
		state.lastName,
		state.address,
		state.contactNumber
	].indexOf('') !== -1;

	const [
		pdfData,
		setPdfData
	] = React.useState({
		order: {},
		cart: {},
	});

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	if (activeStep === steps.length + 1)
		return <Pdf
			order={pdfData.order}
			cart={pdfData.cart}
		/>;

	return (
		<main
			className={classes.layout}
		>
			<Paper
				elevation={0}
				className={classes.paper}
			>
				<Logo />
				<Typography
					variant="h6"
					color='primary'
					align="center"
					title='Gaachwala Checkout'
				/>
				<Stepper
					activeStep={activeStep}
					className={classes.stepper}
				>
					{
						steps.map(
							label => (
								<Step
									key={label}
								>
									<StepLabel>
										<b>
											{label}
										</b>
									</StepLabel>
								</Step>
							)
						)
					}
				</Stepper>
				<br />
				<br />
				<React.Fragment>
					{
						activeStep === steps.length
							?
							(
								<Invoice
									open={activeStep === steps.length}
									onClick={handleNext}
									setData={setPdfData}
									data={state}
									onClose={handleNext}
								/>
							)
							:
							(
								<React.Fragment>
									{
										getStepContent(
											activeStep,
											state,
											setState
										)
									}
									<div className={classes.buttons}>
										{
											activeStep !== 0
											&&
											(
												<Button
													onClick={handleBack}
													className={classes.button}
													buttonText='Back'
												/>
											)
										}
										<Button
											color="primary"
											disabled={disabled}
											onClick={
												() => {
													if (steps.length - 1 === activeStep) create(state);
													handleNext();
												}
											}
											className={classes.button}
											buttonText={
												activeStep === steps.length - 1
													?
													'Place order'
													:
													'Next'
											}
										/>
									</div>
								</React.Fragment>
							)
					}
				</React.Fragment>
			</Paper>
		</main>
	);
}

const mapStateToProps = state => ({
	profile: getProfile(state)
})

const mapDispatchToProps = {
	create
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);