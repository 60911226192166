import React from 'react';
import {
    withRouter
} from 'react-router-dom';
import ProductArray from './ProductArray';

const ProductList = () => (
    <ProductArray />
);

export default withRouter(ProductList);