import {
    FETCH_ALL_USER, 
    FETCH_USER_COUNT
} from '../../../consts/user/Fetch';
import {
    apiAction, 
    thunk
} from '../Helper';

export const fetchAllUser = () => apiAction(
    {
        url: '/api/user/info/all',
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH_ALL_USER,
                    data
                }),
            ]
        ),
        label: FETCH_ALL_USER
    }
);

export const fetchUserCount = () => apiAction(
    {
        url: '/api/user/info/count',
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH_USER_COUNT,
                    data
                }),
            ]
        ),
        label: FETCH_USER_COUNT
    }
);