import React from 'react';
import PropTypes from 'prop-types';
import {
	Avatar,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {bounceIn} from 'react-animations';
import styled, { keyframes } from 'styled-components';

const styles = theme => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		width: '80px',
		height: '80px',
        fontSize: '25px',
        color: theme.palette.secondary.dark,
    },
    paper: {
        color: theme.palette.secondary.dark,
    }
});

const BounceIn = styled.div`animation: 8s ${keyframes`${bounceIn}`} 1`;

const RecommendPaper = ({
	classes
}) => (
    <div 
        align="center"
        //elevation={0} 
        className={classes.paper}
    >	
        <BounceIn>	
            <Avatar className={classes.avatar}>
                97.5%
            </Avatar>
        </BounceIn>
        of reviewers recommend <b>Gaachwala</b>
	</div>
);

RecommendPaper.propTypes = {
  	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecommendPaper);
