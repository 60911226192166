import React from 'react';
import {
	Grid,
} from '@material-ui/core';
import data from './Data';
import {
	Textfield
} from '../../commonComponents';

const AddressForm = ({
	state,
	setState
}) => {
	const onChange = key => event => {
		setState({
			...state,
			[key]: event.target.value,
		});
	}
	return (
		<Grid 
			container 
			spacing={3}
		>
			{
				data.map(
					(a, index) => (
						<Grid 
							item 
							key={index}
							xs={12} 
						>
							<Textfield
								// required
								keyval={a.keyval}
								name={a.name}
								label={a.label}
								fullWidth
								value={state[a.keyval]}
								// autoComplete="lname"
								onChange={onChange(a.keyval)}
							/>
						</Grid>
					)
				)
			}
		</Grid>
	);
};

export default AddressForm;