import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles, ButtonGroup, Grid } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import Tabs from './Tabs';
import { Button, Typography, Divider, Link } from '../../commonComponents';
// import CircularProgress from './progress';
import ZoomImage from '../zoomImage';
import Loading from '../loading/index';
import AddToCartButton from '../addToCartButton';
import * as rootReducer from '../../redux/reducers';
import * as fromCategory from '../../redux/reducers/Category';
import * as fromCart from '../../redux/reducers/cart';
// import product from '../../redux/reducers/productdetails';
import { addToCart } from '../../redux/actions/cart';

const styles = theme => ({
	container: {
		margin: '90px auto',
		padding: 0,
		minHeight: '70vh',
		height: 'auto',
		width: '100%',
		color: theme.palette.primary.dark,
		// marginTop: 90,
		[theme.breakpoints.down('sm')]: {
			marginTop: 60,
		},
	},
	button: {
		//margin: theme.spacing(1),
		marginTop: 15,
		width: '350px',
		backgroundColor: theme.palette.secondary.main,
	},
	item: {
		paddingTop: '15px',
		paddingLeft: '35px',
		margin: '0 auto',
		color: theme.palette.primary.dark,
		textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			paddingTop: 10,
			paddingLeft: 0,
		},
	},
	item1: {
		width: '80%',
		maxWidth: '80%',
		margin: '0 auto',
	},
	buttonGroup: {
		padding: '0px 20px',
	},
});

const ProductDetails = ({
	classes,
	product,
	match,
	isFetching,
	fetchProducts,
	fetchCategory,
	addToCart,
	admin,
	flag,
	style,
	...props
}) => {
	const [count, setCount] = useState(1);
	let id = (match && match.params && match.params.productId) || '';

	const sell = product.isTub ? `৳ ${product.sellPrice}` : `৳ ${product.sellPrice} (Without Tub)`;

	if (isFetching) {
		return <Loading />;
	}

	return (
		<Grid
			container
			direction='row'
			alignContent='center'
			justify='center'
			// spacing={3}
			className={classes.container}
		>
			<Grid item xs={12} md={5} className={classes.item1}>
				<ZoomImage
					src={product.image}
					alt={product.name}
					id={product.name}
					height='100%'
					width='100%'
				/>
			</Grid>
			<Grid item xs={12} md={7} className={classes.item}>
				<Typography
					variant='h4'
					gutterBottom
					title={product.name || product.banglaName}
					color='inherit'
				>
					{product.scientificName !== '' && (
						<i>({product.scientificName})</i>
					)}
				</Typography>
				<Divider
					style={{
						margin: 0,
						width: '100%'
					}}
				/>
				<br />
				<Typography
					variant='body1'
					gutterBottom
					color='inherit'
					title={product.isStockOut ? "" : sell}
				>
					{
						product.isStockOut
							?
							<b
								style={{
									color: 'red'
								}}
							>
								Out of Stock
						</b>
							:
							""
					}
				</Typography>
				{/* <Typography
					variant='body1'
					gutterBottom
					color='inherit'
					title={`Delivery Charge: ৳ ${product.deliveryCharge}`}
				/> */}
				{product.planterName !== '' && (
					<Typography
						variant='body1'
						gutterBottom
						color='inherit'
						title={product.planterName}
					/>
				)}
				{product.planterSize !== '' && (
					<Typography
						variant='body1'
						gutterBottom
						color='inherit'
						title={product.planterSize}
					/>
				)}
				{product.planterPrice !== 0 && (
					<Typography
						variant='body1'
						gutterBottom
						color='inherit'
						title={product.planterPrice}
					/>
				)}
				{product.planterMaterial !== '' && (
					<Typography
						variant='body1'
						gutterBottom
						color='inherit'
						title={product.planterMaterial}
					/>
				)}
				{product.measurementUnit !== '' && (
					<Typography
						variant='body1'
						gutterBottom
						color='inherit'
						title={product.measurementUnit}
					/>
				)}
				{/* <Typography 
                    variant="body1"
                    gutterBottom
                >
                    { promo ? promo : null}
                </Typography> */}
				{admin === 1 && !flag && (
					<Link
						to={{
							pathname: '/adminproductentry',
							state: id,
						}}
					>
						<Button
							color='primary'
							buttonText='Update this product'
						/>
					</Link>
				)}

				{admin === 0 && (
					<Fragment>
						<Typography
							variant='body1'
							gutterBottom
							title='Quantity'
							color='inherit'
						/>

						<ButtonGroup
							disabled={product.isStockOut ? true : false}
							color='primary'
						>
							<Button
								onClick={() => setCount(count + 1)}
								disabled={count === 10 || isFetching}
								Icon={Add}
							/>
							<Button
								className={classes.buttonGroup}
								buttonText={count}
							/>
							<Button
								onClick={() => setCount(count - 1)}
								disabled={count === 1 || isFetching}
								Icon={Remove}
							/>
						</ButtonGroup>
						<br />
						<AddToCartButton
							variant='text'
							className={classes.button}
							title='Add to Cart'
							id={product.id}
							price={product.sellPrice}
							productType={product.productType}
							deliveryCharge={product.deliveryCharge}
							stockOut={product.isStockOut}
							quantity={count}
							text='Add To Cart'
						/>
					</Fragment>
				)}
				<Tabs
					description={product.description}
					light={product.lightRequirement}
					water={product.waterRequirement}
					benefits={product.BenefitsAndUses}
				/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state, ownProps) => ({
	isFetching: rootReducer.getProductIsFetching(state),
	product:
		ownProps.product ||
		rootReducer.getProduct(state, ownProps.match.params.productId),
	admin: rootReducer.getAuth(state).admin ? 1 : 0,
});

const mapDispatchToProps = {
	...fromCategory,
	...fromCart,
	addToCart: addToCart,
};

export default withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
);
