import React, {
	useState
} from 'react';
import {
	connect
} from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
	withStyles,
	Grid
} from '@material-ui/core';
import {
	Autocomplete
} from '@material-ui/lab';
import {
	data,
	care,
	typeOptions,
	emptyState
} from './Data';
import ChecboxForFeaturedProduct from './CheckBoxForFeatured';
import ProductDetailConfirmationDialog from './ProductEntryConfirmation';
import CategoryEntry from '../categoryEntry';
import {
	Textfield,
	Button,
	Typography
} from '../../commonComponents';
import {
	getProduct,
	getCategories
} from '../../redux/reducers';
import {
	deleteProduct
} from '../../redux/actions/product/Delete';

const styles = theme => (
	{
		root: {
			width: '60%',
			margin: '0 auto',
			height: '100%',
			padding: theme.spacing(1),
			color: theme.palette.secondary.dark,
			[theme.breakpoints.down('sm')]: {
				width: '95%',
			},
		},
		margin: {
			margin: theme.spacing(1),
		},
		withoutLabel: {
			marginTop: theme.spacing(3),
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: '100%',
		},
		appBar: {
			marginTop: '50px'
		},
		care: {
			marginLeft: 10
		},
		buttons: {
			margin: '0 auto'
		}
	}
);

const ProductDetails = ({
	classes,
	initialState,
	deleteProduct,
	id,
	names,
}) => {
	const [
		state,
		setState
	] = useState({
		id: id || '',
		name: initialState.name || '',
		banglaName: initialState.banglaName || '',
		scientificName: initialState.scientificName || '',
		BenefitsAndUses: initialState.BenefitsAndUses || '',
		planterName: initialState.planterName || '',
		planterSize: initialState.planterSize || '',
		planterPrice: initialState.planterPrice || 0,
		measurementUnit: initialState.measurementUnit || '',
		buyPrice: initialState.buyPrice || 0,
		sellPrice: initialState.sellPrice || 0,
		deliveryCharge: initialState.deliveryCharge || 0,
		description: initialState.description || '',
		lightRequirement: initialState.lightRequirement || '',
		waterRequirement: initialState.waterRequirement || '',
		isTub: initialState.isTub || false,
		productType: initialState.productType || typeOptions[0],
		image: (Array.isArray(initialState.image) && initialState.image.join(', ')) || '',
		categories: initialState.categories || [],
		open: false,
		isStockOut: initialState.isStockOut || false,
		anchorEl: null,
		checkFeature: initialState.checkFeature || false,
	});

	const onChange = key => event => {
		setState({
			...state,
			[key]: (event.target && event.target.files) || event.target.value,
		});
	};

	const onClickOpen = event => {
		setState({
			...state,
			open: !state.open,
			anchorEl: event.currentTarget
		});
	};

	const handleClose = () => {
		setState({
			...state,
			open: !state.open,
			anchorEl: null
		})
	};

	const handler = value => setState({
		...state,
		checkFeature: value
	});

	const handlerIsTub = value => setState({
		...state,
		isTub: value
	});

	const handlerIsStockOut = value => setState({
		...state,
		isStockOut: value
	});

	const handleCategory = categories => setState(prevState => ({
		...prevState,
		categories,
	}));

	return (
		<Grid
			container
			direction='column'
			justify='center'
			alignItems='stretch'
			spacing={2}
			className={classes.root}
		>
			{
				data.map(
					(item, index) => (
						<Grid
							item
							key={index}
							xs
						>
							<Textfield
								label={item.label}
								keyval={item.keyval}
								required={item.required}
								className={
									classNames(
										classes.margin,
										classes.textField
									)
								}
								value={state[item.keyval]}
								onChange={onChange(item.keyval)}
								state={state}
							/>
						</Grid>
					)
				)
			}
			<Typography
				title="Care"
			/>
			{
				care.map(
					(item, index) => (
						<Grid
							item
							key={index}
							xs
							classes={classes.care}
						>
							<Textfield
								label={item.label}
								keyval={item.keyval}
								className={
									classNames(
										classes.margin,
										classes.textField
									)
								}
								value={state[item.keyval]}
								onChange={onChange(item.keyval)}
							/>
						</Grid>
					)
				)
			}
			<Grid
				item
				xs
			>
				<ChecboxForFeaturedProduct
					value={state.checkFeature}
					onClick={handler}
					label='Featured Product'
				/>
			</Grid>
			<Grid
				item
				xs
			>
				<ChecboxForFeaturedProduct
					value={state.isTub}
					onClick={handlerIsTub}
					label='With Tub'
				/>
			</Grid>
			<Grid
				item
				xs
			>
				<ChecboxForFeaturedProduct
					value={state.isStockOut}
					onClick={handlerIsStockOut}
					label='Stock Out'
				/>
			</Grid>
			<Grid
				item
				xs
			>
				<Autocomplete
					value={state.productType}
					onChange={(event, newValue) => {
						setState({
							...state,
							productType: newValue,
						});
					}}
					id="product-type-options"
					options={typeOptions}
					renderInput={(params) => <Textfield required {...params} label="Product Type" variant="outlined" />}
				/>
			</Grid>
			<Grid
				item
				xs
			>
				<CategoryEntry
					value={
						state.categories.map(
							v => names.find(n => n.id === v)
								||
								''
						)
					}
					onClick={
						(_, v) => handleCategory(v.map(
							({ id }) => id)
						)
					}
					multiple={true}
					required={true}
					options={names}
					label="Select Categories"
				/>
			</Grid>
			<Grid
				item
				xs
				className={classes.buttons}
			>
				<Button
					size="small"
					fullWidth
					variant="outlined"
					color="primary"
					onClick={onClickOpen}
					buttonText="Update"
				/>
				<br />
				<br />
				<Button
					size="small"
					fullWidth
					variant="outlined"
					color="primary"
					onClick={
						() => {
							deleteProduct(id);
							setState(emptyState);
						}
					}
					buttonText="Delete"
				/>
				<ProductDetailConfirmationDialog
					open={state.open}
					onClose={handleClose}
					state={state}
					setState={setState}
					id={id}
				/>
			</Grid>
		</Grid>
	);
};

ProductDetails.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
	initialState: getProduct(state, ownProps.id) || {},
	names: getCategories(state)
});

const mapDispatchToProps = (dispatch) => ({
	deleteProduct: id => dispatch(deleteProduct(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProductDetails));