import React, {
    Fragment,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useLocation
} from 'react-router-dom';
import clsx from 'clsx';
import { 
    makeStyles,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '@material-ui/core';
import {
    Add
} from '@material-ui/icons';
import {
    Button,
    Link,
    Textfield
} from '../../commonComponents';
import {
    activatePromo
} from '../../redux/actions/promo/user';
import {
    getActivePromo,
    getUserName
} from '../../redux/reducers';

const useStyles = makeStyles(
    (theme) => ({
        expansion: {
            boxShadow: 'none',
        },
        root: {
            margin: '0 auto',
            '& > *': {
                margin: '0 auto',
                width: '100%',
            },
        },
        button: {
            margin: '12px auto',
            textTransform: 'capitalize'
        },
        expansionPanelDetail: {
            width: '100%',
            padding: 0,
        },
        hide: {
            display: 'none'
        }
    }
));

export default () => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const userName = useSelector(getUserName);
    const activePromo = useSelector(getActivePromo);
    const {
        state
    } = useLocation();

    const onClick = () => {
        dispatch(activatePromo({
            userName,
            code: value
        }));

        setValue('');
    };

    const onChange = ({
        target: {
            value
        }
    }) => setValue(value);

    return (
        <Fragment>
            <Button
                component={Link}
                to="/cart"
                className={
                    clsx({
                        [classes.hide]: !activePromo || !state
                    })
                }
            >
                Return to cart.
            </Button>
            <ExpansionPanel
                square
                className={classes.expansion}
            >
                <ExpansionPanelSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Button
                        color='primary'
                        buttonText='Add Promo'
                        className={classes.button}
                        Icon={Add}
                    />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.expansionPanelDetail}
                >
                    <form 
                        className={classes.root} 
                        noValidate 
                        autoComplete="off"
                    >
                        <Textfield 
                            id="outlined-basic" 
                            label="Promo Entry" 
                            helperText="Enter Your Promo Code to The Profile" 
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                        />
                        <Button
                            color='primary'
                            buttonText='Activate'
                            className={classes.button}
                            onClick={onClick}
                        />
                    </form>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Fragment>
    );
}
