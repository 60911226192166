import React from 'react';
import PropTypes from 'prop-types';
import { 
	slideInUp 
} from 'react-animations';
import styled, { 
	keyframes 
} from 'styled-components';
import { 
	withStyles 
} from '@material-ui/core/styles';
import {
	Typography
} from '../../commonComponents';

const styles = theme => (
	{
		progress: {
			//margin: theme.spacing.unit * 2,
			width: '100%',
			height: '100%',
			margin: '115px auto',
			padding: 'auto',
			display: 'flex',
			justifyContent: 'center',
			color: theme.palette.primary.main,
		},
	}
);

const SlideInUp = styled.div`animation: 2s ${keyframes`${slideInUp}`} 1`;

const Loading = ({
	classes,
}) => (
	<div 
		className={classes.progress}
	>
		<SlideInUp>
			<Typography
				variant='h6'
				color='inherit'
				align='center'
				title='গাছ লাগাই, ঢাকা বাঁচাই।'
			/>
		</SlideInUp>
	</div>
);

Loading.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
