import React, {
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useLocation
} from 'react-router-dom';
import {
    Button,
    RichTextEditor,
    Textfield
} from '../../commonComponents';
import {
    Grid,
    makeStyles
} from '@material-ui/core';
import {
    update
} from '../../redux/actions/staticContent/Update';
import {
    getStaticContent
} from '../../redux/reducers';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(1)
    }
}));

const StaticContentEditor = props => {
    const {
        state: {
            slug
        }
    } = useLocation();

    const data = useSelector(state => getStaticContent(state, slug));

    const content = JSON.parse(data.content[0]);

    const classes = useStyles();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        slug: slug || '',
        content: content || null
    });

    const onChange = key => value => setState({
        ...state,
        [key]: value
    });

    const disabled = Boolean(state.slug === '' || !state.content);

    if (!Object.keys(content).length) {
        return 'Access forbidden';
    }

    return (
        <Grid
            container
            direction="column"
            justify="space-evenly"
            spacing={1}
            className={classes.root}
        >
            <Grid
                item
                xs
            >
                <Textfield
                    label="Slug"
                    value={state.slug}
                    onChange={({ target: { value } }) => onChange('slug')(value)}
                    disabled
                />
            </Grid>
            <Grid
                item
                xs
            >
                <RichTextEditor
                    value={content}
                    onChange={onChange('content')}
                />
            </Grid>
            <Grid
                item
                xs
            >
                <Button
                    disabled={disabled}
                    onClick={() => dispatch(update({
                        ...state,
                        content: [JSON.stringify(state.content)],
                        id: data.id
                    }))}
                >
                    Update
                </Button>
            </Grid>
        </Grid>
    );
};

export default StaticContentEditor;