import React from 'react';
import {
    withStyles,
    Divider,
    Paper
} from '@material-ui/core';
import Logo from '../logo/index';
import {
    Button,
    Link,
    Typography
} from '../../commonComponents';

const styles = theme => (
    {
        root: {
            minHeight: '70vh',
            width: '100%',
            margin: '100px auto',
        },
        typography: {
            padding: 8,
            margin: '0 auto'
        },
        button: {
            marginTop: '50px'
        },
        divider1: {
            width: '40%',
            margin: '10px auto'
        },
    }
);

const EmptyCart = ({
    classes
}) => (
        <Paper
            elevation={0}
            className={classes.root}
        >
            <Logo />
            <Typography
                color='primary'
                title='Gaachwala Shopping Cart'
                align='center'
                variant='h6'
            />
            <Divider className={classes.divider1} />
            <Typography
                variant="subtitle1"
                color='primary'
                align="center"
                className={classes.typography}
                title="There is no item in your cart."
            />
            <div
                align='center'
            >
                <Button
                    component={Link}
                    color='primary'
                    to='/'
                    className={classes.button}
                    buttonText="Continue Shopping"
                />
            </div>
        </Paper>
    )

export default withStyles(styles)(EmptyCart);