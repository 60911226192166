import {
    READ
} from '../../../../consts/promo/Admin';
import {
    apiAction,
    thunk
} from '../../Helper';
import {
    appendNotification
} from '../../Notifications';

export const readById = id => apiAction({
   url: '/api/promo/' + id,
   onSuccess: data => ({
       type: READ,
       data
   }),
   label: READ
});

export const readAll = () => apiAction({
    url: '/api/promoinfo/all',
    onSuccess: data => thunk(
        data.all_promo.map(promo => ({
            type: READ,
            data: {
                code: promo.promo_code,
                discount: promo.discount,
                validity: promo.end_date,
                id: promo.id,
                createdAt: promo.created_at,
                updatedAt: promo.updated_at,
                userLimit: promo.limit,
                maxDiscount: promo.max_discount,
                minPurchase: promo.min_purchase,
                name: promo.name
            }
        }))
    )(),
    label: READ
});

export const readByCode = code => apiAction({
    url: '/api/promo/info/' + code,
    onSuccess: data => ({
        type: READ,
        data: {
            code: data.promo_code,
            discount: data.discount,
            validity: data.end_date,
            id: data.id,
            createdAt: data.created_at,
            updatedAt: data.updated_at,
            userLimit: data.limit,
            maxDiscount: data.max_discount,
            minPurchase: data.min_purchase,
            name: data.name
        }
    }),
    onFailure: () => appendNotification({
        message: 'Invalid promo',
        variant: 'error'
    }),
    label: READ
});