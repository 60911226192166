import {
    CREATE
} from '../../../consts/category/Create';
import { 
    apiAction
} from '../Helper';
import {
    fetchAll
} from './Fetch';

export const create = ({
    categoryName,
    description,
    parentCategory
}) => apiAction(
    {
        url: '/api/category',
        method: 'POST',
        data: (
            () => {
                let obj = {
                    name: categoryName,
                    description: description || '',
                };
                
                if(parentCategory && parentCategory !== '') obj.category_id = parentCategory;
                
                return obj;
            }
        )(),
        onSuccess: fetchAll,
        label: CREATE
    }
);