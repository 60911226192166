import React from 'react';
import { 
    connect 
} from 'react-redux';
import {
    Dialog,
    AppBar,
    Toolbar,
    Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button,
    IconButton
} from '../../commonComponents';
import {
    emptyState
} from './Data';
import { 
    create 
} from '../../redux/actions/product/Create';
import ProductDetailsAdmin from '../productDetails/index';
import { 
    update 
} from '../../redux/actions/product/Update';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide 
        direction="up" 
        ref={ref} 
        {...props} 
    />;
});

const ProductDetailConfirmationDialog = ({
	onClick,
	state,
	setState,
    onClose,
    open,
}) => (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar >
                <Toolbar >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        Icon={CloseIcon}
                    />
                    <Button
                        autoFocus
                        onClick={() => {
                            onClick(state);
                            setState(emptyState);
                        }}
                        buttonText="save"
                    />
                </Toolbar>
            </AppBar>
            <ProductDetailsAdmin
                // style={{marginTop: 90}}
                product={{
                    ...state,
                }}
                flag={1}
            />
        </Dialog>
			
);

const mapDispatchToProps = (dispatch, ownProps) => ({
	onClick: state => dispatch((ownProps.id === '' ? create : update)(state)),
});

export default connect(null, mapDispatchToProps)(ProductDetailConfirmationDialog);