import {
    combineReducers
} from 'redux';
import item, * as fromItem from './Item';
import list, * as fromList from './List';

export default combineReducers(
    {
        item,
        list
    }
);

export const getProducts = state => (
    fromList
        .getIds(state.list)
        .map(
            id => fromItem.getProduct(state.item, id)
        )
);

export const getProduct = (state, id) => fromItem.getProduct(state.item, id);

export const getImages = (state, id) => getProduct(state, id).image;

export const getIsFetching = state => fromList.getIsFetching(state);

export const getProductCounts = state => fromItem.getProductCount(state.item);

export const getProductsByCategory = (state, catId) => (
    getProducts(state)
        .filter(
            (
                {
                    categories
                }
            ) => (
                    categories
                    &&
                    categories.indexOf
                    &&
                    categories.indexOf(catId) !== -1
                )
        )
);

export const getFeaturedProducts = state => (
    getProducts(state)
        .filter(
            (
                {
                    checkFeature
                }
            ) => checkFeature
        )
);

export const getSearchedProducts = state => fromList.getSearchedIds(state.list).map(id => getProduct(state, id));