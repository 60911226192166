import {
    UPDATE
} from '../../../consts/inventory/Update';
import {
    apiAction,
} from '../Helper';
import {
    fetchAll
} from './Fetch';

export const update = ({
    id,
    productID,
    Quantity
}) => apiAction(
    {
        url: '/api/inventory/update/' + id,
        method: 'POST',
        data: {
            productID,
            count: Quantity,
        },
        onSuccess: fetchAll,
        label: UPDATE,
    }
);