import React, {
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Grid,
    makeStyles
} from '@material-ui/core';
import {
    Button,
    DatePicker,
    Textfield
} from '../../commonComponents';
import {
    promo_properties,
    emptyState
} from './Data';
import {
    create
} from '../../redux/actions/promo/admin/Create';
import {
    update
} from '../../redux/actions/promo/admin/Update';
import {
    getPromoByID
} from '../../redux/reducers';


const useStyles = makeStyles(theme => ({
    root: {
        width: '60%',
        margin: '0 auto',
        height: '100%',
        padding: theme.spacing(1),
        color: theme.palette.secondary.dark,
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    appBar: {
        marginTop: '50px'
    },
    care: {
        marginLeft: 10
    },
    buttons: {
        margin: '0 auto'
    }
}));

const PromoEntry = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        state: id
    } = useLocation();

    const [state, setState] = useState({
        ...emptyState,
        ...useSelector(state => getPromoByID(state, id))
    });

    const onClick = event => {
        event.preventDefault();

        if(!id)dispatch(create(state));
        else dispatch(update(state));
    };

    const onChange = key => ({
        target: {
            value
        }
    }) => setState({
        ...state,
        [key]: value
    });

    const onChangeDate = key => value => setState({
        ...state,
        [key]: value
    });

    const renderProperty = property => {
        switch(property.keyval) {
            case 'validity':
                return (
                    <DatePicker 
                        value={state[property.keyval]}
                        onChange={onChangeDate(property.keyval)}
                        className={
                            clsx(
                                classes.margin, 
                                classes.textField
                            )
                        }
                        label={property.label}
                        disableFuture={false}
                        disablePast
                    />
                );
            default:
                return (
                    <Textfield
                        label={property.label}
                        keyval={property.keyval}
                        className={
                            clsx(
                                classes.margin, 
                                classes.textField
                            )
                        }
                        value={state[property.keyval]}
                        onChange={onChange(property.keyval)}
                        state={state}
                        type={property.type}
                    />
                );
        }
    };

    return (
        <Grid 
            container 
            direction='column' 
            justify='center'
            alignItems='stretch'
            spacing={2}
            className={classes.root}
        >
            {
                promo_properties.map(
                    (property, index) => (
                        <Grid
                            item
                            key={index}
                            xs
                        >
                            {renderProperty(property)}
                        </Grid>
                    )
                )
            }
            <Grid 
                item 
                xs={12}
                container
                justify="center"
            >
                <Button 
                    size="small" 
                    variant="outlined" 
                    color="primary" 
                    onClick={onClick}
                >
                    {id ? 'Update' : 'Create'}
                </Button>
            </Grid>    
        </Grid>                
    );
};

PromoEntry.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default PromoEntry;

