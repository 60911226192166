import {
    CHECKOUT,
    REMOVE
} from '../../../consts/cart';
import {
    createProduct
} from './Create';
import {
    updateCart,
    updateProduct
} from './Update';
import {
    activatePromo,
    reduceLimit
} from '../promo/user';
import {
    getActivePromo,
    getCart,
    getCartProduct,
    getDiscountUser,
    getUserName,
    getCartIsFetching,
} from '../../reducers';
import deliveryCharge from '../../../utils/DeliveryCharge';

export const addToCart = ({
    id,
    quantity,
    price,
    productType
}) => (
    dispatch,
    getState
) => {
        const cart = getCart(getState());
        const cartProduct = getCartProduct(getState(), id);
        const cartIsFetching = getCartIsFetching(getState(), cart.id);

        if (cartIsFetching) return;

        // if cart product is not present
        if (!cartProduct) {
            // create cart product
            dispatch(
                createProduct(
                    {
                        productId: id,
                        quantity: quantity,
                        price: price,
                        promo: '',
                        deliveryCharge: deliveryCharge(productType),
                        totAmount: quantity * price  //delivery charge will be deleted very soon
                    },
                    cart
                )
            );
            // update cart is sent to createProduct since it is always required
        }
        else {
            dispatch(
                updateProduct(
                    {
                        ...cartProduct,
                        quantity: cartProduct.quantity + quantity,
                        totAmount: (cartProduct.quantity + quantity) * price,
                    },
                    {
                        ...cart,
                        totAmount: cart.totAmount + quantity * price,
                        TDC: cart.TDC + cartProduct.deliveryCharge * quantity,
                        deliveryCharge: Math.min(240, cart.TDC + cartProduct.deliveryCharge * quantity)
                    }
                )
            );
        }
    };

export const updateQuantity = product => (
    dispatch,
    getState
) => {
    const cart = getCart(getState());
    const cartProduct = getCartProduct(getState(), product.id);
    const cartIsFetching = getCartIsFetching(getState(), cart.id);

    if (cartIsFetching) return;

    dispatch(
        updateProduct(
            {
                id: product.id,
                productId: product.productId,
                quantity: product.quantity,
                price: product.price,
                deliveryCharge: product.deliveryCharge,
                promo: '',
                totAmount: product.quantity * product.price
            },
            {
                ...cart,
                totAmount: cart.totAmount + (product.quantity - cartProduct.quantity) * product.price,
                TDC: cart.TDC + (product.quantity - cartProduct.quantity) * product.deliveryCharge,
                deliveryCharge: Math.min(240, cart.TDC + (product.quantity - cartProduct.quantity) * product.deliveryCharge)
            }
        )
    );
};

export const removeFromCart = ({
    id,
    totAmount,
    price,
    quantity,
    deliveryCharge
}) => async (
    dispatch,
    getState
) => {
        const cart = getCart(getState());
        const cartIsFetching = getCartIsFetching(getState(), cart.id);

        if (cartIsFetching) return;

        await dispatch(
            updateCart(
                {
                    ...cart,
                    cartProducts: cart.cartProducts.filter(cp => cp !== id),
                    totAmount: cart.totAmount - price * quantity,
                    TDC: cart.TDC - deliveryCharge * quantity,
                    deliveryCharge: Math.min(240, cart.TDC - deliveryCharge * quantity)
                }
            )
        );
        await dispatch(
            {
                type: REMOVE,
                id
            }
        )
    };

export const checkoutCart = (
    admin = false
) => async (
    dispatch,
    getState
) => {
        if (!admin) {
            const cart = getCart(getState());
            const cartIsFetching = getCartIsFetching(getState(), cart.id);

            if (cartIsFetching) return;

            await dispatch(
                updateCart(
                    {
                        ...cart,
                        status: 'checked_out',
                        discount: getDiscountUser(getState())
                    }
                )
            );

            if (getDiscountUser(getState()) > 0) dispatch(reduceLimit(getActivePromo(getState())));
        }

        await dispatch(
            {
                type: CHECKOUT,
                admin
            }
        )
    };

export const applyPromoToCart = code => (dispatch, getState) => {
    const cart = getCart(getState());
    const cartIsFetching = getCartIsFetching(getState(), cart.id);

    if (cartIsFetching) return;

    const activePromo = getActivePromo(getState());

    if (activePromo.userLimit < 1) return dispatch(activatePromo({
        userName: getUserName(getState()),
        code
    }));

    return dispatch(updateCart({
        ...cart,
        promoCode: code
    }));
};