import {
    DELETE_USER,
    UPDATE_PASSWORD,
    UPDATE_PROFILE
} from '../../../consts/user/Update';
import {
    appendNotification
} from '../Notifications';
import { 
    apiAction,
    thunk
} from '../Helper';

export const deleteUser = id => apiAction(
    {
        url: '/api/user/remove/' + id,
        method: 'POST',
        onSuccess: () => ({
            type: DELETE_USER,
            id
        }),
        label: DELETE_USER
    }
);

export const updatePassword = ({
    userName,
    currentPassword,
    newPassword
}) => apiAction({
    url: '/api/user/updatepassword/' + userName,
    method: 'POST',
    data: {
        password: newPassword,
        oldpassword: currentPassword
    },
    onSuccess: thunk([
        data => ({
            type: UPDATE_PASSWORD,
            success: true,
            data
        }),
        () => appendNotification({
            message: 'Successfully updated password.',
            variant: 'success'
        })
    ]),
    onFailure: () => appendNotification({
        message: 'Failed to update passwod. Please try again.',
        variant: 'error'
    }),
    label: UPDATE_PASSWORD
});

export const updateProfileInformation = ({
    address,
    first_name,
    id,
    last_name,
    phone
}) => apiAction({
    url: '/api/user/update/' + id,
    method: 'POST',
    data: {
        first_name,
        last_name,
        phone,
        address: [address]
    },
    onSuccess: data => ({
        type: UPDATE_PROFILE,
        data
    }),
    label: UPDATE_PROFILE
});