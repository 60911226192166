import {
    READ,
    UPDATE
} from '../../consts/StaticContent';

const staticContent = (state = {}, action) => {
    switch(action.type) {
        case READ:
        case UPDATE:
            return {
                ...state,
                [action.data.content_slug]: {
                    content: action.data.static_content,
                    id: action.data.id
                }
            };
        default:
            return state;
    }
};

export default staticContent;

export const get = (state, slug) => state[slug] || {content: ["{}"]};