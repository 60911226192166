import {
    FETCH,
    FETCH_ALL
} from '../../../consts/product/Fetch';
import {
    API_START,
    API_END
} from '../../../consts/Api';
import {
    DELETE
} from '../../../consts/product/Delete';
import {
    SEARCH
} from '../../../consts/product/Search';

export default (
    state =
        {
            ids: [],
            searchIds: [],
            isFetching: false,
            error: null
        },
    action
) => {
    switch (action.type) {
        case FETCH_ALL:
            return {
                ...state,
                ids: action.data.map(({ id }) => id)
            };
        case DELETE:
            return {
                ...state,
                ids: state
                    .ids
                    .filter(id => id !== action.id)
            };
        case SEARCH:
            return {
                ...state,
                searchIds: action.data.map(({ id }) => id)
            };
        case API_START:
            if ([FETCH_ALL, FETCH, SEARCH].indexOf(action.payload) !== -1) {
                return {
                    ...state,
                    isFetching: true
                };
            }
            return state;
        case API_END:
            if ([FETCH_ALL, FETCH, SEARCH].indexOf(action.payload) !== -1) {
                return {
                    ...state,
                    isFetching: false
                };
            }
            return state;
        default:
            return state;
    }
};

export const getIsFetching = state => state.isFetching;

export const getIds = state => state.ids;

export const getSearchedIds = state => state.searchIds;