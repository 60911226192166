import React from 'react';
import PropTypes from 'prop-types';
import { 
	withStyles,
	useTheme
} from '@material-ui/core';
import {
	Button,
	Textfield
} from '../../commonComponents';

const styles = theme => (
	{
		root: {
			width: '100%',
			color: theme.palette.secondary.dark,
			margin: '0 auto',
			// padding: theme.spacing(3),
		},
		textField2: {
			padding: theme.spacing(1),
			margin: theme.spacing(1),
			height: 'auto',
			width: '80%',
		},
	}
);

const TextFields = ({
	classes,
}) => {
	const theme = useTheme();

	return (
		<div
			className={classes.root}
		>
			<Textfield
				keyval="name"
                label="Name"
				style={{
					width: '80%',
					margin: theme.spacing(1),
				}}
			/>
			<Textfield
				keyval="email"
				label="Email"
				style={{
					width: '80%',
					margin: theme.spacing(1),
				}}
			/>
			<Textfield
				keyval="message"
				label="Message"
				multiline
				rows={6}
				style={{
					width: '80%',
					margin: theme.spacing(1),
				}}
			/>
			<Button 
				color="primary" 
				className={classes.textField2}
				buttonText='Send'
			/>
		</div>
	);
}

TextFields.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFields);
