import React from 'react';
import PropTypes from 'prop-types';
import { 
	withStyles,
	Paper,
} from '@material-ui/core';
import {
	Typography
} from '../../commonComponents';

const styles = theme => (
	{
		empty: {
			minHeight: '70px',
			maxHeight: '70px', 
			width: '350px', 
			paddingTop: '45px', 
			margin: 'auto auto', 
			backgroundColor: theme.palette.secondary.light,
			// zIndex: 2440,
		}
	}
);

const EmptyCartModal = ({
	classes,
}) => (
    <Paper 
        elevation={1}
        className={classes.empty}
    >
        <Typography 
            variant="h6" 
            color='primary'
            align="center"
            title='Your Cart is Empty'
        />
    </Paper>
);

EmptyCartModal.propTypes = {
  	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmptyCartModal);