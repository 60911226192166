import React, {
    Fragment,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import clsx from 'clsx';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles
} from '@material-ui/core';
import {
    Alert
} from '@material-ui/lab';
import {
    passwordFields,
    updatePaswordEmptyState
} from './Data';
import {
    Button,
    Textfield
} from '../../commonComponents';
import {
    updatePassword
} from '../../redux/actions/user/Update';
import {
    getUserName
} from '../../redux/reducers';
import Logo from '../logo/index';

const useStyles = makeStyles(
    theme => ({
        inner: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main
        },
        input: {
            width: '90%',
            margin: theme.spacing(1)
        },
        title: {
            textAlign: 'center',
            color: theme.palette.primary.main
        },
        dialogAction: {
            margin: '0 auto'
        },
        dialogContent: {
            padding: 0
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                textTransform: 'capitalize',
            }
        },
        hide: {
            display: 'none'
        }
    }
));

const UpdatePassword = props => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [state, setState] = useState(updatePaswordEmptyState);
    const dispatch = useDispatch();
    const userName = useSelector(getUserName);

    const onChange = key => ({
        target: {
            value
        }
    }) => setState({
        ...state,
        [key]: {
            value,
            valid: value.length > 7
        }
    });

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const disabled = Object.keys(state).reduce((prev, cur) => prev || !state[cur].valid, false) 
                    || 
                    (state.confirmNewPassword.value !== state.newPassword.value);

    return (
        <Fragment>
            <Dialog
                open={open}
                fullWidth
                onClose={handleClose}
            >
                <Logo />
                <DialogTitle
                    className={classes.title}
                >
                    Update password
                </DialogTitle>
                <DialogContent>
                    <Alert 
                        severity="warning"
                        className={
                            clsx({
                                [classes.hide]: state.newPassword.value === state.confirmNewPassword.value
                            })
                        }
                    >
                        New passwords doesn't match.
                    </Alert>
                    {
                        passwordFields.map(
                            (field, index) => (
                                <Textfield 
                                    key={index}
                                    value={state[field.key].value}
                                    onChange={onChange(field.key)} 
                                    label={field.label}
                                    type="password"
                                    placeholder={field.placeholder} 
                                    className={classes.input}
                                    error={!state[field.key].valid}
                                    helperText={!state[field.key].valid ? "Password should contain at least 8 characters." : ""}
                                />
                            )
                        )
                    }
                </DialogContent>
                <DialogActions
                    className={classes.dialogAction}
                >
                    <Button
                        buttonText='Update'
                        className={classes.button}
                        color='primary'
                        disabled={disabled}
                        onClick={
                            () => {
                                dispatch(updatePassword({
                                    userName,
                                    currentPassword: state.currentPassword.value,
                                    newPassword: state.newPassword.value
                                }));

                                handleClose();
                            }
                        }
                    />
                    <Button
                        buttonText='cancel'
                        onClick={handleClose}
                        color='primary'
                        className={classes.button}
                    />
                </DialogActions>
            </Dialog>
            <Button
                buttonText='Update password'
                variant='text'
                color='primary'
                onClick={handleOpen}
                className={classes.button}
            />
        </Fragment>
    )
};

export default UpdatePassword;