import React from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    FormControl
} from '@material-ui/core';
import DropDown from './DropDown';

const styles = theme => ({
    root: {
        width: '400px',
        height: 'auto',
        minHeight: '15vh',
        paddingTop: 30,
        padding: theme.spacing(0.5),
        backgroundColor: '#c5e1a5',
        [theme.breakpoints.down('sm')]: {
            width: '280px'
        },
        zIndex: 2000
    },
    inputbase: {
        backgroundColor: '#aed581',
        height: '50px',
        padding: theme.spacing(1)
    }
});

const Cart = ({
    classes,
    onClose
}) => (
        <FormControl
            fullWidth
            className={classes.root}
        >
            <DropDown
                onClose={onClose}
            />
        </FormControl>
    );

Cart.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Cart); 