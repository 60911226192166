import React from 'react';
import { 
    makeStyles 
} from '@material-ui/core';
import { 
    fadeInDown 
} from 'react-animations';
import styled, { 
    keyframes 
} from 'styled-components';
import {
    Typography
} from '../../commonComponents';

const useStyles = makeStyles(
    theme => (
        {
            image: {
                position: 'relative',
                width: '100% !important', // Overrides inline-style
                //height: 180,
                '&:hover, &$focusVisible': {
                    zIndex: 1,
                    '& $imageBackdrop': {
                        opacity: 0.1,
                    },
                    '& $imageTitle': {
                        border: '4px solid currentColor',
                    },
                },
            },
            focusVisible: {},
            imageButton: {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.common.white,        
            },
            imageSrc: {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center ',
            },
            imageBackdrop: {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: theme.palette.secondary.main,
                opacity: 0.3,
                transition: theme.transitions.create('opacity'),
            },
            imageTitle: {
                position: 'relative',
                padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
                backgroundColor: theme.palette.secondary.main,
                // opacity: 0.5,
                // '&:hover': {
                color: theme.palette.secondary.dark,
                opacity: 0.7
                // }
            },
        }
    )
);

const FadeInDown = styled.div`animation: 2s ${keyframes`${fadeInDown}`} 1`;

const TitleImage = ({ 
    data, 
    Image,
    Height, 
}) => {
    const classes = useStyles();

    return (
        <div 
            className={classes.image} 
            style={{
                height: Height
            }} 
        >
            {/* <ButtonBase
            //focusRipple
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            > */}
            <span
                className={classes.imageSrc}
                style={{
                    backgroundImage: `url(${Image})`,
                }}
            >
                <span 
                    className={classes.imageBackdrop} 
                />
                    <span 
                        className={classes.imageButton}
                    >
                        <FadeInDown>
                            <Typography
                                component="span"
                                variant="h5"
                                color="inherit"
                                className={classes.imageTitle}
                                title={data}
                            />
                        </FadeInDown>
                    </span>
            </span>
            {/* </ButtonBase> */}
        </div>
    );
}

export default TitleImage;
