import {
    FETCH_ALL,
    FETCH
} from '../../../consts/inventory/Fetch';
import {
    apiAction,
    thunk
} from '../Helper';

export const fetchAll = () => apiAction(
    {
        url: '/api/inventory/info/all',
        label: FETCH_ALL,
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH_ALL,
                    data: data.all_inventory
                }),
            ]
        )
    }
);

export const fetch = ({
    id
}) => apiAction(
    {
        url: '/api/inventory/productinfo/' + id,
        label: FETCH,
        onSuccess: thunk(
            [
                data => ({
                    type: FETCH,
                    data: data
                }),
            ]
        )
    }
);