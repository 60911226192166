import React, {
    useEffect
} from 'react';
import {
    connect
} from 'react-redux';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';
import Dialog from '../newOrders/Dialog';
import {
    Button
} from '../../commonComponents';
import {
    getDeletedOrders
} from '../../redux/reducers';
import {
    fetchDeletedOrder
} from '../../redux/actions/order/Fetch';
import {
    dropUndo
} from '../../redux/actions/order/Update';

const useStyles = makeStyles(
    {
        paper: {
            width: '100%',
        },
        table: {
            minWidth: '100%',
            width: '100%',
        },
        style: {
            width: '100%',
            '& .MuiExpansionPanel-root': {
                minWidth: '100%'
            },
        },
    }
);

const mapStateToProps = state => ({
    orders: getDeletedOrders(state),
});

const mapDispatchToProps = {
    fetchDeletedOrder,
    dropUndo
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ({
        fetchDeletedOrder,
        orders,
        dropUndo,
        ...props
    }) => {
        const classes = useStyles();

        useEffect(
            () => fetchDeletedOrder(),
            [
                fetchDeletedOrder
            ]
        );

        const [
            open,
            setOpen
        ] = React.useState(-1);

        const handleClickOpen = id => {
            setOpen(id);
        };

        const handleClose = () => {
            setOpen(-1);
        };

        console.log('j', open)

        return (
            <Paper
                className={classes.paper}
            >
                <Table
                    className={classes.table}
                    aria-label="userpurchasehistory"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Customer
							</TableCell>
                            <TableCell
                                align="right"
                            >
                                Contact Number
							</TableCell>
                            <TableCell
                                align="right"
                            >
                                Address
							</TableCell>
                            <TableCell
                                align="right"
                            >
                                Date
							</TableCell>
                            <TableCell
                                align="right"
                            >
                                OrderID
							</TableCell>
                            <TableCell
                                align="right"
                            >
                                Total Amount
							</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        className={classes.style}
                    >
                        {
                            orders.map(
                                (row, index) => {
                                    let join = new Date(row.created_at);

                                    return (
                                        <TableRow
                                            key={index}
                                            className={classes.style}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {
                                                    `${row.firstname} ${row.lastname}`
                                                }
                                                <Button
                                                    id="show-details"
                                                    variant="text"
                                                    color="primary"
                                                    size="small"
                                                    onClick={
                                                        () => dropUndo(row)
                                                    }
                                                    buttonText='Undo Delete?'
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                            >
                                                {row.contactNumber}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                            >
                                                {row.Address}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                            >
                                                {`${join.toString()}`}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                            >
                                                {row.order_number}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                            >
                                                {
                                                    row.totAmount
                                                    ||
                                                    'loading...'
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            )
                        }
                    </TableBody>
                </Table>
                {/* <Dialog
                    open={open !== -1}
                    onClose={handleClose}
                    id={open}
                    flag={1}
                /> */}
            </Paper>
        );
    }
);