import {
    CREATE
} from '../../../../consts/promo/Admin';
import {
    apiAction
} from '../../Helper';

export const create = ({
    code,
    name,
    discount,
    maxDiscount,
    minPurchase,
    validity,
    usageLimit
}) => apiAction({
    url: '/api/promo',
    method: 'POST',
    data: {
        promo_code: code,
        name,
        discount: Number(discount),
        max_discount: Number(maxDiscount),
        min_purchase: Number(minPurchase),
        end_date: validity,
        limit: Number(usageLimit)
    },
    onSuccess: data => ({
        type: CREATE,
        data
    }),
    label: CREATE
});