export const loginData = [
    {
        keyval: 'user_name',
        label: 'User Name',
    },
    {
        keyval: 'password',
        label: 'Password',
    }
];

export const registerData = [
    {
        keyval: 'firstName',
        label: 'First Name',
        helperText: 'Name should only contain letters, spaces, period, hyphen, or apostrophe'
    },
    {
        keyval: 'lastName',
        label: 'Last Name',
        helperText: 'Name should only contain letters, spaces, period, hyphen, or apostrophe'
    },
    {
        keyval: 'userName',
        label: 'User Name',
        helperText: 'Name should only contain letters, spaces, period, hyphen, or apostrophe'
    },
    {
        keyval: 'email',
        label: 'Email Address',
        helperText: ''
    },
    {
        keyval: 'phone',
        label: 'Phone Number',
        helperText: 'Phone number should only contain digits'
    },
    {
        keyval: 'address',
        label: 'Address',
        helperText: ''
    },
    {
        keyval: 'password1',
        label: 'Password',
        helperText: 'Password should contain at least 8 characters'
    },
    {
        keyval: 'password2',
        label: 'Confirm Password',
        helperText: `Passwords don't match`
    },
];

export const defaultRegisterState = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phone: '',
    address: '',
    password1: '',
    password2: '',
    dateofbirth: new Date(),
    gender: 'female',
    showPassword1: false,
    showPassword2: false,
    valid: {
        firstName: true,
        dateofbirth: true,
        lastName: true,
        userName: true,
        email: true,
        phone: true,
        address: true,
        gender: true,
        password1: true,
        password2: true,
    },
    required: {
        firstName: true,
        lastName: true,
        userName: true,
        email: true,
        phone: true,
        address: false,
        gender: true,
        password1: true,
        password2: true,
    }
}