import React from 'react';
import TextField from '@material-ui/core/TextField';

export default ({
    label,
    keyval,
    ...props
}) => {
    return (
        <TextField 
            label={label}
            variant='outlined'
            id={keyval}
		    // onChange={onChange(keyval) || ''} 
            {...props}
        />
    );
}