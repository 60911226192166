import {
    UPDATE_PRODUCT
} from '../../../consts/cart/Update';
import {
    CREATE_PRODUCT
} from '../../../consts/cart/Create';
import {
    CHECKOUT,
    REMOVE
} from '../../../consts/cart';
import {
    FETCH_PRODUCT
} from '../../../consts/cart/Fetch';
// const productKeys = ['id', 'product', 'promo', 'quantity', 'totAmount'];

export default (
    state = {},
    action
) => {
    switch (action.type) {
        case CREATE_PRODUCT:
        case UPDATE_PRODUCT:
        case FETCH_PRODUCT:
            return {
                ...state,
                [action.data.product_id]: {
                    productId: action.data.product_id,
                    price: action.data.price,
                    quantity: action.data.quantity,
                    totAmount: action.data.tot_amount,
                    deliveryCharge: action.data.delivery_charge,
                    id: action.data.id
                },
                [action.data.id]: {
                    productId: action.data.product_id,
                    price: action.data.price,
                    quantity: action.data.quantity,
                    totAmount: action.data.tot_amount,
                    deliveryCharge: action.data.delivery_charge,
                    id: action.data.id
                }
            };
        case CHECKOUT:
            return {};
        case REMOVE:
            const ids = Object.keys(state).filter(k => state[k].id === action.id);
            const nextState = state;
            ids.forEach(id => delete nextState[id]);
            return nextState;
        default:
            return state;
    }
};

export const getCartProduct = (state, id) => state.hasOwnProperty(id) && state[id];