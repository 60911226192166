import React, {
    useState,
    useEffect
} from 'react';
import { connect } from 'react-redux';
import {
    Dialog,
    makeStyles,
    DialogActions,
    DialogContent,
    Grid,
    ButtonGroup,
    DialogTitle
} from '@material-ui/core';
import {
    Add,
    Remove
} from '@material-ui/icons';
import {
    Button,
    Textfield,
    Typography
} from '../../commonComponents';
import {
    getInventory,
} from '../../redux/reducers';
import {
    update
} from '../../redux/actions/inventory/Update';
import {
    Delete
} from '../../redux/actions/inventory/Delete';

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: '0 auto',
        width: '100%',
        color: theme.palette.secondary.dark,
    },
}));

const UpdateInventory = ({
    open,
    onSave,
    name,
    c,
    inventory,
    id,
    onClose,
    onDelete
}) => {
    const classes = useStyles();

    const [
        state,
        setState
    ] = useState({
        id: id,
        productName: name,
        Quantity: 0,
        productID: inventory && inventory.productID
    });

    useEffect(
        () => {
            setState({
                id: id,
                productName: name,
                count: c,
                productID: inventory && inventory.productID,
                Quantity: 0
            });
        },
        [
            id, name, c, inventory
        ]
    );

    if (!open) return null;

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                fullScreen
            // aria-labelledby="alert-dialog-title"
            // aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{`Update ${name} inventory`}</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='stretch'
                        spacing={3}
                    >
                        <Textfield
                            id="update-inventory"
                            label={name}
                            defaultValue={name}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <br />
                        <Textfield
                            id="update-quantity"
                            label='Quantity'
                            defaultValue={state.count}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <br />
                        <Typography
                            title='Enter number for increase or decrease inventory'
                            color='primary'
                        />
                        <ButtonGroup color='primary'>
                            <Button
                                onClick={
                                    () => setState({ ...state, Quantity: state.Quantity + 1 })
                                }
                                Icon={Add}
                            />
                            <Button
                                className={classes.buttonGroup}
                                buttonText={'' + state.Quantity}
                            />
                            <Button
                                onClick={
                                    () => setState({ ...state, Quantity: state.Quantity - 1 })
                                }
                                Icon={Remove}
                                disabled={state.Quantity + state.count === 0}
                            />
                        </ButtonGroup>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onDelete(state);
                            onClose();
                        }}
                        color="primary"
                        buttonText='Delete'
                    />
                    <Button
                        onClick={() => {
                            onSave(state);
                            onClose();
                        }}
                        color="primary"
                        buttonText='Update'
                    />
                    <Button
                        onClick={onClose}
                        color="primary"
                        buttonText='Cancel'
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    inventory: getInventory(state, ownProps.id),
    // name: getProductName(state, ownProps.id)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSave: state => dispatch(
        (update)(state)
    ),
    onDelete: state => dispatch(
        (Delete)(state)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInventory);
