import React, {
    useEffect,
    useRef
} from 'react';
import {
    Provider
} from 'react-redux';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
    SnackbarProvider
} from 'notistack';
import {
    IconButton,
    MuiThemeProvider,
    createMuiTheme,
    withStyles
} from '@material-ui/core';
import {
    Close
} from '@material-ui/icons';
import {
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import Routes from "routes";
import Notifications from './components/notification';
import {
    saveState
} from './services/LocalStorage';
import {
    fetchAll
} from './redux/actions/category/Fetch';
import {
    fetchAll as fetchProductsList
} from './redux/actions/product/Fetch';

const theme = createMuiTheme(
    {
        palette: {
            primary: {
                main: '#1b5e20',
                dark: '#37474f',
            },
            secondary: {
                main: '#aed581',
                dark: '#33691E',
                light: '#dcedc8'
            },
            info: {
                main: '#364e59'
            }
        },
        typography: {
            fontFamily: 'Lucida Console',
            useNextVariants: true,
        },
    }
);

const styles = {
    '@global': {
        'html, body, #root': {
            width: '100%',
            height: 'auto',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            overflowX: 'hidden',
            padding: 0,
            margin: 0,
            fontFamily: 'Lucida Console'
        },
    },
};

const App = ({ store }) => {
    const notistackRef = useRef(null);

    useEffect(
        () => {
            const f = () => saveState(store.getState());

            return store.subscribe(f);
        }
    );

    useEffect(() => {
        store.dispatch(fetchAll());
    }, [store]);

    useEffect(() => {
        store.dispatch(fetchProductsList());
    }, [store]);

    return (
        <Provider
            store={store}
        >
            <Router>
                <MuiThemeProvider
                    theme={theme}
                >
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                    >
                        <SnackbarProvider
                            ref={notistackRef}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            disableWindowBlurListener
                            dense
                            autoHideDuration={1618}
                            action={
                                key => (
                                    <IconButton
                                        onClick={
                                            () => notistackRef.current.closeSnackbar(key)
                                        }
                                    >
                                        <Close />
                                    </IconButton>
                                )
                            }
                        >
                            <Routes />
                            <Notifications />
                        </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </Router>
        </Provider>
    );
};

export default withStyles(styles)(App);