import React from 'react';
import {
    useSelector
} from 'react-redux';
import _ from 'lodash';
import {
    makeStyles,
    List,
    ListItem,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';
import {
    ExpandMore
} from '@material-ui/icons';
import {
    PromoListItemText
} from '../../commonComponents';
import {
    getUserPromoList
} from '../../redux/reducers';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        expansion: {
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px #9ccc65, 0px 1px 3px 0px #33691e'
        },
        hide: {
            display: 'none'
        }
    }
    ));

export default () => {
    const classes = useStyles();
    const promos = useSelector(getUserPromoList).filter(({ status }) => status);

    if (_.isEmpty(promos)) return null;

    return (
        <Accordion
            className={classes.expansion}
        >
            <AccordionSummary
                expandIcon={
                    <ExpandMore />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography
                    color='primary'
                >
                    Your Promos
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List
                    className={classes.root}
                >
                    {
                        promos.map(
                            (p, index) => (
                                <ListItem
                                    key={index}
                                >
                                    <PromoListItemText
                                        promo={p.promo}
                                        userLimit={p.userLimit}
                                    />
                                </ListItem>
                            )
                        )
                    }
                </List>
            </AccordionDetails>
        </Accordion>

    );
}
