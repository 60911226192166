import React from 'react';
import {
    connect
} from 'react-redux';
import {
    Dialog
} from '@material-ui/core';
import Cart from './Cart';
import {
    getOrderWithCart
} from '../../redux/reducers';

const mapStateToProps = (state, ownProps) => ({
    ...getOrderWithCart(state, ownProps.id)
});

export default connect(
    mapStateToProps
)(
    ({
        open,
        onClose,
        cart,
        order,
        ...props
    }) => {
        if (!order) {
            return null;
        }

        return (
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                aria-labelledby="responsive-dialog-title"
            >
                <Cart
                    cart={cart}
                    order={order}
                    onClose={onClose}
                />
            </Dialog>
        );
    }
);