import {
    DELETE
} from '../../../consts/inventory/Delete';
import {
    apiAction
} from '../Helper';

export const Delete = ({
    id
}) => apiAction(
    {
        url: '/api/inventory/remove/' + id,
        method: 'POST',
        onSuccess: () => ({
            type: DELETE,
            id,
        }),
        label: DELETE
    }
);