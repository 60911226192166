import React from 'react';
import {
    makeStyles,
    TextField,
    FormControl
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(
    theme => (
        {
            formControl: {
                margin: '0 auto',
                width: '100%',
                color: theme.palette.secondary.dark,
            }
        }
    )
);

export default ({
    label,
    value,
    onClick,
    multiple,
    options,
    required
}) => {
    const classes = useStyles();

    return (
        <FormControl
            required={
                required
                    ?
                    true
                    :
                    false
            }
            className={classes.formControl}
        >
            <Autocomplete
                multiple={
                    multiple
                        ?
                        true
                        :
                        false
                }
                options={options}
                getOptionLabel={
                    o => o.name || ''
                }
                getOptionSelected={
                    (a, b) => a.id === b.id
                }
                renderInput={
                    params => <TextField
                        {...params}
                        variant='outlined'
                        label={label}
                        required={
                            required
                                ?
                                true
                                :
                                false
                        }
                        margin="normal"
                    />
                }
                onChange={onClick}
                value={value}
            />
        </FormControl>
    );
};