import React, {
	Fragment
} from 'react';
import {
	Grow,
	ClickAwayListener,
	Paper,
	Popper,
	MenuItem,
	MenuList,
	makeStyles
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
	Link,
	IconButton
} from '../../commonComponents';

const useStyles = makeStyles(
	theme => (
		{
			color: {
				color: theme.palette.primary.main,
				textDecoration: 'none',
				textTransform: 'capitalize',
				minWidth: 'auto'
			},
			paper: {
				backgroundColor: '#dcedc8',
				minWidth: 100,
				color: theme.palette.primary.main
			},
			popper: {
				zIndex: 500,
			}
		}
	)
);

export default props => {
	const classes = useStyles();
	const [
		open,
		setOpen
	] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(
		() => {
			if (prevOpen.current === true && open === false) {
				anchorRef.current.focus();
			}

			prevOpen.current = open;
		}, [
		open
	]
	);

	return (
		<Fragment>
			<IconButton
				ref={anchorRef}
				aria-controls={
					open
						?
						'menu-list-grow'
						:
						undefined
				}
				aria-haspopup="true"
				onClick={handleToggle}
				Icon={AccountCircle}
				className={classes.color}
			/>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				transition
				className={classes.popper}
			>
				{
					(
						{
							TransitionProps,
							placement
						}
					) => (
							<Grow
								{...TransitionProps}
								style={
									{
										transformOrigin: placement === 'bottom'
											?
											'center top'
											:
											'center bottom'
									}
								}
							>
								<Paper
									className={classes.paper}
								>
									<ClickAwayListener
										onClickAway={handleClose}
									>
										<MenuList
											autoFocusItem={open}
											className={classes.color}
											id="menu-list-grow"
											onKeyDown={handleListKeyDown}
										>
											<Link
												to="/userprofile"
												className={classes.color}
											>
												<MenuItem
													onClick={handleClose}
												>
													{props.name}
												</MenuItem>
											</Link>
											<MenuItem
												className={classes.color}
												onClick={props.onClick}
											>
												{props.button}
											</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)
				}
			</Popper>
		</Fragment>
	);
}