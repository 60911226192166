import {
    READ
} from '../../../consts/StaticContent';
import {
    apiAction
} from '../Helper';

export const readBySlug = slug => apiAction({
    url: '/api/staticcontent/getslug/' + slug,
    onSuccess: data => ({
        type: READ,
        data: {
            static_content: data.static_content,
            content_slug: data.content_slug,
            id: data.id
        }
    }),
    label: READ
});