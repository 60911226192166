import {
    ALL
} from '../../../consts/category/Fetch';
import {
    apiAction
} from '../Helper';

export const fetchAll = () => apiAction(
    {
        url: '/api/category/info/all',
        onSuccess: data => ({
            type: ALL,
            data
        }),
        label: ALL
    }
);