import {
    CREATE_CART,
    CREATE_PRODUCT
} from '../../../consts/cart/Create';
import {
    apiAction,
    thunk
} from '../Helper';
import {
    updateCart
} from './Update';
import {
    getCartIsFetching
} from '../../reducers';

export const createCart = userId => apiAction(
    {
        url: '/api/cart',
        method: 'POST',
        data: {
            user_id: userId,
            customer_promo: '',
            status: 'new',
            cart_products: [],
            tot_amount: 0,
            delivery_charge: 0,
        },
        onSuccess: data => ({
            type: CREATE_CART,
            data: {
                ...data,
                TDC: 0
            }
        }),
        label: CREATE_CART,
        race: getCartIsFetching
    }
);

export const createProduct = (
    product,
    cart
) => apiAction(
    {
        url: '/api/cartproduct',
        method: 'POST',
        data: {
            product_id: product.productId,
            // promo: product.promo,
            price: product.price,
            quantity: product.quantity,
            delivery_charge: product.deliveryCharge,
            tot_amount: product.totAmount
        },
        onSuccess: thunk(
            [
                data => ({
                    type: CREATE_PRODUCT,
                    data
                }),
                data => updateCart(
                    {
                        ...cart,
                        totAmount: cart.totAmount + product.price * product.quantity,
                        TDC: cart.TDC + product.deliveryCharge * product.quantity,
                        deliveryCharge: Math.min(cart.TDC + product.deliveryCharge * product.quantity, 240),
                        cartProducts: (
                            cart
                            &&
                            Array.isArray(cart.cartProducts)
                            &&
                            [...cart.cartProducts, data.id]
                        )
                            ||
                            [data.id]
                    }
                )
            ]
        ),
        label: CREATE_PRODUCT,
        race: getCartIsFetching
    }
);