import React, {
	useState,
	useEffect
} from 'react';
import {
	connect
} from 'react-redux';
import {
	AppBar,
	CssBaseline,
	Toolbar,
	Badge,
	makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Tile from './admin/Tile';
import Drawer from './admin/Drawer';
import {
	IconButton,
	Divider,
	Button,
	Link
} from '../../commonComponents';
import {
	getNewOrder
} from '../../redux/reducers';
import {
	logout
} from '../../redux/actions/user/Login';
import {
	fetchNewOrder
} from '../../redux/actions/order/Fetch';
import Icon from '../../assets/icon.png';

const drawerWidth = 240;

const useStyles = makeStyles(
	theme => (
		{
			root: {
				display: 'flex',
				margin: '85px auto',
				overflowX: 'auto'
			},
			drawer: {
				[theme.breakpoints.up('sm')]: {
					width: drawerWidth,
					flexShrink: 0,
				},
			},
			appBar: {
				marginLeft: drawerWidth,
				backgroundColor: theme.palette.secondary.main,
				[theme.breakpoints.up('sm')]: {
					width: `calc(100% - ${drawerWidth}px)`,
				},
			},
			menuButton: {
				marginLeft: theme.spacing(2),
				[theme.breakpoints.up('sm')]: {
					display: 'none',
				},
				color: theme.palette.primary.dark,
			},
			toolbar: {
				backgroundColor: theme.palette.secondary.main,
			},
			drawerPaper: {
				width: drawerWidth,
			},
			content: {
				margin: '5px auto',
				flexGrow: 1,
				backgroundColor: theme.palette.background.default,
				// padding: theme.spacing(3),
				height: '100%',
			},
			divider: {
				margin: '20px auto',
			}
		}
	)
);

const ClippedDrawer = ({
	children,
	logout,
	fetchNewOrder,
	newOrders,
	...props
}) => {
	const classes = useStyles();

	useEffect(
		() => {
			fetchNewOrder();
			const interval = setInterval(() => fetchNewOrder(), 60000);
			return () => clearInterval(interval);
		}, [
		fetchNewOrder
	]
	);

	const [
		mobileOpen,
		setMobileOpen
	] = useState(false);

	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen);
	}

	return (
		<div
			className={classes.root}
		>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={classes.appBar}
			>
				<Toolbar
					className={classes.toolbar}
				>
					<Link
						to="/"
					>
						<img
							alt="gaachwala logo"
							src={Icon}
							height="50"
							width="50"
							className={classes.image}
						/>
					</Link>

					<IconButton
						color="inherit"
						aria-label="open drawer"
						//edge="start"
						onClick={handleDrawerToggle}
						className={classes.menuButton}
						Icon={MenuIcon}
					/>
					<Badge
						badgeContent={newOrders}
						color="primary"
					>
						<Link
							to="/new-orders"
						>
							<Button
								variant='text'
								color="primary"
								buttonText='New Orders'
							/>
						</Link>
					</Badge>

					<Link
						title="Login"
						color="inherit"
						to="/"
						onClick={logout}
					>
						<Button
							color="primary"
							variant='text'
							buttonText='Logout'
						/>
					</Link>
				</Toolbar>
			</AppBar>
			<Drawer
				onClose={handleDrawerToggle}
				open={mobileOpen}
			/>
			<main
				className={classes.content}
			>
				<Tile />

				<Divider
					className={classes.divider}
				/>

				{children}

			</main>
		</div>
	);
};

const mapStateToProps = state => ({
	newOrders: getNewOrder(state)
});

const mapDispatchToProps = {
	logout,
	fetchNewOrder
}

export default connect(mapStateToProps, mapDispatchToProps)(ClippedDrawer);