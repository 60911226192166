import {
    FETCH_ALL_USER,
    FETCH_USER_COUNT
} from '../../../consts/user/Fetch';
import {
    DELETE_USER
} from '../../../consts/user/Update';

export default (
    state = {
        users: [],
        count: 0
    },
    action
) => {
    switch (action.type) {
        case FETCH_ALL_USER:
            return {
                ...state,
                users: action.data.all_user.filter(
                    ({
                        deleted
                    }) => !deleted
                ),
                // count: action.data.all_user.filter(({deleted}) => !deleted).length
            };
        case FETCH_USER_COUNT:
            return {
                ...state,
                count: action.data.count
            }
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter(
                    ({
                        id
                    }) => action.id !== id
                ),
                count: state.users.filter(
                    ({
                        id
                    }) => action.id !== id).length
            };
        default:
            return state;
    }
};

export const getAllUsers = state => state.users;

export const getUserCount = state => state.count;

export const getEmailById = (state, id) => {
    const email = state.users.find(u => u.id === id) || 'User not found';
    return email.email || 'User not found';
};