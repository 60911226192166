import React, {
    useState
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
    withStyles
} from '@material-ui/core';

const styles = theme => (
    {
        div: {
            position: 'relative',
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
            padding: 5,
            margin: '20px 10px',
            [theme.breakpoints.down('md')]: {
                margin: '20px 2px'
            },
        }
    }
);

const ZoomImage = props => {
    const [
        x,
        setX
    ] = useState(0);
    const [
        y,
        setY
    ] = useState(0);

    let [mouseMove, cancel] = useDebouncedCallback((clientX, clientY) => {
        if (!document.getElementById(props.id + '-div')) return;
        var box = document.getElementById(props.id + '-div').getBoundingClientRect();
        let xLim = (box.right - box.left) / 2.0 - 20;
        let yLim = (box.bottom - box.top) / 2.0 - 20;
        let x = (box.left + box.right) / 2.0 - clientX;
        let y = (box.top + box.bottom) / 2.0 - clientY;
        if (x < -xLim) x = -xLim;
        else if (x > xLim) x = xLim;
        if (y < -yLim) y = -yLim;
        else if (y > yLim) y = yLim;
        setX(x);
        setY(y);
    }, 50, [], { maxWait: 100 })

    const { classes } = props;

    return (
        <div className={classes.div}
            id={props.id + '-div'}
            onMouseMove={
                e => {
                    mouseMove(e.clientX, e.clientY);
                }
            }
            onMouseLeave={
                () => {
                    setX(0);
                    setY(0);
                    cancel();
                }
            }
        >
            <img
                alt={props.alt}
                src={props.src}
                id={props.id}
                height={props.height}
                width={props.width}
                style={{
                    position: 'relative',
                    transition: 'all .7s ease-in-out',
                    transform: x === 0 ? 'scale(1)' : 'scale(2)',
                    left: x + 'px',
                    top: y + 'px',
                    maxHeight: '100%',
                    maxWidth: '100%',
                    margin: '0 auto',
                }}
            />
        </div>
    );
};

export default withStyles(styles)(ZoomImage);