import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    withStyles,
    Grid,
    Divider,
    useTheme,
    Link
} from '@material-ui/core';
import {
    Copyright,
} from '@material-ui/icons';
import {
    contact,
    developer
} from './Data';
import {
    Typography
} from '../../commonComponents'; //typography is used for copyright that's why imported as banner
import Logo from '../logo/index';
import Logos from '../logos/index';
import LinkToStaticPages from './LinkToStaticPages';

const styles = theme => (
    {
        root: {
            height: 'auto',
            width: '100%',
            color: theme.palette.primary.main,
            margin: '0 auto',
            backgroundColor: '#aed581'
        },
        copy1: {
            //fontSize: '12px',
            color: theme.palette.primary.main,
            textAlign: 'right',
            paddingTop: 15,
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                padding: 0,
            },
            paddingRight: 25,
        },
        copyrightIcon: {
            width: '12px',
            height: '12px'
        },
        logos: {
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        contact: {
            lineHeight: '1.6'
        },
        item: {
            textAlign: 'center',
            //padding: 5,
            // [theme.breakpoints.down('sm')]: {
            //     textAlign: 'center',
            // },
            paddingBottom: 15,
        },
        divider: {
            width: '80%',
            margin: '10px auto',
            // padding: 5,
        },
        dividerdeveloper: {
            width: '80%',
            margin: '10px auto',
            [theme.breakpoints.up('md')]: {
                width: '50%',
            },
            [theme.breakpoints.up('lg')]: {
                width: '30%',
            },
            // padding: 5,
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.secondary.dark,
        }
    }
);

const Footer = ({
    classes,
}) => {
    const theme = useTheme();

    return (
        <Grid
            container
            direction="row"
            justify="center"
            spacing={6}
            className={classes.root}
        >
            <Grid
                item
                xs={12}
                sm={3}
                className={classes.item}
            >
                <Logo />
                <Typography
                    variant="h6"
                    color="inherit"
                    style={{
                        paddingLeft: 5,
                        paddingTop: 5,
                    }}
                    title='গাছ লাগাই, ঢাকা বাঁচাই।'
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={4}
                className={classes.item}
            >
                <LinkToStaticPages />
            </Grid>
            <Grid
                item
                xs={12}
                sm={5}
                className={
                    clsx(
                        classes.contact,
                        classes.item
                    )
                }
            >
                {
                    contact.map(
                        (k, index) => (
                            <Typography
                                key={index}
                                variant={k.variant}
                                color="inherit"
                                gutterBottom
                                title={k.text}
                                IconLeft={
                                    k.Icon
                                        ?
                                        k.Icon
                                        :
                                        null
                                }
                                iconStyle={{
                                    fontSize: '20px',
                                    color: theme.palette.primary.main,
                                    verticalAlign: 'middle',
                                    paddingRight: '5px',
                                }}
                            />
                        )
                    )
                }
                <Divider
                    variant="middle"
                    className={classes.dividerdeveloper}
                />
                {
                    developer.map(
                        (k, index) => (
                            <Typography
                                key={index}
                                variant={k.variant}
                                component={index === 0 ? Link : k.variant}
                                display='block'
                                href={index === 0 ? 'http://www.aubichol.com/' : ''}
                                color="inherit"
                                gutterBottom
                                title={k.text}
                                IconLeft={
                                    k.Icon
                                        ?
                                        k.Icon
                                        :
                                        null
                                }
                                iconStyle={{
                                    fontSize: '20px',
                                    color: theme.palette.primary.main,
                                    verticalAlign: 'middle',
                                    paddingRight: '5px',
                                }}
                            />
                        )
                    )
                }
            </Grid>
            <Divider
                variant="middle"
                className={classes.divider}
            />
            <Grid
                item
                container
                justify='flex-end'
                alignContent='center'
                xs={12}
                sm={12}
            >
                <Grid
                    item
                    xs={12}
                    md={4}
                    className={classes.logos}
                >
                    <Logos />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={8}
                    className={classes.copy1}
                >
                    <Typography
                        variant='body2'
                        color="inherit"
                    >
                        Copyright
                        <Copyright
                            className={classes.copyrightIcon}
                        />
                        {`2020 Gaachwala. All rights reseved.
                        Developed by `}
                        <a
                            href="http://www.aubichol.com"
                            className={classes.link}
                        >
                            Aubichol Intelligent Technologies.
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);