import React from 'react';
import {
	Checkbox,
	FormControlLabel,
	FormControl,
} from '@material-ui/core';

const ChecboxForFeaturedProduct = ({
	onClick,
	value,
	label
}) => (
		<FormControl
			component="fieldset"
		>
			<FormControlLabel
				control={
					<Checkbox
						color="primary"
						checked={value}
						onChange={
							() => onClick(!value)
						}
					/>
				}
				label={label}
				labelPlacement="end"
			// {...props}
			/>
		</FormControl>
	);

export default ChecboxForFeaturedProduct;