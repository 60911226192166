import React from "react";
import { 
    DatePicker 
} from "@material-ui/pickers";

const Date_Picker = props => (   
    <DatePicker
        fullWidth
        clearable
        inputVariant="outlined"
        disableFuture
        openTo="year"
        format="dd/MM/yyyy"
        label="Date of birth"
        views={
            [
                "year", 
                "month", 
                "date"
            ]
        }
        {...props}
    />
);

export default Date_Picker;