import React, { 
    Fragment 
} from "react";
import { 
    withStyles 
} from '@material-ui/core';
import {
    Button
} from '../../commonComponents';
import logos from './Data';

const styles = () => (
    {
        icon: {
            color: '#1b5e20',
            minWidth: 'auto',
            filter: 'grayscale(100%) contrast(100%)',
            '&:hover': {
                filter: 'none',
            },
            transition: 'filter .7s ease-in',
        },
    }
);

const Logos = ({
    classes,
}) => (
    <Fragment>
        {
            logos.map(
                (i, index) => (
                    <Button 
                        key={index}
                        variant='text'
                        className={classes.icon} 
                        href={i.href}
                        title={`Gaachwala on ${i.name}`}
                    >
                        <img 
                            src={i.logo} 
                            alt={i.name}
                            width="20px" 
                            height="20px"
                        />
                    </Button>
                )
            )
        }
    </Fragment>
)

export default withStyles(styles)(Logos);