import React from 'react';
import PropTypes from 'prop-types';
import {
	Drawer,
	Hidden,
	makeStyles,
	useTheme,
} from '@material-ui/core';
import AdminDrawer from './AdminDrawer';

const drawerWidth = 240;

const useStyles = makeStyles(
    theme => (
        {
            drawer: {
                [theme.breakpoints.up('sm')]: {
                    width: drawerWidth,
                    flexShrink: 0,
                },
            },
            drawerPaper: {
                width: drawerWidth,
            },
        }
    )
);

const Drawers = ({
    container,
    open,
    onClose,
    ...props
}) => {
	const classes = useStyles();
    const theme = useTheme();
    
	return (
        <nav 
            className={classes.drawer} 
            aria-label="admin-drawer"
        >
            <Hidden 
                smUp 
                implementation="css"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={
                        theme.direction === 'rtl' 
                        ? 
                        'right' 
                        : 
                        'left'
                    }
                    open={open}
                    onClose={onClose}
                    transitionDuration={700}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <AdminDrawer />
                </Drawer>
            </Hidden>

            <Hidden 
                xsDown 
                implementation="css"
            >
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <AdminDrawer />
                </Drawer>
            </Hidden>
        </nav>
	);
};

Drawers.propTypes = {
	container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
}; 

export default Drawers;