import React, {
    useEffect
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    Redirect,
    useParams
} from 'react-router-dom';
import {
    accountVerify
} from '../../redux/actions/AccountVerify';
import {
    getIsLoggedIn
} from '../../redux/reducers';

export default props => {
    const loggedIn = useSelector(getIsLoggedIn);
    const dispatch = useDispatch();
    const {key} = useParams();

    console.log("id ", key);

    useEffect(
        () => {
            dispatch(accountVerify(key));
        }
    );

    let to = "/login";

    if(loggedIn) to = "/userprofile";

    return <Redirect to={to} />;
}