import {
    useEffect
} from 'react';
import {
    connect
} from 'react-redux';
import {
    useSnackbar
} from 'notistack';
import {
    getNotification
} from '../../redux/reducers';
import {
    updateNotification
} from '../../redux/actions/Notifications';

const mapStateToProps = state => (
    {
        newNotifications: getNotification(state)
    }
);

const mapDispatchToProps = {
    updateNotification
};

const Notification = (
    {
        newNotifications,
        updateNotification
    }
) => {
    const {
        enqueueSnackbar
    } = useSnackbar();

    useEffect(
        () => {
            if(!Array.isArray(newNotifications)) {
                return;
            }

            newNotifications.forEach(
                v => {
                    enqueueSnackbar(
                        v.message, 
                        {
                            variant: v.variant,
                            key: v.id,
                            autoHideDuration: v.autoHideDuration || 1618
                        }
                    );
                }
            );
        },
        [
            newNotifications, 
            enqueueSnackbar
        ]
    );

    useEffect(
        () => {
            if(!Array.isArray(newNotifications)) {
                return;
            }

            newNotifications.forEach(
                v => {
                    updateNotification(v.id);
                }
            );
        }, 
        [
            newNotifications, 
            updateNotification
        ]
    );

    return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);