import {
    combineReducers
} from 'redux';
import {
    CREATE_CART,
    CREATE_PRODUCT
} from '../../../consts/cart/Create';
import {
    FETCH_CART,
    FETCH_PRODUCT
} from '../../../consts/cart/Fetch';
import {
    UPDATE_CART,
    UPDATE_PRODUCT
} from '../../../consts/cart/Update';
import {
    CHECKOUT,
    ADD,
    REMOVE,
    UPDATE
} from '../../../consts/cart';
import {
    API_START,
    API_END
} from '../../../consts/Api';

const valid = v => [
    CREATE_CART,
    FETCH_CART,
    UPDATE_CART,
    API_START,
    API_END,
    CREATE_PRODUCT,
    CHECKOUT,
    FETCH_PRODUCT,
    UPDATE_PRODUCT,
    ADD,
    REMOVE,
    UPDATE
].indexOf(v) !== -1;

const defaultState = {
    id: '',
    status: '',
    cartProducts: [],
    totAmount: 0,
    deliveryCharge: 0,
    TDC: 0
}

const isFetching = (
    state = false,
    action
) => {
    switch (action.type) {
        case API_START:
            if (valid(action.payload)) {
                return true;
            }

            return state;
        case API_END:
            if (valid(action.payload)) {
                return false;
            }

            return state;
        default:
            return state;
    }
};

const cart = (
    state = {},
    action
) => {
    switch (action.type) {
        case CREATE_CART:
        case UPDATE_CART:
            return {
                ...state,
                id: action.data.id,
                promoCode: action.data.promo_code,
                status: action.data.status,
                cartProducts: action.data.cart_products,
                totAmount: action.data.tot_amount,
                deliveryCharge: action.data.delivery_charge,
                TDC: action.data.TDC,
                userId: action.data.user_id,
                discount: action.data.discount
            };
        case CHECKOUT:
            return {
                ...state,
                defaultState
            };
        case FETCH_CART:
            return {
                ...state,
                id: action.data.id,
                promoCode: action.data.promo_code,
                status: action.data.status,
                cartProducts: action.data.cart_products,
                totAmount: action.data.tot_amount,
                deliveryCharge: action.data.delivery_charge,
                discount: action.data.discount,
                userId: action.data.user_id || action.data.customer_id
            }
        default:
            return state;
    }
};

const carts = (
    state = {},
    action
) => {
    if (!valid(action.type)) {
        return state;
    }
    if (('data' in action) && ('id' in action.data)) {
        return {
            ...state,
            [action.data.id]: cartIndividual(state[action.data.id] || {}, action)
        };
    }

    return state;
}

const cartIndividual = combineReducers(
    {
        isFetching,
        cart
    }
);

export default carts;

export const getCart = state => state;

export const getIsFetching = (state, id) => state.hasOwnProperty(id) && state[id].isFetching;

export const getIds = state => state.ids;

export const getCartById = (state, id) => (state.hasOwnProperty(id) && state[id].cart) || defaultState;

export const getTotalAmountByID = (state, id) => state.hasOwnProperty(id) && (state[id].cart.totAmount + state[id].cart.deliveryCharge - state[id].cart.discount);