import {
    DELETE
} from '../../../consts/category/Delete';
import { 
    apiAction 
} from '../Helper';

export const deleteCategory = id => apiAction(
    {
        url: '/api/category/remove/' + id,
        method: 'POST',
        onSuccess: () => ({
            type: DELETE,
            id,
        }),
        label: DELETE,
    }
);