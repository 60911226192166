import {
    combineReducers
} from 'redux';
import login, * as fromLogin from './Login';
import profile, * as fromProfile from './Profile';
import admin, * as fromAdmin from './Admin';
import {
    API_START,
    API_END
} from '../../../consts/Api';
import {
    LOGIN
} from '../../../consts/user/Login';
import {
    PROFILE, 
    ID
} from '../../../consts/user/Profile';
import {
    FETCH_ALL_USER, 
    FETCH_USER_COUNT
} from '../../../consts/user/Fetch';

const isFetching = (
    state = false, 
    action
) => {
    switch(action.type) {
        case API_START:
            if([
                LOGIN,
                PROFILE, 
                FETCH_ALL_USER,
                FETCH_USER_COUNT,
                ID
            ].indexOf(action.payload) !== -1) return true;
            return state;
        case API_END:
            if([
                LOGIN,
                PROFILE, 
                FETCH_USER_COUNT,
                FETCH_ALL_USER,
                ID
            ].indexOf(action.payload) !== -1) return false;
            return state;    
        default:
            return state;
    }
};

export default combineReducers({
    login,
    profile,
    admin,
    isFetching
});

export const getIsFetching = state => state.isFetching;

export const getToken = state => fromLogin.getToken(state.login);

export const getProfile = state => fromProfile.getProfile(state.profile);

export const getUserId = state => fromProfile.getUserId(state.profile);

export const getUsers = state => fromAdmin.getAllUsers(state.admin);

export const getProfileById = (state, id) => getUsers(state).find(u => u.id === id);

export const getCount = state => fromAdmin.getUserCount(state.admin);

export const getUserName = state => fromProfile.getUserName(state.profile);

export const getEmail = state => fromProfile.getEmail(state.profile);

export const getEmailById = (state, id) => fromAdmin.getEmailById(state.admin, id);