import React from 'react';
import {
    data,
} from './Data';
import {
    Typography,
    Link
} from '../../commonComponents'; //typography is used for copyright that's why imported as banner

const LinkToStaticPages = () => (
    data.map(
        (f, index) => (
            <Link 
                key={index}
                to={f.link}
            >
                <Typography 
                    variant="body1"
                    color="primary"
                    title={f.text}
                />      
            </Link>
        )
    )
);

export default LinkToStaticPages;