import React, {
    Fragment,
    useRef,
    useState,
    useEffect
} from 'react';
import { 
    Button, 
    Snackbar 
} from '@material-ui/core';
import { 
    Alert 
} from '@material-ui/lab';
import * as serviceWorker from './serviceWorker';

const SnackBar = ({
    open,
    update,
    cancel
}) => (
    <Snackbar
        open={open}
    >
        <Alert 
            color="info"
            action={
                <Fragment>
                    <Button 
                        size="small"
                        onClick={update}
                    >
                        Update
                    </Button>
                    <Button 
                        size="small"
                        onClick={cancel}
                    >
                        Dismiss
                    </Button>
                </Fragment>
            }
        >
            New version available.
        </Alert>
    </Snackbar>
);

const UpdateChecker = props => {
    const [update, setUpdate] = useState(false);
    const refresh = useRef(null);
    const interval = useRef(null);

    useEffect(
        () => {
            serviceWorker.register({
                onUpdate: () => setUpdate(true),
                onSuccess: () => {}
            });

            if('serviceWorker' in navigator) {
                navigator.serviceWorker.oncontrollerchange = () => {
                    if(refresh.current) return;
                    refresh.current = true;

                    if(interval.current) clearInterval(interval.current);

                    window.location.reload();
                };
            }

            const updateSW = () => {
                if('serviceWorker' in navigator) {
                    navigator
                    .serviceWorker
                    .getRegistration()
                    .then(
                        registration => {
                            if(!registration) return;

                            if(registration.waiting) setUpdate(true);

                            registration.update();
                        }
                    );
                }
            };

            interval.current = setInterval(updateSW, 24 * 60 * 60 * 1000);

            return () => clearInterval(interval.current);
        },
        []
    );

    const onRefresh = () => {
        navigator
        .serviceWorker
        .getRegistration()
        .then(
            registration => {
                if(!registration) return;

                if(!registration.waiting) return;

                registration.waiting.postMessage('skip-waiting');
            }
        )
        .then(
            () => {
                refresh.current = true;
                
                if(interval.current) clearInterval(interval.current);

                window.location.reload();
            }
        );
    };

    return (
        <Fragment>
            <SnackBar 
                open={update}
                update={onRefresh}
                cancel={() => setUpdate(false)}
            />
            {props.children}
        </Fragment>
    );
};

export default UpdateChecker;