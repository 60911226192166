import {
    ALL
} from '../../consts/category/Fetch';
import {
    API_START,
    API_END
} from '../../consts/Api';
import {
    DELETE
} from '../../consts/category/Delete';

export default (
    state = {
        category: [],
        isFetching: false,
        error: null
    },
    action
) => {
    switch (action.type) {
        case ALL:
            return {
                ...state,
                category: action.data.all_category//.filter(c => c.name !== '')
            };
        case DELETE:
            return {
                ...state,
                category: state.category.filter(
                    ({
                        id
                    }) => id !== action.id
                ).map(
                    c => ({
                        ...c,
                        category_id: c.category_id === action.id ? "" : c.category_id
                    })
                )
            };
        case API_START:
            if (action.payload === ALL) return {
                ...state,
                isFetching: true,
                error: null
            };
            return state;
        case API_END:
            if (action.payload === ALL) return {
                ...state,
                isFetching: false,
                error: null
            };
            return state;
        default:
            return state;
    }
};

export const getIsFetching = state => state.isFetching;

export const getCategories = state => state.category.map(({
    id,
    name
}) => ({
    id,
    name
}));

export const getCategory = (state, id) => (
    state.category.find
        ?
        state.category.find(
            c => c.id === id
        )
        :
        {}
);

export const getCategoryTree = state => {
    if (
        !state.category
        ||
        state.category === []
        ||
        state.category === {}
        ||
        !state.category.reduce
    ) return {
        data: []
    };

    const adjacencyList = state.category.reduce(
        (
            prev, {
                category_id,
                id
            }
        ) => ({
            ...prev,
            [category_id]: Array.isArray(prev[category_id]) ? [...prev[category_id], id] : [id]
        }),
        {}
    );

    const roots = state.category.filter(
        ({
            category_id,
            id
        }) => (
                category_id === ''
                ||
                category_id === id
            )).map(
                ({
                    id
                }) => id
            );

    const build = id => ({
        name: state.category.find(c => c.id === id).name,
        id: id,
        children: Array.isArray(adjacencyList[id])
            ?
            adjacencyList[id].filter(
                i => i !== id
            ).map(
                i => build(i)
            )
            :
            undefined
    });

    return {
        data: roots.map(r => build(r))
    };
};

export const getCategorySubTreeAsArray = (state, id) => {
    const traverseToRoot = node => {
        if (node === "") return false;
        if (node === id) return true;
        const parent = state.category.find(i => i.id === node).category_id;
        if (parent === node) return false;
        return traverseToRoot(parent);
    };
    return state.category.filter(i => i.id !== id && traverseToRoot(i.id)).map(({ id, name }) => ({ id, name }));
};