import React, {
    useState
} from 'react';
import {
    useDispatch
} from 'react-redux';
import isEmail from 'validator/es/lib/isEmail';
import {
    makeStyles, 
    Grid, 
} from '@material-ui/core';
import {
    Typography,
    Button,
    Divider,
    Textfield
} from '../../commonComponents';
import {
    forgetPassword
} from '../../redux/actions/ResetPassword';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                minHeight: '90vh',
                height: 'auto',
                margin: '105px auto 0px auto',
                width: '100%'
                // flexWrap: 'wrap'
            },
            inner: {
                backgroundColor: theme.palette.secondary.main,
                padding: theme.spacing(2),
                margin: '0 auto',
                color: theme.palette.primary.dark,
                width: '100%',
            },
            input: {
                // lineHeight: theme.spacing(1.5),
                '& .MuiOutlinedInput-input': {
                    // padding: theme.spacing(1),
                },
                margin: '5px auto',
                width: '100%'
            },
            button: {
                margin: 5
            }
        }
    )
);

export default () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState('');

    const onChange = ({
        target: {
            value
        }
    }) => setValue(value);

    const submit = () => {
        dispatch(forgetPassword(value));
        setValue('');
    };

    const disabled = !isEmail(value);

    return (
        <Grid
            container
            // justify="center"
            alignItems="flex-start"
            direction='column'
            spacing={2}
            className={classes.root}
        >
            <Grid 
                item
                md={4}
                sm={10}
                className={classes.inner}
            >
                <Typography 
                    variant="h6" 
                    align='center'
                    color="inherit"
                    title="Reset password"
                />  
                <Divider />
                <Typography 
                    variant="subtitle2" 
                    color="inherit" 
                    gutterBottom 
                    paragraph={true}
                    title="Enter your email address so that we can send you a reset password link.
                    From there you will be able to set a new password and then login usually."
                />  
                <Textfield 
                    variant="outlined" 
                    className={classes.input} 
                    label="Email"
                    keyval="email"
                    onChange={onChange}
                />
            </Grid>
            <Grid 
                item
                // sm={5}
                md={4}
                sm={10}
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.inner}
            >
                <Button 
                    size="small"
                    buttonText='submit'
                    className={classes.button}
                    disabled={disabled}
                    onClick={submit}
                />
                <Button 
                    size="small"
                    buttonText='cancel'
                    className={classes.button}
                    onClick={() => setValue('')}
                />
            </Grid>
        </Grid>
    );
};