import {
    FETCH_ALL
} from '../../../consts/inventory/Fetch';
import {
    API_START,
    API_END
} from '../../../consts/Api';
import {
    DELETE
} from '../../../consts/inventory/Delete';

export default (
    state = {
        inventories: [],
        isFetching: false,
        error: null
    },
    action
) => {
    switch (action.type) {
        case FETCH_ALL:
            return {
                ...state,
                inventories: action.data//.filter(c => c.name !== '')
            };
        case DELETE:
            return {
                ...state,
                inventories: state.inventories.filter(
                    ({
                        id
                    }) => id !== action.id
                )
            };
        case API_START:
            if (action.payload === FETCH_ALL) return {
                ...state,
                isFetching: true,
                error: null
            };
            return state;
        case API_END:
            if (action.payload === FETCH_ALL) return {
                ...state,
                isFetching: false,
                error: null
            };
            return state;
        default:
            return state;
    }
};

export const getIsFetching = state => state.isFetching;

export const getInventories = state => state.inventories && state.inventories.map(({
    id,
    productID,
    count
}) => ({
    id,
    productID,
    count
}));

export const getInventory = (state, id) => (
    state.inventories &&
        state.inventories.find
        ?
        state.inventories.find(
            c => c.id === id
        )
        :
        {}
);