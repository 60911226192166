import axios from "axios";
import { 
	API 
} from "../../consts/Api";
import {
	BACKEND_URL
} from '../../consts/Backend';
import { 
	accessDenied, 
	apiError, 
	apiStart, 
	apiEnd 
} from "../actions/Api";
import {
	appendNotification
} from '../actions/Notifications';
import {
	getToken,
	getAuth
} from '../reducers';

const apiMiddleware = ({ 
	dispatch, 
	getState 
}) => next => action => {
	next(action);

	if (action.type !== API) return;

	const {
		url,
		method,
		data,
		onSuccess,
		onFailure,
		label,
		headers,
		race
	} = action.payload;
	const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

	const params = (data && Object.keys(data).map(k => data[k])) || [];

	const admin = getAuth(getState()).admin;

	if(typeof race === 'function')
		if(race(getState(), ...params)) return;

	const actionWith = resp => Object.assign({}, data, resp);

	// axios default configs
	axios.defaults.baseURL = BACKEND_URL;
	axios.defaults.headers.common["Content-Type"] = "application/json";
	axios.defaults.headers.common["Authorization"] = getToken(getState());

	if (label) {
		dispatch(apiStart(label, data));
	}

	axios
		.request({
			url,
			method,
			headers,
			[dataOrParams]: data
		})
		.then(({ data }) => {
			if(typeof onSuccess === 'function')dispatch(onSuccess(actionWith(data)));
			if(admin && method !== 'GET') {
				dispatch(appendNotification({
					message: label + ': success',
					variant: 'success'
				}));
			}
		})
		.catch(error => {
			dispatch(apiError(error));

			if(typeof onFailure === 'function')dispatch(onFailure(error));

			if (error.response && error.response.status === 403) {
				dispatch(accessDenied(window.location.pathname));
			}

			if(admin && method !== 'GET') {
				dispatch(appendNotification({
					message: label + ': failed, please try again.',
					variant: 'error'
				}));
			}
		})
		.finally(() => {
			if (label) {
				dispatch(apiEnd(label, data));
			}
		});
};

export default apiMiddleware;