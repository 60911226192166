import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    ButtonGroup,
    Grid,
    makeStyles
} from '@material-ui/core';
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    RemoveCircle
} from '@material-ui/icons';
import {
    Button,
    DeleteConfirmation,
    IconButton,
    Textfield,
    Typography
} from '../../commonComponents';
import {
    update
} from '../../redux/actions/staticContent/Update';

import {
    readBySlug
} from '../../redux/actions/staticContent/Read';
import {
    getStaticContent
} from '../../redux/reducers';

const useStyles = makeStyles(theme => ({
    padding: {
        padding: theme.spacing(1)
    },
    margin: {
        margin: theme.spacing(1)
    }
}));

const SLUG = 'banner';

const BannerEdit = props => {
    const classes = useStyles();
    const staticBanner = useSelector(
        state => getStaticContent(state, SLUG)
    );
    const [banner, setBanner] = useState(staticBanner.content);
    const [selectToDelete, setSelectToDelete] = useState(-1);
    const dispatch = useDispatch();
    const dd = useRef(null);

    useEffect(
        () => {
            dispatch(readBySlug(SLUG));
        },
        [
            dispatch
        ]
    );

    const add = () => setBanner([
        ...banner,
        ''
    ]);

    const edit = index => ({target: {value}}) => setBanner([
        ...banner.slice(0, index),
        value,
        ...banner.slice(index+1)
    ]);

    const remove = index => () => setBanner([
        ...banner.slice(0, index),
        ...banner.slice(index+1)
    ]);

    const reorder = (from, to) => {
        if(from === to) return;

        let next = [
            ...banner.slice(0, from),
            ...banner.slice(from+1)
        ];

        next = [
            ...next.slice(0, to),
            banner[from],
            ...next.slice(to)
        ];

        setBanner(next);
    };

    const swap = (a, b) => () => {
        if(Math.min(a, b) < 0 || Math.max(a, b) >= banner.length) return;
        
        if(a > b) [a, b] = [b, a];

        setBanner([
            ...banner.slice(0, a),
            banner[b],
            banner[a],
            ...banner.slice(b+1)
        ]);
    };

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
        >
            <DeleteConfirmation
                open={selectToDelete !== -1}
                onClose={() => setSelectToDelete(-1)}
                remove={remove(selectToDelete)}
                name={`\`${banner[selectToDelete]}\``}
            >

            </DeleteConfirmation>
            <Typography>
                Edit banner texts for home slide show here
            </Typography>
            <Button
                onClick={add}
            >
                Add banner text
            </Button>
            {
                banner.map(
                    (text, index) => (
                        <Grid
                            key={index}
                            item
                            xs={12}
                            container
                            justify="center"
                            alignItems="center"
                            className={classes.padding}
                            onDrop={
                                e => {
                                    e.preventDefault();
                                    reorder(dd.current, index);
                                }
                            }
                        >
                            <IconButton
                                Icon={KeyboardArrowUp}
                                title="Move up"
                                onClick={swap(index, index-1)}
                            />
                            <IconButton 
                                Icon={KeyboardArrowDown}
                                title="Move down"
                                onClick={swap(index, index+1)}
                            />
                            <Typography
                                onDragStart={e => {
                                    dd.current = index;
                                }}
                                className={classes.margin}
                            >
                                {index + 1}.
                            </Typography>
                            <Textfield 
                                value={banner[index]}
                                onChange={edit(index)}
                            />
                            <IconButton
                                title="Remove"
                                onClick={() => setSelectToDelete(index)}
                                Icon={RemoveCircle}
                            >
                                
                            </IconButton>
                        </Grid>
                    )
                )
            }
            <ButtonGroup
                className={classes.margin}
            >
                <Button
                    onClick={
                        () => dispatch(update({
                            slug: SLUG,
                            content: banner,
                            id: staticBanner.id
                        }))
                    }
                >
                    Save
                </Button>
                <Button
                    onClick={
                        () => setBanner(staticBanner.content)
                    }
                >
                    Cancel
                </Button>
            </ButtonGroup>
        </Grid>
    );
};

export default BannerEdit;