import {
    DISABLE,
    UPDATE
} from '../../../../consts/promo/User';
import {
    apiAction
} from '../../Helper';

export const update = ({
    id,
    status,
    userName,
    userLimit,
    promo
}) => apiAction({
    url: '/api/promoprocessupdate/' + id,
    method: 'POST',
    data: {
        id,
        status,
        user_name: userName,
        user_limit: userLimit,
        promo: {
            promo_code: promo.code,
            name: promo.name,
            discount: promo.discount,
            max_discount: promo.maxDiscount,
            min_purchase: promo.minPurchase,
            end_date: promo.validity,
            limit: promo.limit
        }
    },
    onSuccess: data => ({
        type: UPDATE,
        data: {
            id: data.id,
            status: data.status,
            userName: data.user_name,
            userLimit: data.user_limit,
            promo: {
                code: data.promo.promo_code,
                name: data.promo.name,
                discount: data.promo.discount,
                maxDiscount: data.promo.max_discount,
                minPurchase: data.promo.min_purchase,
                validity: data.promo.end_date,
                limit: data.promo.limit
            }
        }
    }),
    label: UPDATE
});

export const disableAll = userName => apiAction({
    url: '/api/promoprocess/disablepromos/' + userName,
    method: 'POST',
    onSuccess: () => ({
        type: DISABLE
    })
});