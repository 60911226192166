import {
    APPEND_NOTIFICATION,
    UPDATE_NOTIFICATION
} from '../../consts/Notifications';

const notifications = (state = [], action) => {
    let nextState = state;

    if (nextState.length > 100) {
        nextState = nextState.slice(nextState.length - 100);
    }

    switch (action.type) {
        case APPEND_NOTIFICATION:
            return [
                ...nextState,
                {
                    shown: false,
                    ...action
                }
            ];
        case UPDATE_NOTIFICATION:
            return nextState.map(v => (
                {
                    ...v,
                    shown: v.id === action.id ? true : v.shown
                }
            ));
        default:
            return nextState;
    }
};

export default notifications;

export const getNotification = state => state && state[0] && state
    .filter(v => v.shown === false)
    .map(
        (
            {
                id,
                message,
                variant
            }
        ) => (
            {
                id,
                message,
                variant
            }
        )
    );