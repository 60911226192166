import React, { 
    useState 
} from 'react';
import { 
    useSpring, 
    animated 
} from 'react-spring';
import { 
    connect 
} from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { 
    fade, 
    makeStyles, 
    withStyles,
    Collapse,
    SvgIcon
} from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {
    Button,
    Link,
    Typography
} from '../../commonComponents';
import { 
    getMenu 
} from '../../redux/reducers';
import { 
    deleteCategory 
} from '../../redux/actions/category/Delete';

function MinusSquare(props) {
    return (
        <SvgIcon 
            fontSize="inherit" 
            {...props}
        >
        {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon 
            fontSize="inherit" 
            {...props}
        >
        {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon 
            className="close" 
            fontSize="inherit" 
            {...props}
        >
        {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const style = useSpring({
        from: { 
            opacity: 0, 
            transform: 'translate3d(20px,0,0)' 
        },
        to: { 
            opacity: props.in ? 1 : 0, 
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)` 
        },
    });

    return (
        <animated.div 
            style={style}
        >
            <Collapse 
                {...props} 
            />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles(
    theme => (
        {
            iconContainer: {
                '& .close': {
                    opacity: 0.3,
                },
            },
            group: {
                marginLeft: 12,
                paddingLeft: 12,
                borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
            },
        }
    )
)(
    props => (
        <TreeItem 
            {...props} 
            TransitionComponent={
                TransitionComponent
            } 
        />
    )
);

const useStyles = makeStyles(
    {
        root: {
            height: '100%',
            flexGrow: 1,
            width: 'auto',
            padding: 5
        },
        hide: {
            display: 'none',
        },
    }
);

const mapStateToProps = state => ({
    tree: getMenu(state).data
});

const mapDispatchToProps = {
    deleteCategory
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(
    props => {
        const classes = useStyles();

        const [
            selected, 
            setSelected
        ] = useState({
            id: '',
            name: ''
        });

        if(!props.tree) {
            return '';
        }

        let nodeId = 1;

        const NodeView = ({
            data
        }) => (
            <React.Fragment>
                {
                    data.map(
                        (c, index) => (
                            <StyledTreeItem
                                nodeId={"" + nodeId++}
                                label={c.name}
                                key={index}
                                id={c.id}
                                name={c.name}
                                onClick={
                                    e => {
                                        const node = e.currentTarget.parentNode;
                                        setSelected({
                                            id: node.getAttribute('id'),
                                            name: node.getAttribute('name')
                                        })
                                    }
                                }
                            >
                                {
                                    Array.isArray(c.children) 
                                    ? 
                                    <NodeView data={c.children} /> 
                                    : 
                                    null
                                }
                            </StyledTreeItem>
                        )
                    )
                }
            </React.Fragment>
        )

        return (
            <React.Fragment>
                <span>
                    <Typography 
                        variant="body1" 
                        gutterBottom 
                        component="span"
                        title={
                            selected.id !== '' 
                            ? 
                            'Selected Category: ' + selected.name 
                            : 
                            'No Category Selected.'
                        }
                    />
                        
                    <Link 
                        to={{
                            pathname: '/admincategoryentry',
                            state: selected.id
                        }}
                        className={
                            clsx(
                                {
                                    [classes.hide]: selected.id === ''
                                }
                            )
                        }
                    >
                        <Button 
                            disabled={
                                selected.id === ''
                            } 
                            buttonText='Update'
                        />
                    </Link>
                    <Button 
                        disabled={
                            selected.id === ''
                        } 
                        className={
                            clsx(
                                {
                                    [classes.hide]: selected.id === ''
                                }
                            )
                        }
                        onClick={
                            () => props.deleteCategory(selected.id)
                        }
                        buttonText="Delete"
                    />  
                </span>
                <TreeView
                    className={classes.root}
                    //defaultExpanded={['1']}
                    defaultCollapseIcon={
                        <MinusSquare />
                    }
                    defaultExpandIcon={
                        <PlusSquare />
                    }
                    defaultEndIcon={
                        <CloseSquare />
                    }
                >
                    <NodeView 
                        data={props.tree} 
                    />
                </TreeView>
            </React.Fragment>
        );
    }
);