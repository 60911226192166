import React from 'react';
import { 
	makeStyles,
	Avatar,
	Grid
} from '@material-ui/core';

const useStyles = makeStyles(
	theme => (
		{
			avatar: {
				margin: 10,
				color: '#fff',
				backgroundColor: theme.palette.primary.main,
				width: 60,
				height: 60,
			},
		}
	)
);

export default () => {
	const classes = useStyles();

	return (
		<Grid 
			container 
			justify="center" 
			alignItems="center"
		>
			<Avatar className={classes.avatar}>
				A
			</Avatar>
		</Grid>
	);
};