import {
	UPDATE
} from '../../../consts/product/Update';
import {
	apiAction,
	thunk
} from '../Helper';
import {
	fetchAll
} from './Fetch';

export const update = product => apiAction(
	{
		url: '/api/admin/productupdate/' + product.id,
		method: 'POST',
		data: {
			name: product.name,
			bangla_name: product.banglaName,
			scientific_name: product.scientificName,
			benefits: product.BenefitsAndUses,
			planter_name: product.planterName,
			planter_size: product.planterSize,
			planter_price: product.planterPrice,
			product_type: product.productType,
			planter_material: product.planterMaterial,
			measurement_unit: product.measurementUnit,
			buy_price: parseFloat(product.buyPrice),
			sell_price: parseFloat(product.sellPrice),
			delivery_charge: parseFloat(product.deliveryCharge),
			description: product.description,
			light_requirement: product.lightRequirement,
			water_requirement: product.waterRequirement,
			image_url: product.image.split(',').map(s => s.trim()),
			category_id: product.categories,
			featured_products: product.checkFeature,
			curation: product.curation,
			product_stock: product.isStockOut,
			is_tub: product.isTub,
			related_products: []
		},
		onSuccess: thunk(
			[
				data => ({
					type: UPDATE,
					data
				}),
				data => ({
					type: fetchAll,
					data
				}),
			]
		),
		label: UPDATE,
	}
);