import React from 'react';
import {
	Link, 
	withRouter
} from 'react-router-dom';
import {
	Drawer,
	List,
	ListItem,
	ListItemText,
	makeStyles,
} from '@material-ui/core';
import {
    lists,
    entryList,
} from './Data';
import {
	Typography,
	Divider
} from '../../../commonComponents';
import Avatar from './Avatar';

const drawerWidth = 240;

const useStyles = makeStyles(
	theme => (
		{
			drawer: {
				[theme.breakpoints.up('sm')]: {
					width: drawerWidth,
					flexShrink: 0,
				},
			},
			toolbar: {
				backgroundColor: theme.palette.secondary.main,
			},
			drawerPaper: {
				width: drawerWidth,
			}
		}
	)
);

const AdminDrawer = ({
	match: {
		url,
	},
}) => {
	const classes = useStyles();

	return (
		<Drawer
			className={classes.drawer}
			variant="permanent"
			classes={{
				paper: classes.drawerPaper,
			}}
			transitionDuration={400}
		>
            <div 
                className={classes.toolbar} 
            />

			<Avatar />

			<Typography 
				variant="body2" 
				align="center"
				gutterBottom
				title='Nurul Islam Topu'
			/>

			<Divider />

			<List>
                {
                    entryList.map(
                        (obj) => (
                            <ListItem 
                                button 
                                key={obj.text} 
                                component={Link} 
                                to={obj.href}
                                style={{
                                    backgroundColor: url === obj.href ? '#aed581' : '#ffffff'
                                }}
                            >
                                <ListItemText 
                                    primary={obj.text} 
                                />
                            </ListItem>
                        )
                    )
                }
			</List>
            
			<Divider />

			<List>
                {
                    lists.map(
                        (
                            {text, href}
                        ) => (
                            <ListItem 
                                button 
                                key={text} 
                                component={Link} 
                                to={href}
                                style={{
                                    backgroundColor: url === href ? '#aed581' : '#ffffff'
                                }}
                            >						
                                <ListItemText 
                                    primary={text} 
                                />
                            </ListItem>
                        )
                    )
                }
			</List>			
		</Drawer>
	);
};
  
export default withRouter(AdminDrawer);