import {
    activate
} from './Activate';
import {
    update,
    disableAll
} from './Update';
import {
    readByCode
} from '../admin/Read';
import {
    apiAction,
    thunk
} from '../../Helper';
import {
    appendNotification
} from '../../Notifications'
import {
    getUserPromoList
} from '../../../reducers';

export const activatePromo = ({
    userName,
    code
}) => async (dispatch, getState) => {
    let promo = getUserPromoList(getState()).find(p => p.promo.code === code);

    await dispatch(disableAll(userName));

    // update the promo to active
    if(promo && promo.id) {
        if(
            (new Date(promo.promo.validity)) <= Date.now()
            ||
            promo.userLimit < 1
        ) {
            return dispatch(appendNotification({
                message: 'This promo has expired.',
                variant: 'warning'
            }));
        }

        if(promo.status) {
            dispatch(appendNotification({
                message: 'This promo is already activated.',
                variant: 'info'
            }));
        }

        const updateAction = update({
            id: promo.id,
            status: true,
            userName: userName,
            userLimit: promo.userLimit,
            promo: promo.promo
        }).payload;

        return dispatch(apiAction({
            ...updateAction,
            onSuccess: thunk([
                updateAction.onSuccess,
                () => promo.status 
                        ?
                        {
                            type: '......'
                        } :
                        appendNotification({
                            message: 'Promo activated.',
                            variant: 'success'
                        })
            ])
        }));
    }

    // create new entry
    const read = readByCode(code).payload;

    return dispatch(apiAction({
        ...read,
        onSuccess: thunk([
            read.onSuccess,
            promo => {
                if(
                    (new Date(promo.end_date)) <= Date.now()
                    ||
                    promo.limit < 1
                ) {
                    return appendNotification({
                        message: 'This promo has expired.',
                        variant: 'warning'
                    });
                }
            
                return activate({
                    status: true,
                    userName: userName,
                    userLimit: promo.limit,
                    promo: promo
                });
            }
        ])
    }))

    // promo = getPromoByCode(getState(), code);

};

export const reduceLimit = ({
    id,
    status,
    userName,
    userLimit,
    promo
}) => update({
    id,
    status,
    userName,
    userLimit: userLimit - 1,
    promo
});