import React from 'react';
import { 
    makeStyles,
    Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme =>({
	divider: {
		width: '70%',
        margin: '10px auto',
        height: '1px',
	},
}));

export default ({
    ...props
}) => {
    const classes = useStyles();
    
    return (
        <Divider 
            classes={{
                root: classes.divider
            }}
            {...props}
        />
    );
}